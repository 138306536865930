<template>
    <div class="position-relative">
        <v-boilerplate-loading :elevation="0" v-if="! isLoaded" type="list-item-two-line@1" class="w-full"/>
        <template v-else>
            <sub-header-with-divider text="Zubereitung" v-if="showTitleIfNecessary && (! isTogether || ! isSideBySide)"/>

            <div
                v-for="(group, index) in recipe.step_groups"
                class="recipe-step-group"
                :class="{
                    'mt-5': index > 0
                }"
            >

                <step-sub-title prefix="Gruppe: " :sub-data="group"/>
                <step-sub-informations
                    :prefix="(! group.title || group.title === '' ? 'Gruppeninformation: ' : '')"
                    :sub-data="group"
                    title-secondary
                    class="ml-1"
                />
                <divider
                    v-if="(group.title && group.title !== '') || (group.level && group.level !== '') || (group.time && group.time > 0)"
                    class="mt-3 mb-3"
                />
                <divider v-else-if="index > 0"/>

                <div
                    v-for="(stepData, stepIndex) in group.steps"
                    class="recipe-step"
                    :key="'recipe-ingredient-'+recipe.id+group.id+stepData.id"
                >
                    <div
                        :class="{
                        'mt-4': ! ((stepData.level && stepData.level !== '') || (stepData.time && stepData.time > 0)),
                        'mt-6': (stepData.level && stepData.level !== '') || (stepData.time && stepData.time > 0),
                        }"
                        v-if="stepIndex > 0" />

                    <step :step="stepData"/>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import SubHeaderWithDivider from '@symfia/library/Views/Helper/SubHeaderWithDivider';
import Divider              from '@symfia/library/Views/Helper/Divider';
import SubHeader            from '@symfia/library/Views/Helper/SubHeader';
import StepSubTitle         from './StepSubTitle';
import StepSubInformations  from './StepSubInformations';
import Step                 from './Step';

export default {
    components: {
        SubHeaderWithDivider,
        Divider,
        SubHeader,
        StepSubTitle,
        Step,
        StepSubInformations,
    },

    props: {
        recipe: {
            type:     Object,
            required: true,
        },

        isTogether: {
            type:    Boolean,
            default: false,
        },

        isSideBySide: {
            type:    Boolean,
            default: false,
        },

        showTitleIfNecessary: {
            type:    Boolean,
            default: false,
        },
    },

    data: () => ({}),

    watch: {},

    mounted() {

    },

    beforeDestroy() {

    },

    methods: {},

    computed: {
        isLoaded() {
            if (
                this.recipe === null
                || typeof this.recipe !== 'object'
                || typeof this.recipe.step_groups === 'undefined'
            ) {
                return false;
            }

            if (typeof this.recipe.step_groups_loaded === 'undefined') {
                return true;
            }

            return this.recipe.step_groups_loaded === true;
        },
    },
};
</script>
