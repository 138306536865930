<template>
    <div class="position-relative">
        <div :class="{flex: coverSideBySide}">
            <div class="align-self-center" :class="{flex: ! coverSideBySide }">
                <div>
                    <recipe-image
                        :recipe="recipe"
                        v-if="displayCover"
                        :height="coverSideBySide ? sideBySideCoverDimensions : 125"
                        :width="coverSideBySide ? sideBySideCoverDimensions : 125"/>
                </div>
                <div :class="{
                'ml-3': displayCover && ! coverSideBySide,
                'mt-3': displayCover && coverSideBySide
            }">
                    {{ user }}
                </div>
            </div>

            <div class="w-full" :class="{'ml-6': coverSideBySide}">
                <v-row class="w-full">
                    <v-col cols="12" md="6" lg="4">
                        <recipe-portions-field
                            v-if="modifyRecipePortionsAllowed"
                            :portions="portionsForCalculation"
                            :original-portions="recipe.portions"
                            v-on:recipe:portions:updated="updatedPortions"
                        />
                        <template v-else>
                            <v-icon class="mr-2">$portions</v-icon>
                            {{ recipe.portions }} Portionen
                        </template>
                    </v-col>

                    <v-col cols="12" md="6" lg="4" offset-lg="4" v-if="recipe.calories_complete_label">
                        <v-icon class="mr-2">$calories</v-icon>
                        {{ recipe.calories_complete_label }}
                    </v-col>
                </v-row>

                <v-row class="w-full">
                    <v-col cols="12" md="6" lg="4">
                        <template v-if="recipe.difficulty_label">
                            <v-icon class="mr-2" v-html="iconForDifficulty"/>
                            {{ recipe.difficulty_label }}
                        </template>
                    </v-col>

                    <v-col cols="12" md="6" lg="4">
                        <template v-if="recipe.food_type_text">
                            <v-icon class="mr-1" v-html="iconForFoodType"/>
                            {{ recipe.food_type_text }}
                        </template>
                    </v-col>

                    <v-col cols="12" md="6" lg="4">
                        <template v-if="hasAdditionalData && recipe.additional.diet_type_label_full">
                            <v-icon class="mr-1">$dietType</v-icon>
                            {{ recipe.additional.diet_type_label_full }}
                        </template>
                    </v-col>
                </v-row>

                <template v-if="hasTimeData || hasFullTime">
                    <v-row class="w-full">
                        <v-col cols="12" :class="{'pb-0': $breakpoint.mdAndUp}">
                            <v-icon class="mr-1">$recipeTime</v-icon>
                            {{ recipe.human_time_label }}
                        </v-col>
                        <v-col cols="12" class="pb-0" v-if="$breakpoint.mdAndUp">
                            <divider :use-margin-bottom="false"/>
                        </v-col>
                        <template v-if="hasTimeData">
                            <v-col cols="12" md="6" lg="4">
                                <template v-if="recipe.additional.preparation_time_label">
                                    <v-icon class="mr-1">$recipeSpecificTime</v-icon>
                                    {{ recipe.additional.preparation_time_label }}
                                </template>
                                <template v-else-if="$breakpoint.mdAndUp">
                                    Keine Vorbereitungszeit
                                </template>
                            </v-col>
                            <v-col cols="12" md="6" lg="4">
                                <template v-if="recipe.additional.cooking_baking_time_label">
                                    <v-icon class="mr-1">$recipeSpecificTime</v-icon>
                                    {{ recipe.additional.cooking_baking_time_label }}
                                </template>
                                <template v-else-if="$breakpoint.mdAndUp">
                                    Keine Koch-/Backzeit
                                </template>
                            </v-col>
                            <v-col cols="12" md="6" lg="4">
                                <template v-if="recipe.additional.resting_time_label">
                                    <v-icon class="mr-1">$recipeSpecificTime</v-icon>
                                    {{ recipe.additional.resting_time_label }}
                                </template>
                                <template v-else-if="$breakpoint.mdAndUp">
                                    Keine Ruhezeit
                                </template>
                            </v-col>
                        </template>
                        <template v-else>
                            <v-col cols="12" md="6" lg="4">
                                <avatar-text-or-time-loader/>
                            </v-col>
                            <v-col cols="12" md="6" lg="4" v-if="$breakpoint.mdAndUp">
                                <avatar-text-or-time-loader/>
                            </v-col>
                            <v-col cols="12" md="6" lg="4" v-if="$breakpoint.lgAndUp">
                                <avatar-text-or-time-loader/>
                            </v-col>
                        </template>
                    </v-row>
                </template>

                <div v-if="recipe.subtitle && recipe.subtitle !== ''" class="mt-4">
                    <div v-html="recipe.subtitle"/>
                </div>
            </div>
        </div>

        <recipe-additionals :recipe="recipe" v-if="showAdditionals"/>
    </div>
</template>

<script>
import RecipeImage            from '../RecipeImage';
import Icon                   from '@/views/layout/helper/Icon/Icon';
import SubHeaderWithDivider   from '@symfia/library/Views/Helper/SubHeaderWithDivider';
import Divider                from '@symfia/library/Views/Helper/Divider';
import ContentLoader          from '@symfia/library/Views/Helper/ContentLoader';
import AvatarTextOrTimeLoader from '@symfia/library/Views/ContentLoaders/AvatarTextOrTimeLoader';
import RecipeAdditionals   from './RecipeAdditionals';
import RecipePortionsField from './RecipePortionsField';

export default {
    components: {
        Icon,
        RecipeImage,
        SubHeaderWithDivider,
        Divider,
        ContentLoader,
        AvatarTextOrTimeLoader,
        RecipeAdditionals,
        RecipePortionsField,
    },

    props: {
        recipe: {
            type:     Object,
            required: true,
        },

        portionsForCalculation: {
            type:    [Number, String],
            default: null,
        },

        displayCover: {
            type:    Boolean,
            default: true,
        },

        showAdditionals: {
            type:    Boolean,
            default: false,
        },

        modifyRecipePortionsAllowed: {
            type:    Boolean,
            default: false,
        },
    },

    data: () => ({}),

    watch: {},

    mounted() {

    },

    beforeDestroy() {

    },

    methods: {
        updatedPortions(newPortions) {
            this.$emit('recipe:portions:updated', newPortions ? parseInt(newPortions) : null)
        }
    },

    computed: {
        portionsInputHint() {
            if (! this.hasModifiedPortions) {
                return null;
            }

            return `Portionen verändert - Original ${this.recipe.portions} Portionen`;
        },

        portionsInputText() {
            if (! this.recipe.portions || this.recipe.portions <= 0) {
                return null;
            }

            return `${this.recipe.portions} Portionen`;
        },

        hasModifiedPortions() {
            if (! this.recipe.portions || this.recipe.portions <= 0) {
                return false;
            }

            if (
                this.recipe.portions
                && this.portionsForCalculation
                && this.recipe.portions > 0
                && this.portionsForCalculation > 0
                && this.recipe.portions !== this.portionsForCalculation
            ) {
                return true;
            }

            return false;
        },

        iconForFoodType() {
            switch (this.recipe.food_type) {
                case 'meat':
                    return '$foodTypeMeat';

                case 'fish':
                    return '$foodTypeFish';

                case 'vegetarian':
                    return '$foodTypeVegetarian';

                case 'vegan':
                    return '$foodTypeVegan';

                case 'normal':
                default:
                    return '$foodTypeNormal';
            }
        },

        iconForDifficulty() {
            switch (this.recipe.difficulty) {
                case 'very-easy':
                    return '$difficultyVeryEasy';

                case 'easy':
                    return '$difficultyEasy';

                case 'normal':
                    return '$difficultyNormal';

                case 'hard':
                    return '$difficultyHard';

                case 'very-hard':
                    return '$difficultyVeryHard';

                default:
                    return '$difficultyNormal';
            }
        },

        coverSideBySide() {
            return this.displayCover && this.$breakpoint.smXlAndUp;
        },

        sideBySideCoverDimensions() {
            return this.$breakpoint.lgAndUp ? 250 : 200;
        },

        isLoaded() {
            return ! (this.recipe === null
                || typeof this.recipe !== 'object'
                || typeof this.recipe.id === 'undefined'
                || ! this.recipe.id
                || typeof this.recipe.title === 'undefined');
        },

        user() {
            if (this.recipe.user.username) {
                if (this.isActualUser(this.recipe.user)) {
                    return 'Rezept von dir';
                }

                return 'Rezept von ' + this.generateUserNameFromObject(this.recipe.user);
            }

            return 'Unbekannter Ersteller';
        },

        hasAdditionalData() {
            return typeof this.recipe.additional !== 'undefined' && this.recipe.additional !== null;
        },

        hasFullTime() {
            return typeof this.recipe.human_time_label !== 'undefined'
                && this.recipe.human_time_label
                && this.recipe.human_time_label !== '';
        },

        hasTimeData() {
            return this.hasAdditionalData
                && (
                    this.recipe.additional.preparation_time_label
                    || this.recipe.additional.cooking_baking_time_label
                    || this.recipe.additional.resting_time_label
                );
        },
    },
};
</script>
