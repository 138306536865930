<template>
    <div class="position-relative w-full flex cursor-pointer" @click="selectRecipe">
        <v-dialog
            :value="showDeleteRecipeDialog"
            :fullscreen="$breakpoint.smAndDown"
            max-width="95%"
            :style="{'min-height:': '100%'}"
            content-class="h-full !shadow-none"
            persistent
        >
            <alert-content-card
                :action-button-is-loading="isDeleting"
                v-on:alert-component:cancel="showDeleteRecipeDialog = false"
                v-on:alert-component:action="deleteRecipe">
                <template slot="title">
                    Rezept löschen
                </template>

                <template slot="actionButton">
                    Löschen
                </template>

                <template slot="cancelButton">
                    Abbrechen
                </template>

                <span slot="default">
                        Möchtest du dein Rezept {{ title }} wirklich löschen?
                    </span>
            </alert-content-card>
        </v-dialog>

        <media-card
            darker-background-opacity
            :eager-load-images="false"
            :allow-tooltips="false"
            :small-card="isLarge"
            :open-delay-hover="openDelayHover"
            :title-is-on-main="titleInline"
            :title-is-on-main-if-no-poster="titleInlineIfNoPoster"
            :button-on-mobile-for-hover="buttonOnMobileForHover"

            :background="recipe.background || recipe.cover"
            :background-lazy="recipe.placeholderBase64 || recipe.placeholder || null"

            :title="recipe.title"

            spacer-between-rows

            :left-sub-text="! isOwnView ? user : null"
            :right-sub-text="recipe.portions_label"
            spacer-after-first-group

            :second-left-sub-text="recipe.human_time_label"
            :second-right-sub-text="recipe.calories_label"
            spacer-after-second-group

            :third-left-sub-text="recipe.ingredients_count_label"
            :third-right-sub-text="recipe.steps_count_label"
            spacer-after-third-group

            :fourth-left-sub-text="recipe.difficulty_label"
            :fourth-right-sub-text="recipe.food_type_label"

            :desc="recipe.subtitle"

            css-truncate-desc
        >
            <template slot="actionButtons" v-if="showActions && false">
                <action-group
                    class="d-flex"
                    :class="{
                        'ml-1': $vuetify.breakpoint.smAndDown
                    }"
                    :show-delete="canDelete"
                    use-dropdown
                    v-on:recipes:delete="showDeleteRecipeDialog = true"
                />
            </template>
        </media-card>
    </div>
</template>

<script>
import MediaCard        from '@symfia/library/Views/Cards/Media/MediaCard';
import prune            from 'underscore.string/prune';
import ActionGroup      from '../Shared/ActionGroup';
import AlertContentCard from '@symfia/library/Views/Components/TailwindUi/AlertContentCard';

export default {
    components: {
        MediaCard,
        ActionGroup,
        AlertContentCard,
    },

    props: {
        recipe: {
            type:     Object,
            required: true,
        },

        isOwnView: {
            type:    Boolean,
            default: false,
        },

        isLarge: {
            type:    Boolean,
            default: false,
        },

        titleInline: {
            type:    Boolean,
            default: false,
        },

        titleInlineIfNoPoster: {
            type:    Boolean,
            default: true,
        },

        openDelayHover: {
            type:    Number,
            default: null,
        },

        buttonOnMobileForHover: {
            type:    Boolean,
            default: true,
        },

        onDashboard: {
            type:    Boolean,
            default: false,
        },

        dashboardFull: {
            type:    Boolean,
            default: false,
        },

        dashboardUsedCols: {
            type:    Number,
            default: 6,
        },
    },

    mounted() {

    },

    watch: {},

    data: () => ({
        showDeleteRecipeDialog: false,
        isDeleting:             false,
        requests:               {
            delete: null,
        },
    }),

    beforeDestroy() {
        this.abortRequests();
    },

    methods: {
        abortRequests() {
            if (this.requests.delete) {
                this.requests.delete.cancel();
                this.requests.delete = null;
            }
        },

        deleteRecipe() {
            if (this.isDeleting) {
                return;
            }

            this.isDeleting      = true;
            this.requests.delete = this.$axiosToken.source();

            this.$axios.post(
                this.route('cookbook.recipe.delete', {recipe: this.recipe.id}),
                null,
                {
                    cancelToken: this.requests.withdraw.token,
                },
            ).then((response) => {
                this.$emit('recipes:delete', response.data.id);
            }).finally(() => {
                this.isDeleting             = false;
                this.showDeleteRecipeDialog = false;
                this.requests.delete        = null;
            });
        },

        selectRecipe() {
            this.$emit('recipes:select', this.recipe);
        },
    },

    computed: {
        canDelete() {
            return this.isActualUser(this.recipe.user_id);
        },

        showActions() {
            return this.canDelete;
        },

        title() {
            return this.recipe.name;
        },

        year() {
            return '';
        },

        getSliceForTags() {
            let length   = 3;
            let hasValue = false;

            if (this.isSmartphonePortrait() && ! hasValue) {
                length   = 2;
                hasValue = true;
            }

            if (this.isSmartphoneLandscape() && ! hasValue) {
                length   = 5;
                hasValue = true;
            }

            if (this.isLarge && ! this.isSmartphonePortrait()) {
                length -= 1;
            }

            if (this.isLarge && this.isSmartphoneLandscape()) {
                length -= 2;
            }

            return length;
        },

        canShowDebugInfos() {
            return this.isDebugEnabled() === true && this.isLocalEnv() === true;
        },

        usedBreakpoint() {
            if (this.canShowDebugInfos) {
                let shouldBeTwoLine = this.shouldBeTwoLineName;
                let hasValue        = false;
                let value           = 1;

                if (! this.poster && ! hasValue) {
                    value    = 2;
                    hasValue = true;
                }

                if (this.isSmartphonePortrait() && ! hasValue) {
                    value    = 3;
                    hasValue = true;
                }

                if (this.isSmartphoneLandscape() && ! hasValue) {
                    value    = 4;
                    hasValue = true;
                }

                if (this.isSmallLaptopScreen() && ! hasValue) {
                    value    = 5;
                    hasValue = true;
                }

                if (! this.isLargeLaptopScreen() && ! hasValue) {
                    value    = 6;
                    hasValue = true;
                }

                if (this.isXlBreakpoint() && ! hasValue) {
                    value    = 7;
                    hasValue = true;
                }

                let debugData = [];

                if (value && typeof value.toString !== 'undefined') {
                    debugData.push(
                        'Breakpoint: ' + value.toString(),
                    );
                }

                if (this.nameLength && typeof this.nameLength.toString !== 'undefined') {
                    debugData.push(
                        'Name Length: ' + this.nameLength.toString(),
                    );
                }

                if (this.nameLengthWithCalc && typeof this.nameLengthWithCalc.toString !== 'undefined') {
                    debugData.push(
                        'Name Length + Calc: ' + this.nameLengthWithCalc.toString(),
                    );
                }

                if (this.shouldBeTwoLineLength && typeof this.shouldBeTwoLineLength.toString !== 'undefined') {
                    debugData.push(
                        '2 Line Check Length: ' + this.shouldBeTwoLineLength.toString(),
                    );
                }

                if (this.shouldBeTwoLineLengthCalc && typeof this.shouldBeTwoLineLengthCalc.toString !== 'undefined') {
                    debugData.push(
                        '2 Line Check Length + Calc: ' + this.shouldBeTwoLineLengthCalc.toString(),
                    );
                }

                if (this.getDescLength && typeof this.getDescLength.toString !== 'undefined') {
                    debugData.push(
                        'Desc Length: ' + this.getDescLength.toString(),
                    );
                }

                if (this.getDescLengthCalculation && typeof this.getDescLengthCalculation.toString !== 'undefined') {
                    debugData.push(
                        'Desc Length + Calc: ' + this.getDescLengthCalculation.toString(),
                    );
                }

                if (this.getSliceForGenres && typeof this.getSliceForGenres.toString !== 'undefined') {
                    debugData.push(
                        'Genre Slice: ' + this.getSliceForGenres.toString(),
                    );
                }

                debugData.push(
                    shouldBeTwoLine ? ' 2 Line Name' : ' 1 Line Name',
                );

                debugData.push(
                    this.isLarge ? ' Large' : ' Normal',
                );

                return debugData.join('<br>');
            }

            return null;
        },

        shouldBeTwoLineLength() {
            let hasValue   = false;
            let textLength = 28;

            if (! this.poster && ! hasValue) {
                textLength = 50;
                hasValue   = true;
            }

            if (this.isSmartphonePortrait() && ! hasValue) {
                textLength = 23;
                hasValue   = true;
            }

            if (this.isSmartphoneLandscape() && ! hasValue) {
                textLength = 60;
                hasValue   = true;
            }

            if (this.isSmallLaptopScreen() && ! hasValue) {
                textLength = 27;
                hasValue   = true;
            }

            if (! this.isLargeLaptopScreen() && ! hasValue) {
                textLength = 23;
                hasValue   = true;
            }

            if (this.isXlBreakpoint() && ! hasValue) {
                textLength = 43;
                hasValue   = true;
            }

            return textLength;
        },

        shouldBeTwoLineLengthCalc() {
            let textLength = this.shouldBeTwoLineLength;

            if (this.isLarge) {
                textLength -= 9;

                if (this.isSmartphoneLandscape()) {
                    textLength -= 30;
                }

                if (this.is4kResolution()) {
                    textLength -= 13;
                } else if (this.isMdOrHigherBreakpoint() && ! this.isLargeLaptopScreen()) {
                    textLength -= 3;
                }
            }

            return textLength;
        },

        shouldBeTwoLineName() {
            return false;
            return this.name.toString().length >= this.shouldBeTwoLineLengthCalc;
        },

        getDescLength() {
            let length       = 135;
            let newLengthSet = false;

            if (! this.poster && ! newLengthSet) {
                length       = 220;
                newLengthSet = true;
            }

            if (this.isSmartphonePortrait() && ! newLengthSet) {
                length       = 105;
                newLengthSet = true;
            }

            if (this.isSmartphoneLandscape() && ! newLengthSet) {
                length       = 235;
                newLengthSet = true;
            }

            if (this.isSmallLaptopScreen() && ! newLengthSet) {
                length       = 150;
                newLengthSet = true;
            }

            if (! this.isLargeLaptopScreen() && ! newLengthSet) {
                length       = 80;
                newLengthSet = true;
            }

            if (this.isXlBreakpoint() && ! newLengthSet) {
                length       = 160;
                newLengthSet = true;
            }

            return length;
        },

        getDescLengthCalculation() {
            let length = this.getDescLength;

            if (! this.shouldBeTwoLineName) {
                length += 40;

                if (this.isSmartphoneLandscape()) {
                    length += 25;
                }
            }

            if (! this.genres && ! this.canShowDebugInfos) {
                length += 30;
            }

            if (this.user) {
                //length -= 30;
            }

            if (this.titleInline
                || (
                    (! this.recipe.poster || ! this.recipe.poster === '')
                    && this.titleInlineIfNoPoster
                )
                || this.buttonOnMobileForHover
            ) {
                length -= 40;
            }

            if (this.isLarge && ! this.isSmartphonePortrait()) {
                length -= 55;

                if (! this.shouldBeTwoLineName && this.isSmartphoneLandscape()) {
                    length -= 105;
                } else if (this.isSmartphoneLandscape()) {
                    length -= 70;
                }

                if (this.is4kResolution()) {
                    length -= 5;
                } else if (! this.shouldBeTwoLineName && this.isSmallLaptopScreen()) {
                    length -= 5;
                } else if (this.isSmallLaptopScreen()) {
                    length += 0;
                } else if (! this.shouldBeTwoLineName && ! this.isLargeLaptopScreen()) {
                    length += 40;
                } else if (! this.isLargeLaptopScreen()) {
                    length += 60;
                } else if (! this.shouldBeTwoLineName && this.isLargeLaptopScreen()) {
                    length += 5;
                } else if (this.isLargeLaptopScreen()) {
                    length += 15;
                }
            }
            if (this.onDashboard && ! this.dashboardFull) {
                length -= 40;
            }

            return length;
        },

        user() {
            if (this.recipe.user.username) {
                if (this.isActualUser(this.recipe.user)) {
                    return 'Dein Rezept';
                }

                return 'Rezept von ' + this.generateUserName(this.recipe.user.username);
            }

            return null;
        },

        nameLength() {
            let length       = 65;
            let newLengthSet = false;

            if (! this.poster && ! newLengthSet) {
                length       = null;
                newLengthSet = true;
            }

            if (this.isSmartphonePortrait() && ! newLengthSet) {
                length       = 40;
                newLengthSet = true;
            }

            if (this.isSmartphoneLandscape() && ! newLengthSet) {
                length       = 100;
                newLengthSet = true;
            }

            if (this.isSmallLaptopScreen() && ! newLengthSet) {
                length       = 70;
                newLengthSet = true;
            }

            if (! this.isLargeLaptopScreen() && ! newLengthSet) {
                length       = 40;
                newLengthSet = true;
            }

            if (this.isXlBreakpoint() && ! newLengthSet) {
                length       = 80;
                newLengthSet = true;
            }

            return length;
        },

        nameLengthWithCalc() {
            let length = this.nameLength;

            if (this.isLarge && ! this.isSmartphonePortrait() && length) {
                length -= 40;

                if (this.isSmartphoneLandscape()) {
                    length -= 25;
                }

                if (this.isSmallLaptopScreen()) {
                    length += 5;
                }

                if (this.isLgBreakpoint() && ! this.isLargeLaptopScreen()) {
                    length += 27;
                } else if (this.isLgBreakpoint() && this.isLargeLaptopScreen()) {
                    length += 9;
                }
            }

            return length;
        },

        truncatedName() {
            let length = this.nameLengthWithCalc;

            if (length) {
                return prune(this.name, length);
            }

            return this.name;
        },
    },
};
</script>
