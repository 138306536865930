import generateFeatureFlagDataArray from '@symfia/core/helper/generateFeatureFlagDataArray';

const defaultActive = false;
const flagName      = 'RecipesDetailsAsModal';

const flag = generateFeatureFlagDataArray(
    {
        active:      defaultActive,
        group:       'Rezepte',
        module:      'Cookbook',
        subGroup:    'Liste',
        flag:        flagName,
        description: 'Möchtest du die Infos von Rezepten als Modal-Fenster öffnen?',
        title:       'Anzeige: Infos als Modal',
    },
);

export {
    flag,
    defaultActive,
    flagName,
};
