<template>
    <div class="position-relative">
        <media-library-attachment
            name="cover"
            :initial-value="mediaLibraryMedia"
            :validation-rules="{
                accept: ['image/jpeg', 'image/gif', 'image/png', 'image/jpg'],
                minSizeInKB: 0,
                maxSizeInKB: 1024,
            }"
            @is-ready-to-submit-change="imageUploadFinished = $event"
            @change="mediaData = $event"
            :upload-domain="$baseUrlWithoutSuffix"
        />

        <hideable-content-sub-header-with-divider text="Wichtiges">
            <v-row>
                <v-col cols="12" md="6" lg="4">
                    <text-field-with-validation
                        :rules="rules.title"
                        label="Name"
                        name="title"
                        counter="125"
                        v-model="value.title"
                    >
                        <span slot="label">Name <span class="required-asterisk-mark">*</span></span>
                    </text-field-with-validation>
                </v-col>

                <v-col cols="12" md="6" lg="4">
                    <v-subheader>{{ value.portions === 1 ? 'Eine Portion' : value.portions + ' Portionen' }}</v-subheader>
                    <slider-with-plus-minus
                        v-model="value.portions"
                        :thumb-label="true"
                        :min="1"
                        dense
                        :max="maxPortions"
                        :hint="portionsHint"
                        :persistent-hint="portionsHint !== null"
                    >
                        <template v-slot:hint v-if="portionsHint">
                            {{ maxPortions }} Portionen sind dir zu wenig?
                            <v-btn x-small depressed text @click="updateMaxPortions">Erhöhen</v-btn>
                        </template>
                    </slider-with-plus-minus>
                </v-col>

                <v-col cols="12" md="6" lg="4">
                    <v-select
                        v-model="value.difficulty"
                        :loading="loadingAutocomplete"
                        :disabled="loadingAutocomplete"
                        :items="autocompletion.difficulties"
                        label="Schwierigkeitsgrad"
                    ></v-select>
                </v-col>
            </v-row>
        </hideable-content-sub-header-with-divider>

        <hideable-content-sub-header-with-divider text="Weiteres">
            <v-row>
                <v-col cols="12" md="6" lg="4">
                    <v-text-field
                        maxlength="255"
                        label="Kurzbeschreibung"
                        name="subTitle"
                        counter="255"
                        v-model="value.subTitle"
                    />
                </v-col>

                <v-col cols="12" md="6" lg="4">
                    <v-select
                        v-model="value.foodType"
                        :loading="loadingAutocomplete"
                        :disabled="loadingAutocomplete"
                        :items="autocompletion.foodTypes"
                        label="Art des Gerichts"
                    ></v-select>
                </v-col>

                <v-col cols="12" md="6" lg="4">
                    <v-switch
                        v-model="value.public"
                        label="Öffentlich"
                    ></v-switch>
                </v-col>
            </v-row>
        </hideable-content-sub-header-with-divider>
    </div>
</template>

<script>
import {titleRule}                         from '../../Config/Rules/CreateEdit';
import TextFieldWithValidation             from '@symfia/core/components/VeeValidate/TextFieldWithValidation';
import HideableContentSubHeaderWithDivider from '@symfia/library/Views/Helper/HideableContentSubHeaderWithDivider';
import SliderWithPlusMinus                 from '@symfia/library/Views/Inputs/SliderWithPlusMinus';
import {MediaLibraryAttachment}            from '@spatie/media-library-pro-vue2-attachment';

export default {
    components: {SliderWithPlusMinus, HideableContentSubHeaderWithDivider, TextFieldWithValidation, MediaLibraryAttachment},

    props: {
        value: {
            type:     Object,
            required: true,
        },

        mediaLibraryMedia: {
            type:    Object,
            default: function () {
                return {};
            },
        },

        autocompletion: {
            type:    Object,
            default: function () {
                return {};
            },
        },

        loadingAutocomplete: {
            type:    Boolean,
            default: false,
        },
    },

    watch: {
        value() {
            this.$emit('input', this.value);
        },

        imageUploadFinished() {
            this.$emit('recipe-details:image-upload-finished', this.imageUploadFinished);
        },

        mediaData() {
            this.$emit('recipe-details:image-uploaded', this.mediaData);
        },
    },

    data: () => ({
        imageUploadFinished: true,
        mediaData:           {},
        maxPortions:         20,
        portionsUpgrade:     20,
        finalPortionsLimit:  100,
        rules:               {
            title: titleRule,
        },
    }),

    beforeMount() {

    },

    mounted() {

    },

    beforeDestroy() {

    },

    computed: {
        portionsHint: function () {
            if (this.maxPortions !== this.finalPortionsLimit && this.maxPortions === this.value.portions) {
                return `${this.maxPortions} gilt als Limit`;
            }

            return null;
        },
    },

    methods: {
        updateMaxPortions() {
            let newPortions = this.maxPortions + this.portionsUpgrade;

            if (newPortions > this.finalPortionsLimit) {
                newPortions = this.finalPortionsLimit;
            }

            this.maxPortions = newPortions;
        },

        handleEvent(value, $event) {
            console.log(value, $event);
        },

        handleEventEnd(value, $event) {
            console.log('end', value, $event);
        },

        incrementPortions() {
            this.value.portions++;
        },

        decrementPortions() {
            if (this.value.portions <= 1) {
                return;
            }

            this.value.portions--;
        },
    },
};
</script>
