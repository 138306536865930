<template>
    <div class="position-relative">
        <v-row v-if="displayTitle">
            <v-col cols="12" class="text-small leading-relaxed md:text-center md:text-lg">
                Voller Name: <br />{{ recipe.title }}
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="6">
                Rezept erstellt {{ recipe.created_at_human }}
            </v-col>

            <v-col cols="12" md="6">
                Rezept zuletzt bearbeitet {{ recipe.updated_at_human }}
            </v-col>
        </v-row>

        <v-boilerplate-loading :elevation="0" v-if="! isLoaded" type="list-item-two-line@3" class="w-full"/>
        <template v-else>
            <v-row v-if="hasSourceOrProvider">
                <v-col cols="12" :lg="hasProvider ? 6 : 12" v-if="hasSource">
                    <a v-if="hasSourceUrl" target="_blank" :href="recipe.additional.source_url">{{sourceName}}</a>
                    <template v-else>{{sourceName}}</template>
                </v-col>

                <v-col cols="12" :lg="hasSource ? 6 : 12" v-if="hasProvider">
                    <a v-if="hasProviderUrl" target="_blank" :href="recipe.additional.provider_url">{{providerName}}</a>
                    <template v-else>{{providerName}}</template>
                </v-col>
            </v-row>

            <v-row v-if="hasCategoryOrTags">
                <v-col cols="12" :lg="hasTags ? 6 : 12" v-if="hasCategory">
                    Gehört zur Kategorie:<br>
                    {{ recipe.additional.category }}
                </v-col>

                <v-col cols="12" :lg="hasCategory ? 6 : 12" v-if="hasTags">
                    Stichwörter:<br>
                    <v-chip
                        v-for="tag in recipe.additional.tags"
                        :color="$vuetify.theme.dark ? 'blue-grey darken-4': 'grey lighten-1'"
                        :ripple="false"
                        :key="'recipe-tag-'+recipe.id+'-'+tag"
                        class="mr-2 mt-2"
                        small
                        v-html="tag"
                    />
                </v-col>
            </v-row>

            <recipe-additionals :recipe="recipe" />

            <sub-header-with-divider v-if="recipe.additional.note" text="Notiz" class="mt-3"/>
            <div v-if="recipe.additional.note" v-html="recipe.additional.note"></div>

            <sub-header-with-divider v-if="recipe.additional.recommendations" text="Empfehlung" class="mt-3"/>
            <div v-if="recipe.additional.recommendations" v-html="recipe.additional.recommendations"></div>
        </template>
    </div>
</template>

<script>
import SubHeaderWithDivider from '@symfia/library/Views/Helper/SubHeaderWithDivider';
import RecipeAdditionals    from './RecipeAdditionals';

export default {
    components: {
        SubHeaderWithDivider,
        RecipeAdditionals,
    },

    props: {
        recipe: {
            type:     Object,
            required: true,
        },

        showMore: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
        displayTitle: false,
    }),

    watch: {
        '$breakpoint.width': function (newVal, oldVal) {
            this.handleTitleDisplay();
        }
    },

    mounted() {
        this.handleTitleDisplay();
    },

    beforeDestroy() {

    },

    methods: {
        handleTitleDisplay() {
            const element = document.getElementsByClassName('recipe-details-card-header-title')[0];

            this.displayTitle = element.offsetWidth < element.scrollWidth;
        }
    },

    computed: {
        sourceName() {
            if (this.hasSource) {
                if (this.hasSourceName) {
                    return 'Originales Rezept erstellt von ' + this.recipe.additional.source_name;
                }

                return 'Original von jemand anderem';
            }

            return null;
        },

        hasSource() {
            return this.hasSourceUrl || this.hasSourceName;
        },

        hasSourceOrProvider() {
            return this.hasSource || this.hasProvider;
        },

        hasSourceUrl() {
            if (this.isLoaded) {
                if (
                    typeof this.recipe.additional.source_url
                    && this.recipe.additional.source_url !== null
                    && this.recipe.additional.source_url !== ''
                ) {
                    return true;
                }
            }

            return false;
        },

        hasSourceName() {
            if (this.isLoaded) {
                if (
                    typeof this.recipe.additional.source_name !== 'undefined'
                    && this.recipe.additional.source_name !== null
                    && this.recipe.additional.source_name !== ''
                ) {
                    return true;
                }
            }

            return false;
        },

        providerName() {
            if (this.hasProvider) {
                if (this.hasProviderName) {
                    return 'Rezept bereitgestellt über ' + this.recipe.additional.provider_name;
                }

                return 'Rezept aus anderer Quelle';
            }

            return null;
        },

        hasProvider() {
            return this.hasSourceUrl || this.hasSourceName;
        },

        hasProviderUrl() {
            if (this.isLoaded) {
                if (
                    typeof this.recipe.additional.provider_url
                    && this.recipe.additional.provider_url !== null
                    && this.recipe.additional.provider_url !== ''
                ) {
                    return true;
                }
            }

            return false;
        },

        hasProviderName() {
            if (this.isLoaded) {
                if (
                    typeof this.recipe.additional.provider_name !== 'undefined'
                    && this.recipe.additional.provider_name !== null
                    && this.recipe.additional.provider_name !== ''
                ) {
                    return true;
                }
            }

            return false;
        },

        hasCategoryOrTags() {
            return this.hasCategory || this.hasTags;
        },

        hasCategory() {
            return this.recipe.additional.category;
        },

        hasTags() {
            return this.recipe.additional.tags_label;
        },

        isLoaded() {
            if (
                this.recipe === null
                || typeof this.recipe !== 'object'
                || typeof this.recipe.additional === 'undefined'
            ) {
                return false;
            }

            if (typeof this.recipe.additional_loaded === 'undefined') {
                return true;
            }

            return this.recipe.additional_loaded === true;
        },
    },
};
</script>
