<template>
    <div>
        <sub-header
            :text="text"
            :bolder="bolder"
            :bigger="bigger"
            :color="color"
            :change-accent-for-theme="changeAccentForTheme"
            :inset="inset"
            :icon="icon"
            :icon-color="iconColor"
            v-on:subheader:icon-clicked="toggleContent"
            :remove-padding="removePadding"/>
        <divider
            v-if="showDivider"
            :color="colorDivider"
            :change-accent-for-theme="changeAccentForTheme"
            :use-margin-bottom="useMarginBottom"/>

        <v-expand-transition v-if="! withoutAnimation">
            <div v-show="hidden === false">
                <slot />
            </div>
        </v-expand-transition>
        <div v-show="hidden === false" v-else>
            <slot />
        </div>
    </div>
</template>

<script>
import SubHeader from './SubHeader';
import Divider   from './Divider';

export default {
    components: {
        SubHeader,
        Divider,
    },

    props: {
        withoutAnimation: {
            type:    Boolean,
            default: false,
        },

        startHidden: {
            type:    Boolean,
            default: false,
        },

        text: {
            type:     String,
            required: true,
        },

        inset: {
            type:    Boolean,
            default: false,
        },

        bolder: {
            type:    Boolean,
            default: false,
        },

        bigger: {
            type:    Boolean,
            default: false,
        },

        showDivider: {
            type:    Boolean,
            default: true,
        },

        color: {
            type:    String,
            default: 'info',
        },

        iconColor: {
            type:    String,
            default: 'info',
        },

        colorDivider: {
            type:    String,
            default: 'blue',
        },

        changeAccentForTheme: {
            type:    Boolean,
            default: true,
        },

        removePadding: {
            type:    Boolean,
            default: true,
        },

        useMarginBottom: {
            type:    Boolean,
            default: true,
        },
    },

    data: () => ({
        hidden: false,
    }),

    mounted() {
        this.hidden = this.startHidden === true;
    },

    computed: {
        icon() {
            return this.hidden ? '$plus' : '$minus';
        },
    },

    methods: {
        toggleContent() {
            this.hidden = ! this.hidden;

            this.$emit('subheader:content-visible', this.hidden);
        }
    }
};
</script>
