<template>
    <v-boilerplate-loading :elevation="0" v-if="! isLoaded" type="list-item-avatar-three-line@2" class="w-full"/>
    <div v-else-if="recipe.calorie.calories <= 0">
        Zu diesem Rezept gibt es keine Angaben zu den Kalorien
    </div>
    <div class="position-relative" v-else>
        <v-row>
            <v-col cols="12" :class="{
                'md:text-center': hasCaloriesData,
                'text-small': hasCaloriesData,
                'md:text-lg': hasCaloriesData,
                'leading-relaxed': hasCaloriesData
            }">
                <v-icon class="mr-2">$calories</v-icon>
                {{ recipe.calorie.calories_complete_label }}
            </v-col>
        </v-row>

        <v-row v-if="hasMainCaloriesData">
            <v-col cols="12" md="6" lg="4" v-if="recipe.calorie.fat_label">
                <div>
                    <v-icon class="mr-2">$caloriesFat</v-icon>
                    {{ recipe.calorie.fat_label }}
                </div>
                <div :class="{'ml-12': recipe.calorie.fat_label}" v-if="recipe.calorie.trans_fat_label_sub">
                    {{ recipe.calorie.trans_fat_label_sub }}
                </div>
                <div :class="{'ml-12': recipe.calorie.fat_label}" v-if="recipe.calorie.saturated_fat_label_sub">
                    {{ recipe.calorie.saturated_fat_label_sub }}
                </div>
            </v-col>

            <v-col cols="12" md="6" lg="4" v-if="recipe.calorie.carbohydrate_label">
                <div>
                    <v-icon class="mr-2">$caloriesCarbohydrate</v-icon>
                    {{ recipe.calorie.carbohydrate_label }}
                </div>
                <div :class="{'ml-12': recipe.calorie.carbohydrate_label}" v-if="recipe.calorie.sugar_label_sub">
                    {{ recipe.calorie.sugar_label_sub }}
                </div>
                <div :class="{'ml-12': recipe.calorie.carbohydrate_label}" v-if="recipe.calorie.dietary_fiber_label_sub">
                    {{ recipe.calorie.dietary_fiber_label_sub }}
                </div>
            </v-col>

            <v-col cols="12" md="6" lg="4" v-if="recipe.calorie.protein_label">
                <v-icon class="mr-2">$caloriesProtein</v-icon>
                {{ recipe.calorie.protein_label }}
            </v-col>
        </v-row>

        <v-divider v-if="hasMainCaloriesData && hasAdditionalCaloriesData" class="my-3"/>

        <v-row v-if="hasAdditionalCaloriesData">
            <v-col cols="12" md="6" lg="4" v-if="recipe.calorie.cholesterol_label">
                <v-icon class="mr-2">$caloriesCholesterol</v-icon>
                {{ recipe.calorie.cholesterol_label }}
            </v-col>
            <v-col cols="12" md="6" lg="4" v-if="recipe.calorie.sodium_label">
                <v-icon class="mr-2">$caloriesSodium</v-icon>
                {{ recipe.calorie.sodium_label }}
            </v-col>
            <v-col cols="12" md="6" lg="4" v-if="recipe.calorie.potassium_label">
                <v-icon class="mr-2">$caloriesPotassium</v-icon>
                {{ recipe.calorie.potassium_label }}
            </v-col>
            <v-col cols="12" md="6" lg="4" v-if="recipe.calorie.salt_label">
                <v-icon class="mr-2">$caloriesSalt</v-icon>
                {{ recipe.calorie.salt_label }}
            </v-col>
        </v-row>

        <v-divider v-if="(hasMainCaloriesData || hasAdditionalCaloriesData) && hasSubCaloriesData" class="my-3"/>

        <v-row v-if="hasSubCaloriesData">
            <v-col cols="12" md="6" lg="4" v-if="recipe.calorie.vitamin_a_label">
                {{ recipe.calorie.vitamin_a_label }}
            </v-col>
            <v-col cols="12" md="6" lg="4" v-if="recipe.calorie.vitamin_c_label">
                {{ recipe.calorie.vitamin_c_label }}
            </v-col>
            <v-col cols="12" md="6" lg="4" v-if="recipe.calorie.vitamin_d_label">
                {{ recipe.calorie.vitamin_d_label }}
            </v-col>
            <v-col cols="12" md="6" lg="4" v-if="recipe.calorie.calcium_label">
                {{ recipe.calorie.calcium_label }}
            </v-col>
            <v-col cols="12" md="6" lg="4" v-if="recipe.calorie.iron_label">
                {{ recipe.calorie.iron_label }}
            </v-col>
            <v-col cols="12" md="6" lg="4" v-if="recipe.calorie.magnesium_label">
                {{ recipe.calorie.magnesium_label }}
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default {
    components: {},

    props: {
        recipe: {
            type:     Object,
            required: true,
        },
    },

    data: () => ({}),

    watch: {},

    mounted() {

    },

    beforeDestroy() {

    },

    methods: {},

    computed: {
        hasCaloriesData() {
            return this.hasMainCaloriesData || this.hasAdditionalCaloriesData || this.hasSubCaloriesData;
        },

        hasMainCaloriesData() {
            return this.recipe.calorie.fat_label
                || this.recipe.calorie.trans_fat_label_sub
                || this.recipe.calorie.saturated_fat_label_sub
                || this.recipe.calorie.carbohydrate_label
                || this.recipe.calorie.sugar_label_sub
                || this.recipe.calorie.dietary_fiber_label_sub
                || this.recipe.calorie.protein_label;
        },

        hasAdditionalCaloriesData() {
            return this.recipe.calorie.cholesterol_label
                || this.recipe.calorie.potassium_label
                || this.recipe.calorie.sodium_label
                || this.recipe.calorie.salt_label;
        },

        hasSubCaloriesData() {
            return this.recipe.calorie.vitamin_a_label
                || this.recipe.calorie.vitamin_c_label
                || this.recipe.calorie.vitamin_d_label
                || this.recipe.calorie.magnesium_label
                || this.recipe.calorie.calcium_label
                || this.recipe.calorie.iron_label;
        },

        isLoaded() {
            if (
                this.recipe === null
                || typeof this.recipe !== 'object'
                || typeof this.recipe.calorie === 'undefined'
            ) {
                return false;
            }

            if (typeof this.recipe.calorie_loaded === 'undefined') {
                return true;
            }

            return this.recipe.calorie_loaded === true;
        },
    },
};
</script>
