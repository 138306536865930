<template>
    <content-loader
        :width="usedWidth"
        :height="height"
        :speed="2"
        :primaryColor="primaryColor"
        :secondaryColor="secondaryColor"
    >
        <slot></slot>
    </content-loader>
</template>

<script>
import {ContentLoader} from 'vue-content-loader';

export default {
    components: {
        ContentLoader,
    },
    props:      {
        primaryColor: {
            type:    String,
            default: '#676767',
        },

        secondaryColor: {
            type:    String,
            default: '#737373',
        },

        shouldBeBigger: {
            type:    Boolean,
            default: true,
        },

        changeBiggerOnMd: {
            type:    Boolean,
            default: true,
        },

        shouldBeLittleBigger: {
            type:    Boolean,
            default: false,
        },

        width: {
            type:    [String, Number],
            default: 400,
        },

        height: {
            type:    [String, Number],
            default: 130,
        },

        speed: {
            type:    [Number],
            default: 2,
        },

        changeAccentForTheme: {
            type:    Boolean,
            default: true,
        },
    },

    computed: {
        usedWidth() {
            if (this.shouldBeLittleBigger) {
                if (this.changeBiggerOnMd && this.$vuetify.breakpoint.mdOnly) {
                    return this.width * 1.75;
                }

                return this.width * 1.5;
            }

            if (this.shouldBeBigger) {
                if (this.changeBiggerOnMd && this.$vuetify.breakpoint.mdOnly) {
                    return this.width * 2.5;
                }

                return this.width * 2;
            }

            return this.width;
        },

        classes() {
            if (this.changeAccentForTheme) {
                classes.push(
                    this.$vuetify.theme.dark ? 'darken-4' : 'accent-2',
                );
            }
        },
    },
};
</script>
