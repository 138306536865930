<template>
    <div class="position-relative">
        <hideable-content-sub-header-with-divider text="Kategorisierung">
            <v-row>
                <v-col cols="12" md="6" lg="4">
                    <v-combobox
                        v-model="value.category"
                        :items="autocompletion.categories"
                        clearable
                        :loading="loadingAutocomplete"
                        hint="Vorschläge nicht passend? Einfach frei eingeben!"
                        persistent-hint
                        label="Kategorie"
                    ></v-combobox>
                </v-col>

                <v-col cols="12" md="6" lg="4">
                    <v-combobox
                        v-model="value.tags"
                        :items="autocompletion.tags"
                        label="Tags"
                        clearable
                        hide-selected
                        deletable-chips
                        small-chips
                        :loading="loadingAutocomplete"
                        multiple
                        chips
                    ></v-combobox>
                </v-col>

                <v-col cols="12" md="6" lg="4">
                    <v-autocomplete
                        v-model="value.dietType"
                        :items="autocompletion.dietTypes"
                        :loading="loadingAutocomplete"
                        label="Passend zur Diät"
                    ></v-autocomplete>
                </v-col>
            </v-row>
        </hideable-content-sub-header-with-divider>

        <hideable-content-sub-header-with-divider :text="timeLabel">
            <v-row>
                <v-col cols="12" md="6" lg="4">
                    <v-subheader v-if="showTimeTitles">Zeit zur Vorbereitung</v-subheader>
                    <time-minute-select-with-days-and-hours
                        title-in-bold
                        :additional-title-for-combined-if-empty="! showTimeTitles ? 'zur Vorbereitungsdauer' : null"
                        :additional-title-for-combined="! showTimeTitles ? 'vorbereiten' : null"
                        v-model.number="value.preparationTime"/>
                </v-col>

                <v-col cols="12" md="6" lg="4">
                    <v-subheader v-if="showTimeTitles">Zeit zum Kochen oder Backen</v-subheader>
                    <time-minute-select-with-days-and-hours
                        title-in-bold
                        :additional-title-for-combined-if-empty="! showTimeTitles ? 'zur Koch- oder Backzeit' : null"
                        :additional-title-for-combined="! showTimeTitles ? 'kochen oder backen' : null"
                        v-model.number="value.cookingBakingTime"/>
                </v-col>

                <v-col cols="12" md="6" lg="4">
                    <v-subheader v-if="showTimeTitles">Zeit fürs Ruhen</v-subheader>
                    <time-minute-select-with-days-and-hours
                        title-in-bold
                        :additional-title-for-combined-if-empty="! showTimeTitles ? 'zur Ruhezeit' : null"
                        :additional-title-for-combined="! showTimeTitles ? 'ruhen lassen' : null"
                        v-model.number="value.restingTime"/>
                </v-col>

            </v-row>
        </hideable-content-sub-header-with-divider>

        <hideable-content-sub-header-with-divider text="Informationen">
            <v-row>
                <v-col cols="12" md="6" lg="4">
                    <v-textarea
                        label="Beschreibung"
                        name="description"
                        auto-grow
                        rows="2"
                        v-model="value.description"
                    />
                </v-col>

                <v-col cols="12" md="6" lg="4">
                    <v-textarea
                        label="Notiz"
                        name="note"
                        auto-grow
                        rows="2"
                        v-model="value.note"
                    />
                </v-col>

                <v-col cols="12" md="6" lg="4">
                    <v-textarea
                        label="Vorschläge"
                        name="recommendations"
                        auto-grow
                        rows="2"
                        v-model="value.recommendations"
                    />
                </v-col>

                <v-col cols="12" md="6" lg="4">
                    <v-text-field
                        v-model="value.sourceUrl"
                        hint="Sollte das Rezept nicht von dir stammen, kannst du hier die URL zur eigentlichen Quelle angeben"
                        persistent-hint
                        label="URL zur Quelle"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" offset-lg="4" lg="4">
                    <v-text-field
                        v-model="value.sourceName"
                        hint="Hier kannst du einen Namen für die Quelle hinterlegen"
                        persistent-hint
                        label="Name der Quelle"
                    ></v-text-field>
                </v-col>
            </v-row>
        </hideable-content-sub-header-with-divider>
    </div>
</template>

<script>

import SubHeaderWithDivider                from '@symfia/library/Views/Helper/SubHeaderWithDivider';
import HideableContentSubHeaderWithDivider from '@symfia/library/Views/Helper/HideableContentSubHeaderWithDivider';
import TimeMinuteSelectWithDaysAndHours    from '@symfia/library/Views/Inputs/TimeMinuteSelectWithDaysAndHours';

export default {
    components: {TimeMinuteSelectWithDaysAndHours, HideableContentSubHeaderWithDivider, SubHeaderWithDivider},

    props: {
        value: {
            type:     Object,
            required: true,
        },

        autocompletion: {
            type:    Object,
            default: function () {
                return {};
            },
        },

        loadingAutocomplete: {
            type:    Boolean,
            default: false,
        },

        showTimeTitles: {
            type:    Boolean,
            default: false,
        },
    },

    watch: {
        value() {
            this.$emit('input', this.value);
        },
    },

    data: () => ({
        valueTest: 'asdf',
    }),

    beforeMount() {

    },

    mounted() {

    },

    beforeDestroy() {

    },

    methods: {},

    computed: {
        timeLabel: function () {
            let date1 = this.$date();
            let date2 = this.$date()
                .add(Number(this.value.preparationTime) + Number(this.value.cookingBakingTime) + Number(this.value.restingTime), 'minutes');

            let duration = this.$date.duration(date2.diff(date1));

            let years   = duration.years();
            let months  = duration.months();
            let days    = duration.days();
            let hours   = duration.hours();
            let minutes = duration.minutes();
            let texts   = [];

            if (years > 0) {
                texts.push((years === 1 ? 'Ein Jahr' : years + ' Jahre'));
            }

            if (months > 0) {
                texts.push((months === 1 ? 'ein Monat' : months + ' Monate'));
            }

            if (days > 0) {
                texts.push((days === 1 ? 'ein Tag' : days + ' Tage'));
            }

            if (hours > 0) {
                texts.push((hours === 1 ? 'eine Stunde' : hours + ' Stunden'));
            }

            if (minutes > 0) {
                texts.push((minutes === 1 ? 'eine Minute' : minutes + ' Minuten'));
            }

            let string = '';

            if (texts !== []) {
                if (texts.length > 2) {
                    string = texts.splice(0, texts.length - 1).join(', ');

                    string += ' und ' + texts[texts.length - 1];
                } else {
                    string = texts.join(' und ');
                }
            }

            return 'Dauer (' + (string === '' ? 'Keine Angaben' : 'Insgesamt ' + string) + ')';
        },
    },
};
</script>
