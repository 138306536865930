<template>
    <div>
        <v-subheader class="p-0 d-inline" v-if="useCombinedTitle" v-html="combinedTitle"/>

        <v-subheader class="p-0 d-inline" v-if="includeYear && (! useCombinedTitle || labelsWithCombined)">{{ years === 1 ? 'Ein Jahr' : years + ' Jahre' }}</v-subheader>
        <slider-with-input-and-plus-minus
            v-if="includeYear"
            v-model="years"
            :thumb-label="true"
            min="0"
            :step="$vuetify.breakpoint.smAndDown && ! useInputsOnMobile ? ($vuetify.breakpoint.xs ? stepYearsMobile : stepYearsTablet) : stepYears"
            dense
            :vertical="useVertical"
            :hint="useCombinedTitle && ! labelsWithCombined && ! hideSmallLabelIfCombinedWithoutLabel ? 'Jahre' : null"
            :persistent-hint="useCombinedTitle && ! labelsWithCombined && ! hideSmallLabelIfCombinedWithoutLabel"
            :max="maxYears"
            without-input-hint
            v-on:slider-with-input:input-value-not-taken="yearStepsInputValueTaken = false"
            v-on:slider-with-input:input-value-taken="yearStepsInputValueTaken = true"
        />

        <v-subheader class="p-0 d-inline" v-if="includeMonth && (! useCombinedTitle || labelsWithCombined)">{{ months === 1 ? 'Ein Monat' : months + ' Monate' }}</v-subheader>
        <slider-with-input-and-plus-minus
            v-if="includeMonth"
            v-model="months"
            :thumb-label="true"
            min="0"
            :step="$vuetify.breakpoint.smAndDown && ! useInputsOnMobile ? ($vuetify.breakpoint.xs ? stepMonthsMobile : stepMonthsTablet) : stepMonths"
            dense
            :vertical="useVertical"
            :hint="useCombinedTitle && ! labelsWithCombined && ! hideSmallLabelIfCombinedWithoutLabel ? 'Monate' : null"
            :persistent-hint="useCombinedTitle && ! labelsWithCombined && ! hideSmallLabelIfCombinedWithoutLabel"
            :max="maxMonths"
            without-input-hint
            v-on:slider-with-input:input-value-not-taken="monthStepsInputValueTaken = false"
            v-on:slider-with-input:input-value-taken="monthStepsInputValueTaken = true"
        />

        <v-subheader class="p-0 d-inline" v-if="! useCombinedTitle || labelsWithCombined">{{ days === 1 ? 'Ein Tag' : days + ' Tage' }}</v-subheader>
        <slider-with-input-and-plus-minus
            v-model="days"
            :thumb-label="true"
            min="0"
            :step="$vuetify.breakpoint.smAndDown && ! useInputsOnMobile ? ($vuetify.breakpoint.xs ? stepDaysMobile : stepDaysTablet) : stepDays"
            dense
            :vertical="useVertical"
            :hint="useCombinedTitle && ! labelsWithCombined && ! hideSmallLabelIfCombinedWithoutLabel ? 'Tage' : null"
            :persistent-hint="useCombinedTitle && ! labelsWithCombined && ! hideSmallLabelIfCombinedWithoutLabel"
            :max="maxDays"
            without-input-hint
            v-on:slider-with-input:input-value-not-taken="dayStepsInputValueTaken = false"
            v-on:slider-with-input:input-value-taken="dayStepsInputValueTaken = true"
        />

        <v-subheader class="p-0 d-inline" v-if="! useCombinedTitle || labelsWithCombined">{{ hours === 1 ? 'Eine Stunde' : hours + ' Stunden' }}</v-subheader>
        <slider-with-input-and-plus-minus
            v-model="hours"
            :thumb-label="true"
            min="0"
            :step="$vuetify.breakpoint.smAndDown && ! useInputsOnMobile ? ($vuetify.breakpoint.xs ? stepHoursMobile : stepHoursTablet) : stepHours"
            dense
            :vertical="useVertical"
            :hint="useCombinedTitle && ! labelsWithCombined && ! hideSmallLabelIfCombinedWithoutLabel ? 'Stunden' : null"
            :persistent-hint="useCombinedTitle && ! labelsWithCombined && ! hideSmallLabelIfCombinedWithoutLabel"
            :max="maxHours"
            without-input-hint
            v-on:slider-with-input:input-value-not-taken="hourStepsInputValueTaken = false"
            v-on:slider-with-input:input-value-taken="hourStepsInputValueTaken = true"
        />

        <v-subheader class="p-0 d-inline" v-if="! useCombinedTitle || labelsWithCombined">{{ minutes === 1 ? 'Eine Minute' : minutes + ' Minuten' }}</v-subheader>
        <slider-with-input-and-plus-minus
            v-model="minutes"
            :thumb-label="true"
            min="0"
            :step="wantedMinuteSteps"
            dense
            :vertical="useVertical"
            :max="maxMinutes"
            :hint="hintForMinutes"
            :persistent-hint="hintForMinutes !== null"
            without-input-hint
            v-on:slider-with-input:input-value-not-taken="minuteStepsInputValueTaken = false"
            v-on:slider-with-input:input-value-taken="minuteStepsInputValueTaken = true"
        >
            <template v-slot:hint v-if="changeableMinutes">
                Minuten in {{ wantedMinuteSteps }}er Schritte zu ungenau?
                <v-btn x-small depressed text @click="internalMinuteSteps = 1">1er Schritte nutzen</v-btn>
                <template v-if="minuteStepsInputValueTaken === false">
                    <br>Aufgrund der {{ wantedMinuteSteps }}er Schritte, ist deine Eingabe gerundet worden und beträgt weiterhin {{ minutes }}
                </template>
            </template>
        </slider-with-input-and-plus-minus>
    </div>
</template>

<script>
import SliderWithInput             from '@symfia/library/Views/Inputs/SliderWithInput';
import SliderWithInputAndPlusMinus from '@symfia/library/Views/Inputs/SliderWithInputAndPlusMinus';

export default {
    components: {SliderWithInputAndPlusMinus, SliderWithInput},
    props:      {
        value: {
            type:     Number,
            required: true,
        },

        additionalTitleForCombined: {
            type:    String,
            default: null,
        },

        additionalTitleForCombinedIfEmpty: {
            type:    String,
            default: null,
        },

        titleInBold: {
            type:    Boolean,
            default: false,
        },

        useCombinedTitle: {
            type:    Boolean,
            default: true,
        },

        labelsWithCombined: {
            type:    Boolean,
            default: false,
        },

        hideSmallLabelIfCombinedWithoutLabel: {
            type:    Boolean,
            default: false,
        },

        useInputs: {
            type:    Boolean,
            default: true,
        },

        useInputsOnMobile: {
            type:    Boolean,
            default: true,
        },

        useVertical: {
            type:    Boolean,
            default: false,
        },

        includeMonth: {
            type:    Boolean,
            default: false,
        },

        includeYear: {
            type:    Boolean,
            default: false,
        },

        maxYears: {
            type:    Number,
            default: 10,
        },

        maxMonths: {
            type:    Number,
            default: 11,
        },

        maxDays: {
            type:    Number,
            default: 30,
        },

        maxHours: {
            type:    Number,
            default: 23,
        },

        maxMinutes: {
            type:    Number,
            default: 59,
        },

        stepYears: {
            type:    Number,
            default: 1,
        },

        stepMonths: {
            type:    Number,
            default: 1,
        },

        stepDays: {
            type:    Number,
            default: 1,
        },

        stepHours: {
            type:    Number,
            default: 1,
        },

        stepMinutes: {
            type:    Number,
            default: 5,
        },

        stepYearsMobile: {
            type:    Number,
            default: 1,
        },

        stepMonthsMobile: {
            type:    Number,
            default: 1,
        },

        stepDaysMobile: {
            type:    Number,
            default: 1,
        },

        stepHoursMobile: {
            type:    Number,
            default: 1,
        },

        stepMinutesMobile: {
            type:    Number,
            default: 5,
        },

        stepYearsTablet: {
            type:    Number,
            default: 1,
        },

        stepMonthsTablet: {
            type:    Number,
            default: 1,
        },

        stepDaysTablet: {
            type:    Number,
            default: 1,
        },

        stepHoursTablet: {
            type:    Number,
            default: 1,
        },

        stepMinutesTablet: {
            type:    Number,
            default: 2,
        },
    },

    data: () => ({
        years:                      0,
        months:                     0,
        days:                       0,
        hours:                      0,
        minutes:                    0,
        internalMinuteSteps:        null,
        minuteStepsInputValueTaken: true,
        hourStepsInputValueTaken:   true,
        dayStepsInputValueTaken:    true,
        monthStepsInputValueTaken:  true,
        yearStepsInputValueTaken:   true,
    }),

    mounted() {
        this.parseMinutesIntoFields();
    },

    watch: {
        value() {
            this.$emit('time-minute-select:updated-time', this.value);
            this.$emit('input', this.value);
        },

        years: function () {
            if (this.years < 0) {
                this.years = 0;
            }

            if (this.years > this.maxYears) {
                this.years = this.maxYears;
            }

            this.updateMinutes();
        },

        months: function () {
            if (this.months < 0) {
                this.months = 0;
            }

            if (this.months > this.maxMonths) {
                this.months = this.maxMonths;
            }

            this.updateMinutes();
        },

        days: function () {
            if (this.days < 0) {
                this.days = 0;
            }

            if (this.days > this.maxDays) {
                this.days = this.maxDays;
            }

            this.updateMinutes();
        },

        hours: function () {
            if (this.hours < 0) {
                this.hours = 0;
            }

            if (this.hours > this.maxHours) {
                this.hours = this.maxHours;
            }

            this.updateMinutes();
        },

        minutes: function () {
            if (this.minutes < 0) {
                this.minutes = 0;
            }

            if (this.minutes > this.maxMinutes) {
                this.minutes = this.maxMinutes;
            }

            this.updateMinutes();
        },
    },

    methods: {
        parseMinutesIntoFields() {
            let years   = 0;
            let months  = 0;
            let days    = 0;
            let hours   = 0;
            let minutes = 0;

            if (this.value > 0) {
                let date1 = this.$date();
                let date2 = this.$date().add(this.value, 'minutes');

                let duration = this.$date.duration(date2.diff(date1));

                days    = duration.days();
                hours   = duration.hours();
                minutes = duration.minutes();

                if (duration.seconds() > 0) {
                    minutes++;
                }

                if (this.includeMonth) {
                    months = duration.months();

                    if (this.includeYear) {
                        years = duration.years();
                    } else if (duration.years() >= 1) {
                        let date1ForMonths = this.$date();
                        let date2ForMonths = this.$date().add(duration.years(), 'years');

                        let newDurationForMonths = this.$date.duration(date2ForMonths.diff(date1ForMonths));

                        months += Math.round(newDurationForMonths.asMonths());
                    }

                } else if (duration.months() >= 1 || duration.years() >= 1) {
                    let date1ForDays = this.$date();
                    let date2ForDays = this.$date().add(duration.months(), 'months').add(duration.years(), 'years');

                    let newDurationForDays = this.$date.duration(date2ForDays.diff(date1ForDays));

                    days += Math.round(newDurationForDays.asDays());
                }
            }

            this.days    = days;
            this.minutes = minutes;
            this.hours   = hours;
            this.months  = months;
            this.years   = years;

        },

        updateMinutes() {
            let date1 = this.$date();
            let date2 = this.$date()
                .add(this.minutes, 'minutes')
                .add(this.hours, 'hours')
                .add(this.days, 'days')
                .add(this.months, 'months')
                .add(this.years, 'years');

            let duration = this.$date.duration(date2.diff(date1));

            this.$emit('input', duration.asMinutes());
        },
    },

    computed: {
        wantedMinuteSteps: function () {
            if (this.internalMinuteSteps !== null) {
                return this.internalMinuteSteps;
            }

            if (this.$vuetify.breakpoint.smAndDown) {
                if (this.useInputsOnMobile === false) {
                    return (this.$vuetify.breakpoint.xs ? this.stepMinutesMobile : this.stepMinutesTablet);
                }
            }

            return this.stepMinutes;
        },

        changeableMinutes: function () {
            return this.internalMinuteSteps === null && this.wantedMinuteSteps !== 1;
        },

        hintForMinutes: function () {
            if (this.changeableMinutes) {
                return `Minuten in ${this.wantedMinuteSteps}er Schritten`;
            }

            return 'Minuten';
        },

        combinedTitle: function () {
            let texts   = [];
            let hasData = false;
            let title   = '';

            if (this.years > 0) {
                texts.push((this.years === 1 ? 'Ein Jahr' : this.years + ' Jahre'));
                hasData = true;
            }

            if (this.months > 0) {
                texts.push((this.months === 1 ? (hasData ? 'e' : 'E') + 'in Monat' : this.months + ' Monate'));
                hasData = true;
            }

            if (this.days > 0) {
                texts.push((this.days === 1 ? (hasData ? 'e' : 'E') + 'in Tag' : this.days + ' Tage'));
                hasData = true;
            }

            if (this.hours > 0) {
                texts.push((this.hours === 1 ? (hasData ? 'e' : 'E') + 'ine Stunde' : this.hours + ' Stunden'));
                hasData = true;
            }

            if (this.minutes > 0) {
                texts.push((this.minutes === 1 ? (hasData ? 'e' : 'E') + 'ine Minute' : this.minutes + ' Minuten'));
            }

            let string = '';

            if (texts !== []) {
                if (texts.length > 2) {
                    string = texts.splice(0, texts.length - 1).join(', ');

                    string += ' und ' + texts[texts.length - 1];
                } else {
                    string = texts.join(' und ');
                }
            }

            let finalString = string === '' ? 'Keine Angaben' : string;

            let additionalTitle = null;

            if (this.additionalTitleForCombined && this.additionalTitleForCombined !== '') {
                additionalTitle = this.additionalTitleForCombined;
            }

            if (string === '' && this.additionalTitleForCombinedIfEmpty && this.additionalTitleForCombinedIfEmpty !== '') {
                additionalTitle = this.additionalTitleForCombinedIfEmpty;
            }

            if (additionalTitle) {
                title = ' ' + (this.titleInBold ? '<span class="font-bold ml-1">' + additionalTitle + '</span>' : additionalTitle);
            }

            return finalString + title;
        },
    },
};
</script>
