<template>
    <div class="position-relative">
        <hideable-content-with-input-and-divider
            v-for="(stepGroup, index) in stepGroups"
            :key="'step-group-'+index"
            v-model="stepGroup.title"
            hint="Optionaler Titel der Gruppe an Zubereitungsschritten"
            persistent-hint
            type="text"
            label="Titel"
            class="mt-6"
        >
            <v-row v-for="(step, stepIndex) in stepGroup.steps" :key="'step-group-'+index+'-step-'+stepIndex">
                <v-col cols="12" sm="4" md="3" lg="3">
                    <ValidationProvider :vid="'step-group-'+index+'-step-'+stepIndex" :name="'Zubereitungsschritt ' + (stepIndex + 1) + ' (Gruppe: '+(index + 1)+')'" :rules="rules.name" v-slot="{ errors, valid }">
                        <v-textarea
                            :error-messages="errors"
                            :success="valid"
                            v-model="step.instruction"
                            clearable
                            hint="Beschreibung"
                            persistent-hint
                            auto-grow
                            label="Zubereitungsschritt"
                        >
                            <span slot="label">Zubereitungsschritt <span class="required-asterisk-mark">*</span></span>
                        </v-textarea>
                    </ValidationProvider>
                </v-col>

                <v-col cols="12" sm="2" md="2" lg="2">
                    <v-text-field
                        v-model="step.title"
                        type="text"
                        label="Optionaler Titel"
                        persistent-hint
                        clearable
                    />
                </v-col>

                <v-col cols="12" sm="3" md="3" lg="3">
                    <v-select
                        v-model="step.level"
                        :loading="loadingAutocomplete"
                        :disabled="loadingAutocomplete"
                        :items="autocompletion.difficulties"
                        label="Optionaler Schwierigkeitsgrad"
                        @change="prepareStepGroupTimeAndLevel(index)"
                        clearable
                    ></v-select>
                </v-col>

                <v-col cols="12" sm="2" md="3" lg="3">
                    <time-minute-select-with-days-and-hours
                        title-in-bold
                        v-model.number="step.time"
                        v-on:time-minute-select:updated-time="prepareStepGroupTimeAndLevel(index)"
                    />
                </v-col>

                <v-col cols="12" sm="1" md="1" lg="1" class="flex items-center">
                    <v-btn color="primary" icon class="cursor-pointer" v-if="(stepGroups[index].steps.length - 1) === stepIndex" :ripple="false" @click="addStep(index)">
                        <v-icon>$plus</v-icon>
                    </v-btn>
                    <v-btn icon color="error" class="cursor-pointer" v-if="stepGroups[index].steps.length > 1" :ripple="false" @click="deleteStepStep(index, stepIndex)">
                        <v-icon>$minus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <div class="mt-3">
                <v-btn x-small v-if="(stepGroups.length - 1) === index" color="primary" :ripple="false" @click="addStepGroup">Neue Gruppe</v-btn>
                <v-btn :class="{'ml-3': (stepGroups.length - 1) === index}" x-small v-if="stepGroups.length > 1" @click="deleteStepGroup(index)" color="error" :ripple="false">Gruppe löschen</v-btn>
            </div>
        </hideable-content-with-input-and-divider>
    </div>
</template>

<script>

import HideableContentWithInputAndDivider from '@symfia/library/Views/Helper/HideableContentWithInputAndDivider';
import TimeMinuteSelectWithDaysAndHours   from '@symfia/library/Views/Inputs/TimeMinuteSelectWithDaysAndHours';
import {IngredientAndStepRule}            from '../../Config/Rules/CreateEdit';

export default {
    components: {
        HideableContentWithInputAndDivider,
        TimeMinuteSelectWithDaysAndHours,
    },

    props: {
        value: {
            type:     [Array, null, undefined],
            required: true,
        },

        initialSteps: {
            type:    Array,
            default: function () {
                return [];
            },
        },

        autocompletion: {
            type:    Object,
            default: function () {
                return {};
            },
        },

        loadingAutocomplete: {
            type:    Boolean,
            default: false,
        },
    },

    watch: {
        stepGroups() {
            this.prepareTimeAndLevelForStepGroups();

            this.$nextTick(() => {
                this.$emit('input', this.stepGroups);
            });
        },
    },

    data: () => ({
        rules:           {
            name: IngredientAndStepRule,
        },
        stepGroups:      [],
        showTimeTitles:  true,
        levelComplexity: {
            'very-easy': 1,
            'easy':      2,
            'normal':    3,
            'hard':      4,
            'very-hard': 5,
        },
    }),

    mounted() {
        this.stepGroups = this.initialSteps || this.value || [];

        this.$nextTick(() => {
            this.initStepGroups();
        });
    },

    beforeDestroy() {

    },

    methods: {
        initStepGroups() {
            if (this.stepGroups.length === 0) {
                this.addStepGroup();
            } else if (this.stepGroups[0].steps.length === 0) {
                this.addStep(0);
            }
        },

        createStepGroup(steps = [], title = '', time = 0, level = '') {
            return {
                steps: steps,
                title: title,
                time:  time,
                level: level,
            };
        },

        createStep(instruction = '', title = '', time = 0, level = '') {
            return {
                instruction: instruction,
                time:        time,
                title:       title,
                level:       level,
            };
        },

        addStepGroup() {
            this.stepGroups.push(this.createStepGroup());

            this.addStep(this.stepGroups.length - 1);
        },

        addStep(stepGroupIndex) {
            this.stepGroups[stepGroupIndex].steps.push(this.createStep());
        },

        deleteStepStep(stepGroupIndex, stepIndex) {
            this.stepGroups[stepGroupIndex].steps.splice(stepIndex, 1);
            this.prepareStepGroupTimeAndLevel(stepGroupIndex);
        },

        deleteStepGroup(stepGroupIndex) {
            this.stepGroups.splice(stepGroupIndex, 1);
        },

        prepareTimeAndLevelForStepGroups() {
            this.stepGroups.forEach((stepGroup, stepGroupIndex) => {
                this.prepareStepGroupTimeAndLevel(stepGroupIndex);
            });
        },

        prepareStepGroupTimeAndLevel(stepGroupIndex) {
            let time            = 0;
            let level           = '';
            let levelComplexity = 0;

            for (let step of this.stepGroups[stepGroupIndex].steps) {
                time += step.time || 0;

                if (step.level && step.level !== '' && levelComplexity < this.levelComplexity[step.level]) {
                    level           = step.level;
                    levelComplexity = this.levelComplexity[step.level];
                }
            }

            this.stepGroups[stepGroupIndex].time  = time;
            this.stepGroups[stepGroupIndex].level = level;
        },
    },
};
</script>
