<template>
    <v-text-field
        class="portions-text-input"
        :placeholder="usePlaceholder ? portionsInputText : null"
        :label="usePlaceholder ? null : portionsInputText"
        :value="actualPortions"
        type="number"
        :prepend-inner-icon="reverseIconDisplay ? null : '$portions'"
        :append-icon="reverseIconDisplay ? '$portions' : null"
        prepend-icon="$minus"
        append-outer-icon="$plus"
        :hint="portionsInputHint"
        :clearable="hasModifiedPortions"
        v-on:click:append-outer="increasePortions"
        v-on:click:prepend="decreasePortions"
        persistent-hint
        @input="updatePortions"
    ></v-text-field>
</template>

<style>
.portions-text-input input::-webkit-outer-spin-button,
.portions-text-input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.portions-text-input input[type=number] {
    -moz-appearance: textfield;
}
</style>

<script>
export default {
    components: {},

    props: {
        originalPortions: {
            type:    [String, Number],
            default: null,
        },

        portions: {
            type:    [String, Number],
            default: null,
        },

        moreInformationsLabel: {
            type:    Boolean,
            default: false,
        },

        usePlaceholder: {
            type:    Boolean,
            default: false,
        },

        reverseIconDisplay: {
            type:    Boolean,
            default: true,
        },
    },

    data: () => ({
        modifiedPortions: null,
    }),

    watch: {
        originalPortions(newValue) {
            if (! this.portions || this.portions <= 0) {
                this.modifiedPortions = newValue;
            }
        },

        portions(newValue) {
            this.modifiedPortions = newValue ? newValue : this.originalPortions;
        },

        modifiedPortions(newValue) {
            if (newValue !== this.originalPortions && newValue !== this.portions) {
                this.$emit('recipe:portions:updated', newValue);
            }
        },
    },

    beforeMount() {
        if (this.portions) {
            this.modifiedPortions = this.portions;
        } else if (this.originalPortions) {
            this.modifiedPortions = this.originalPortions;
        }
    },

    beforeDestroy() {

    },

    methods: {
        updatePortions(portions) {
            this.$emit('recipe:portions:updated', portions);
        },

        increasePortions() {
            let actual = this.actualPortions;

            if (! actual) {
                actual = 1;
            }

            this.$emit('recipe:portions:updated', this.actualPortions + 1);
        },

        decreasePortions() {
            let actual = this.actualPortions;

            if (! actual) {
                actual = 1;
            }

            let decreased = actual - 1;

            if (decreased <= 0) {
                decreased = null;
            }

            this.$emit('recipe:portions:updated', decreased);
        }
    },

    computed: {
        actualPortions() {
            let original = this.originalPortions ? parseInt(this.originalPortions) : null;
            let modified = this.portions ? parseInt(this.portions) : null;

            if (modified && modified !== original) {
                return modified;
            }

            return original;
        },

        portionsInputHint() {
            if (! this.hasModifiedPortions) {
                return null;
            }

            return `Portionen verändert - Original ${this.originalPortions} Portionen`;
        },

        hasModifiedPortions() {
            if (! this.originalPortions || this.originalPortions <= 0) {
                return false;
            }

            if (! this.portions || this.portions <= 0) {
                return false;
            }

            return this.originalPortions !== this.portions;
        },

        portionsInputText() {
            let label = null;

            if (this.portions && this.portions >= 0) {
                label = this.portions + ' Portionen';
            }

            if (this.originalPortions && this.originalPortions >= 0) {
                label = this.originalPortions + ' Portionen';
            }

            if (label && this.moreInformationsLabel) {
                label = `Berechnet für ${label}`;
            }

            return label;
        },
    },
};
</script>
