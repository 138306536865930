import '@symfia/core/Validation/Vee-Validate/Rules/Max';

const titleRule = {
    required: {
        allowFalse: false,
        max:        125,
    },
};

const IngredientAndStepRule = {
          required: true,
};

export {
    titleRule,
    IngredientAndStepRule
};
