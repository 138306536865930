<template>
    <div class="position-relative">
        <div class="text-center" v-if="loading">
            <v-icon>$spinner</v-icon>
            - Wir laden die verfügbaren Anbieter
        </div>
        <div v-else-if="providers.length > 0">
            <v-alert class="mb-2" type="error" v-if="error">
                {{ error }}
            </v-alert>
            <import-info-alert
                v-if="! error && ! hideInfo"
            />

            <v-form>
                <v-text-field
                    v-for="(field, index) in fields"
                    v-model="field.value"
                    :key="'import-form-field'+index"
                    label="URL"
                ></v-text-field>

                <v-btn @click="importPages" v-if="! hideImportButton" :loading="importing" color="success">Importieren</v-btn>
            </v-form>
        </div>
        <div v-else>
            Leider haben wir momentan keine Anbieter, von denen du etwas importieren kannst.
        </div>
    </div>
</template>

<script>
import ImportInfoAlert from '../Shared/ImportInfoAlert';

export default {
    components: {
        ImportInfoAlert,
    },

    props: {
        preloadedProviders: {
            type:    Array,
            default: null,
        },

        hideInfo: {
            type:    Boolean,
            default: false,
        },

        hideImportButton: {
            type:    Boolean,
            default: false,
        },
    },

    mounted() {
        this.addField();

        if (this.preloadedProviders !== null) {
            this.providers = this.preloadedProviders;
        } else {
            this.loadImportSites();
        }
    },

    beforeDestroy() {
        this.cancelRequests();
    },

    watch: {
        fields: {
            deep: true,

            handler() {
                if (this.needsCheck) {
                    this.$nextTick(() => {
                        this.checkFields();
                    });
                }
            },
        },
    },

    data: () => ({
        providers:        [],
        fields:           [],
        loading:          false,
        importing:        false,
        axiosToken:       null,
        axiosTokenImport: null,
        needsCheck:       true,
        error:            null,
    }),

    methods: {
        addField() {
            this.fields.push({
                value: '',
            });
        },

        removeField(index) {
            this.fields.splice(index, 1);
        },

        checkFields() {
            this.needsCheck = false;
            this.error      = null;

            if (this.fields.length === 0) {
                this.$logger.debug(
                    'Keine Felder - füge eins hinzu',
                    null,
                    'Recipes-Import',
                );

                this.addField();
            } else {
                this.$logger.debug(
                    'Felder gegenprüfen',
                    this.fields,
                    'Recipes-Import',
                );

                let emptyElements = [];

                this.fields.forEach((field, index) => {
                    if (typeof field.value !== 'undefined' && (field.value === '' || field.value === ' ')) {
                        this.$logger.debug(
                            'Feld ist leer',
                            {field, index},
                            'Recipes-Import',
                        );

                        emptyElements.push(index);
                    }
                });

                this.$logger.debug(
                    'Empty Elements',
                    emptyElements,
                    'Recipes-Import',
                );

                if (emptyElements.length === 0) {
                    this.$logger.debug(
                        'Alle Felder befüllt - neues hinzufügen',
                        null,
                        'Recipes-Import',
                    );

                    this.addField();
                } else {
                    if (emptyElements.length > 1) {
                        emptyElements.sort((a, b) => b - a);

                        this.$logger.debug(
                            'Leere Felder Desc sortiert',
                            emptyElements,
                            'Recipes-Import',
                        );

                        emptyElements.forEach(element => {
                            this.needsCheck = false;
                            this.removeField(element);
                        });

                        this.addField();
                    }
                }

                this.$nextTick(() => {
                    this.needsCheck = true;
                });
            }
        },

        cancelRequests() {
            if (this.axiosToken) {
                this.axiosToken.cancel();
                this.axiosToken = null;
                this.loading    = false;
            }

            if (this.axiosTokenImport) {
                this.axiosTokenImport.cancel();
                this.axiosTokenImport = null;
                this.importing        = false;
            }
        },

        loadImportSites() {
            if (this.loading) {
                return;
            }

            this.loading    = true;
            this.axiosToken = this.$axiosToken.source();

            this.$axios.get(this.route('cookbook.recipes.import.providers'),
                {
                    cancelToken: this.axiosToken.token,
                },
            ).then((response) => {
                this.$logger.debug('Recipe Import Providers', response.data, 'Recipes-Import');

                this.providers = Object.values(response.data);
            }).finally(() => {
                this.axiosToken = null;
            });
        },

        importPages() {
            if (this.importing) {
                return;
            }

            this.error = null;
            let urls   = [];

            this.fields.forEach((field) => {
                if (typeof field.value !== 'undefined' && field.value !== '' && field.value !== ' ') {
                    urls.push(field.value);
                }
            });

            if (urls.length === 0) {
                this.error = 'Du hast keine Urls angegeben.';

                return;
            }

            this.axiosTokenImport = this.$axiosToken.source();
            this.importing        = true;
            this.$emit('recipes:importing', true);

            this.$axios.post(this.route('cookbook.recipes.import.providers'),
                {
                    urls: urls,
                },
                {
                    cancelToken: this.axiosTokenImport.token,
                },
            ).then((response) => {
                this.$notifications.success('Import angestoßen - wir informieren dich immer über den aktuellen Status');

                this.$emit('recipes:imported', response.data);
                this.$logger.debug('Recipes imported', response.data, 'Recipes-Import');
                this.fields = [];
                this.error  = null;
            }).catch((error) => {
                let response = null;
                let message  = null;

                if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined') {
                    response = error.response.data;
                    message  = error.response.data.message || null;
                }

                this.$emit('recipes:importFailed', response);
                this.error = message === null ? 'Leider ist der Import fehlgeschlagen. Am besten meldest du den Fehler' : message;
            }).finally(() => {
                this.axiosTokenImport = null;
                this.importing        = false;
            });
        },
    },

    computed: {},
};
</script>
