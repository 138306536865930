<template>
    <div class="position-relative">
        <v-row>
            <v-col cols="12" md="6" lg="8" order="2" order-md="1">
                <recipe-additionals v-if="showAdditionals" :show-divider="false" :recipe="recipe" />

                <steps show-title-if-necessary is-together :is-side-by-side="! $breakpoint.smAndDown" :recipe="recipe"/>
            </v-col>

            <v-col cols="12" md="6" lg="4" order="1" order-md="2">
                <ingredients
                    :portions-for-calculation="portionsForCalculation"
                    show-title-if-necessary
                    is-together
                    v-on:recipe:portions:updated="updatedPortions"
                    :is-side-by-side="! $breakpoint.smAndDown"
                    :recipe="recipe"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Ingredients          from './Ingredients';
import Steps             from './Steps';
import RecipeAdditionals from './RecipeAdditionals';

export default {
    components: {
        Steps,
        RecipeAdditionals,
        Ingredients,
    },

    props: {
        recipe: {
            type:     Object,
            required: true,
        },

        portionsForCalculation: {
            type:    [Number, String],
            default: null,
        },

        showAdditionals: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({}),

    watch: {},

    mounted() {

    },

    beforeDestroy() {

    },

    methods: {
        updatedPortions(newPortions) {
            this.$emit('recipe:portions:updated', newPortions ? parseInt(newPortions) : null)
        }
    },

    computed: {
        stepsLoaded() {
            if (
                this.recipe === null
                || typeof this.recipe !== 'object'
                || typeof this.recipe.step_groups === 'undefined'
            ) {
                return true;
            }

            if (typeof this.recipe.step_groups_loaded === 'undefined') {
                return false;
            }

            return this.recipe.step_groups_loaded === false;
        },

        ingredientsLoaded() {
            if (
                this.recipe === null
                || typeof this.recipe !== 'object'
                || typeof this.recipe.ingredient_groups === 'undefined'
            ) {
                return true;
            }

            if (typeof this.recipe.ingredient_groups_loaded === 'undefined') {
                return false;
            }

            return this.recipe.ingredient_groups_loaded === false;
        },
    },
};
</script>
