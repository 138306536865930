<template>
    <div class="position-relative">
        <step-title :step="step" />
        <div class="prose-sm md:prose !text-inherit pr-0 mr-0 max-w-full md:max-w-full" v-html="step.instruction" />
        <step-sub-informations class="ml-1" :sub-data="step" secondary/>
    </div>
</template>

<script>
import SubHeaderWithDivider from '@symfia/library/Views/Helper/SubHeaderWithDivider';
import Divider              from '@symfia/library/Views/Helper/Divider';
import SubHeader            from '@symfia/library/Views/Helper/SubHeader';
import StepTitle            from './StepTitle';
import StepSubInformations  from './StepSubInformations';

export default {
    components: {
        SubHeaderWithDivider,
        Divider,
        SubHeader,
        StepTitle,
        StepSubInformations,
    },

    props: {
        step: {
            type:     Object,
            required: true,
        },
    },

    data: () => ({}),

    watch: {},

    mounted() {

    },

    beforeDestroy() {

    },

    methods: {},

    computed: {
        instruction() {
            if (! this.step.instruction || this.step.instruction === '') {
                return null;
            }

            this.step.instruction.replaceAll('\n', '<br />');
        }
    },
};
</script>
