<template>
    <v-divider :inset="inset" :class="classes" :vertical="useVertical"/>
</template>

<script>
    export default {
        props: {
            color: {
                type:    String,
                default: 'blue',
            },

            useMarginTop: {
                type:    Boolean,
                default: false,
            },

            inset: {
                type:    Boolean,
                default: false,
            },

            useVertical: {
                type:    Boolean,
                default: false,
            },

            useMarginBottom: {
                type:    Boolean,
                default: true,
            },

            changeAccentForTheme: {
                type:    Boolean,
                default: true,
            },
        },

        computed: {
            classes() {
                let classes = [this.color];

                if (this.useMarginBottom) {
                    classes.push(
                        this.$vuetify.breakpoint.smAndDown ? 'mb-4' : 'mb-2'
                    );
                }

                if (this.useMarginTop) {
                    classes.push(
                        this.$vuetify.breakpoint.smAndDown ? 'mt-4' : 'mt-2'
                    );
                }

                if (this.changeAccentForTheme) {
                    classes.push(
                        this.$vuetify.theme.dark ? 'darken-4' : 'accent-2'
                    );
                }

                return classes.join(' ');
            }
        },
    };
</script>
