<template>
    <div>
        <v-row>
            <v-col
                :cols="xsCols"
                :sm="smCols"
                :md="mdCols"
                :lg="lgCols"
                :xl="xlCols"
                :style="style"
                class="flex items-stretch w-full"
                :key="recipe.id"
                v-for="(recipe) in recipes"
            >
                <card-list-item
                    :is-own-view="isOwnView"
                    :recipe="recipe"
                    v-on:recipes:select="$emit('recipes:select', $event)"
                    v-on:recipes:delete="$emit('recipes:delete', $event)"
                    :is-large="isLarge"
                    :title-inline="titleInline"
                    :open-delay-hover="openDelayHover"
                    :title-inline-if-no-poster="titleInlineIfNoPoster"
                    :on-dashboard="onDashboard"
                    :dashboard-used-cols="dashboardUsedCols"
                    :dashboard-full="dashboardFull"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import CardListItem from './CardListItem';
    import {
        flagName as RecipeCardsListMoreColumnsName,
        defaultActive as RecipeCardsListMoreColumnsDefault,
    }                   from '../../Config/Flags/RecipesCardsListMoreColumns.js';

    export default {
        components: {
            CardListItem,
        },

        props: {
            recipes:     {
                type:     Array,
                required: true,
            },

            isOwnView: {
                type:    Boolean,
                default: false,
            },

            forceLarge: {
                type:    Boolean,
                default: false,
            },

            onDashboard: {
                type: Boolean,
                default: false
            },

            dashboardFull: {
                type: Boolean,
                default: false
            },

            dashboardUsedCols: {
                type: Number,
                default: 6
            },
        },

        watch: {},

        mounted() { },

        data: () => ({
            overrideLarge: null,
            titleInline: true,
            titleInlineIfNoPoster: true,
            openDelayHover: 350
        }),

        beforeDestroy() {

        },

        methods: {
            updateRecipe(recipe) {
                this.$emit('recipe:updated', recipe);
            }
        },

        computed: {
            wantsLargeNavigation() {
                return this.isFeatureFlagActive(RecipeCardsListMoreColumnsName, RecipeCardsListMoreColumnsDefault);
            },

            isLarge() {
                if (this.overrideLarge === true) {
                    return true;
                }

                if (this.wantsLargeNavigation) {
                    return true;
                }

                return this.forceLarge;
            },

            xsCols() {
                return 12;
            },

            smCols() {
                if (this.onDashboard) {
                    return 12;
                }

                return this.isLarge ? 6 : 12;
            },

            mdCols() {
                if (this.onDashboard && ! this.dashboardFull) {
                    return 6;
                }

                return this.isLarge ? 4 : 6;
            },

            lgCols() {
                if (this.onDashboard && ! this.dashboardFull) {
                    return 6;
                }

                return this.isLarge ? 3 : 4;
            },

            xlCols() {
                if (this.isLarge && this.is4kResolution()) {
                    return 2;
                }

                return 3;
            },

            style() {
                return 'max-height: 225px;min-height: 225px;';
            },
        },
    };
</script>
