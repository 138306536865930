<template>
    <v-subheader
        :style="style"
        :class="classes"
        :inset="inset"
    >
        <slot><span v-html="text"/></slot>
        <v-icon :color="iconColor" class="ml-2" v-if="icon" v-html="icon" @click="$emit('subheader:icon-clicked')"/>
    </v-subheader>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
        },

        icon: {
            type:    String,
            default: null,
        },

        color: {
            type:    String,
            default: 'info',
        },

        iconColor: {
            type:    String,
            default: 'info',
        },

        bolder: {
            type:    Boolean,
            default: false,
        },

        bigger: {
            type:    Boolean,
            default: false,
        },

        inset: {
            type:    Boolean,
            default: false,
        },

        changeAccentForTheme: {
            type:    Boolean,
            default: true,
        },

        removePadding: {
            type:    Boolean,
            default: true,
        },
    },

    computed: {
        classes() {
            let classes = [
                this.color + '--text',
                'text--darken-2',
            ];

            if (this.bolder) {
                classes.push('font-semibold');
            }

            if (this.bigger) {
                classes.push('text-lg');
            }

            return classes;
        },

        style() {
            if (this.removePadding) {
                return 'padding-left: 0 !important;';
            }

            return '';
        },
    },
};
</script>
