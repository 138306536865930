<template>
    <div v-if="! useDropdown && (showDelete)">
        <v-btn
            color="error"
            x-small
            class="mr-3"
            v-if="showDelete"
            @click="$emit('recipes:delete')"
        >
            löschen
        </v-btn>
    </div>
    <div v-else-if="useDropdown && (showDelete)">
        <v-menu v-model="visible" offset-y>
            <template v-slot:activator="{ on }">
                <v-btn
                    color="info"
                    x-small
                    v-on="on"
                >
                    Aktionen

                    <v-icon x-small class="ml-1 transform transition duration-200" :class="{'rotate-180': visible}">$chevronDown</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item v-if="showDelete" @click="$emit('recipes:delete')">
                    <v-list-item-title>Löschen</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
export default {
    data: () => ({
        visible: false,
    }),

    props: {
        useDropdown: {
            type:    Boolean,
            default: false,
        },

        showDelete: {
            type:    Boolean,
            default: false,
        },
    },
};
</script>
