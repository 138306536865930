<template>
    <v-btn-toggle
        v-if="! useDropdown  && (asTabMenu || buttonGroupForNoTabs)"
        :value="activeTabValue"
        tile
        small
        dense
        :color="! asTabMenu &&  buttonGroupForNoTabs ? 'white' : 'primary'"
        group borderless mandatory
        class="!inline"
    >
        <v-btn v-if="! recipeDetailsAndIngredientsStepsSwapped" @click="$emit('recipe:update:tab','recipe')" small class="ml-0 mt-0" :class="{'btn-toggle-fake-tab': ! asTabMenu &&  buttonGroupForNoTabs}">
            Rezept
        </v-btn>
        <v-btn :disabled="! ingredientsLoaded && ! stepsLoaded" @click="$emit('recipe:update:tab','ingredientsSteps')" small class="ml-0 mt-0" :class="{'btn-toggle-fake-tab': ! asTabMenu &&  buttonGroupForNoTabs}" v-show="displayIngredientsStepsTogether">
            {{ ingredientsButtonTitleWithCount }} & {{ stepsButtonTitleWithCount }}
        </v-btn>
        <v-btn :disabled="! ingredientsLoaded" @click="$emit('recipe:update:tab','ingredients')" v-show="! displayIngredientsStepsTogether" small class="ml-0 mt-0" :class="{'btn-toggle-fake-tab': ! asTabMenu &&  buttonGroupForNoTabs}">
            {{ ingredientsButtonTitleWithCount }}
        </v-btn>
        <v-btn :disabled="! stepsLoaded" @click="$emit('recipe:update:tab','steps')" v-show="! displayIngredientsStepsTogether" small class="ml-0 mt-0" :class="{'btn-toggle-fake-tab': ! asTabMenu &&  buttonGroupForNoTabs}">
            {{ stepsButtonTitleWithCount }}
        </v-btn>
        <v-btn v-if="recipeDetailsAndIngredientsStepsSwapped" @click="$emit('recipe:update:tab','recipe')" small class="ml-0 mt-0" :class="{'btn-toggle-fake-tab': ! asTabMenu &&  buttonGroupForNoTabs}">
            Rezept
        </v-btn>
        <v-btn :disabled="! caloriesLoaded" @click="$emit('recipe:update:tab','calories')" small class="ml-0 mt-0" :class="{'btn-toggle-fake-tab': ! asTabMenu &&  buttonGroupForNoTabs}">
            {{ caloriesButtonTitleWithCount }}
        </v-btn>
        <v-btn :disabled="! additionalLoaded" @click="$emit('recipe:update:tab','additional')" small class="ml-0 mt-0" :class="{'btn-toggle-fake-tab': ! asTabMenu &&  buttonGroupForNoTabs}">
            Zusätzliches
        </v-btn>
    </v-btn-toggle>
    <div v-else-if="! useDropdown && !  asTabMenu">
        <v-btn v-if="! recipeDetailsAndIngredientsStepsSwapped" @click="$emit('recipe:update:tab','recipe')" color="info" elevation="0" outlined rounded x-small class="ml-0 mt-0">
            Rezept
        </v-btn>
        <v-btn :disabled="! ingredientsLoaded && ! stepsLoaded" @click="$emit('recipe:update:tab','ingredientsSteps')" v-show="displayIngredientsStepsTogether" color="info" elevation="0" outlined rounded x-small class="ml-2 mt-0">
            {{ ingredientsButtonTitleWithCount }} & {{ stepsButtonTitleWithCount }}
        </v-btn>
        <v-btn :disabled="! ingredientsLoaded" @click="$emit('recipe:update:tab','ingredients')" v-show="! displayIngredientsStepsTogether" color="info" elevation="0" outlined rounded x-small class="ml-2 mt-0">
            {{ ingredientsButtonTitleWithCount }}
        </v-btn>
        <v-btn :disabled="! stepsLoaded" @click="$emit('recipe:update:tab','steps')" v-show="! displayIngredientsStepsTogether" color="info" elevation="0" outlined rounded x-small class="ml-2 mt-0">
            {{ stepsButtonTitleWithCount }}
        </v-btn>
        <v-btn v-if="recipeDetailsAndIngredientsStepsSwapped" @click="$emit('recipe:update:tab','recipe')" color="info" elevation="0" outlined rounded x-small class="ml-0 mt-0">
            Rezept
        </v-btn>
        <v-btn :disabled="! caloriesLoaded" @click="$emit('recipe:update:tab','calories')" color="info" elevation="0" outlined rounded x-small class="ml-2 mt-0">
            {{ caloriesButtonTitleWithCount }}
        </v-btn>
        <v-btn :disabled="! additionalLoaded" @click="$emit('recipe:update:tab','additional')" color="info" elevation="0" outlined rounded x-small class="ml-2 mt-0">
            Zusätzliches
        </v-btn>
    </div>
    <div v-else>
        <v-menu v-model="visible" offset-y>
            <template v-slot:activator="{ on }">
                <v-btn
                    color="info"
                    x-small
                    v-on="on"
                >
                    <template v-if="asTabMenu">
                        {{ activeTabName }}
                    </template>
                    <template v-else>
                        Bereich
                    </template>
                    <v-icon x-small class="ml-1 transition transform duration-200" :class="{'rotate-180': visible}">$chevronDown</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item-group color="primary" :value="activeTab">
                    <v-list-item key=0 @click="$emit('recipe:update:tab','recipe')" v-if="! recipeDetailsAndIngredientsStepsSwapped">
                        <v-list-item-title>
                            Rezept
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item :disabled="! ingredientsLoaded && ! stepsLoaded" key=1 @click="$emit('recipe:update:tab','ingredientsSteps')" v-show="displayIngredientsStepsTogether">
                        <v-list-item-title>
                            {{ ingredientsButtonTitleWithCount }} & {{ stepsButtonTitleWithCount }}
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item :disabled="! ingredientsLoaded" key=2 @click="$emit('recipe:update:tab','ingredients')" v-show="! displayIngredientsStepsTogether">
                        <v-list-item-title>
                            {{ ingredientsButtonTitleWithCount }}
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item :disabled="! stepsLoaded" key=3 @click="$emit('recipe:update:tab','steps')" v-show="! displayIngredientsStepsTogether">
                        <v-list-item-title>
                            {{stepsButtonTitleWithCount}}
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item key=0 @click="$emit('recipe:update:tab','recipe')" v-if="recipeDetailsAndIngredientsStepsSwapped">
                        <v-list-item-title>
                            Rezept
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item :disabled="! caloriesLoaded" key=4 @click="$emit('recipe:update:tab','calories')">
                        <v-list-item-title>
                            {{ caloriesButtonTitleWithCount }}
                        </v-list-item-title>
                    </v-list-item>

                    <v-list-item :disabled="! additionalLoaded" key=5 @click="$emit('recipe:update:tab','additional')">
                        <v-list-item-title>
                            Zusätzliches
                        </v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>
    </div>
</template>

<style>
.btn-toggle-fake-tab {
    opacity: 0.8 !important;
}

.v-btn--active {
    color: rgba(0, 0, 0, 0.87) !important;
}

.dark .v-btn--active {
    color: inherit !important;
}

.btn-toggle-fake-tab:before {
    background-color: transparent !important;
    opacity: 1 !important;
}
</style>

<script>
export default {
    props: {
        activeTab: {
            type: String,
        },

        asTabMenu: {
            type:    Boolean,
            default: true,
        },

        buttonGroupForNoTabs: {
            type:    Boolean,
            default: true,
        },

        recipeDetailsAndIngredientsStepsSwapped: {
            type:    Boolean,
            default: false,
        },

        useDropdown: {
            type:    Boolean,
            default: false,
        },

        additionalLoaded: {
            type:    Boolean,
            default: true,
        },

        stepsLoaded: {
            type:    Boolean,
            default: true,
        },

        ingredientsLoaded: {
            type:    Boolean,
            default: true,
        },

        caloriesLoaded: {
            type:    Boolean,
            default: true,
        },

        caloriesCount: {
            type:    Number,
            default: null,
        },

        stepsCount: {
            type:    Number,
            default: null,
        },

        ingredientsCount: {
            type:    Number,
            default: null,
        },

        ingredientsAndStepsTogether: {
            type:    Boolean,
            default: false,
        },
    },

    data: () => ({
        visible: false,
    }),

    methods: {},

    watch: {},

    computed: {
        activeTabValue() {
            if (! this.asTabMenu) {
                return null;
            }

            switch (this.activeTab) {
                case 'ingredientsSteps':
                    return this.recipeDetailsAndIngredientsStepsSwapped ? 0 : 1;

                case 'ingredients':
                    return this.recipeDetailsAndIngredientsStepsSwapped ? 1: 2;

                case 'steps':
                    return this.recipeDetailsAndIngredientsStepsSwapped ? 2 : 3;

                case 'calories':
                    return 4

                case 'additional':
                    return 5;

                case 'recipes':
                default:
                    return this.recipeDetailsAndIngredientsStepsSwapped ? 3 : 0;
            }
        },

        ingredientsButtonTitleWithCount() {
            if (this.ingredientsCount && this.ingredientsCount > 0 && (! this.useDropdown || ! this.$breakpoint.isMobile)) {
                return this.ingredientsCount === 1 ? '1 Zutat' : this.ingredientsCount + ' Zutaten';
            }

            return 'Zutaten';
        },

        stepsButtonTitleWithCount() {
            if (this.stepsCount && this.stepsCount > 0 && (! this.useDropdown || ! this.$breakpoint.isMobile)) {
                return this.stepsCount === 1 ? '1 Schritt' : this.stepsCount + ' Schritte';
            }

            return 'Schritte';
        },

        caloriesButtonTitleWithCount() {
            if (this.caloriesCount && this.caloriesCount > 0) {
                return this.caloriesCount + ' Kalorien';
            }

            return 'Kalorien';
        },

        displayIngredientsStepsTogether() {
            return this.ingredientsAndStepsTogether;
        },

        activeTabName() {
            if (! this.asTabMenu) {
                return null;
            }

            switch (this.activeTab) {
                case 'ingredients':
                    return this.ingredientsButtonTitleWithCount;

                case 'ingredientsSteps':
                    return this.ingredientsButtonTitleWithCount + ' & ' + this.stepsButtonTitleWithCount;

                case 'steps':
                    return this.stepsButtonTitleWithCount;

                case 'calories':
                    return this.caloriesButtonTitleWithCount;

                case 'additional':
                    return 'Zusätzliches';

                case 'recipes':
                default:
                    return 'Rezept';
            }
        },
    },
};
</script>
