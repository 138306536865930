<template>
    <sub-header v-if="title || ((levelLabel || timeLabel) && showInformations)">
        <template v-if="title">{{prefix}}{{ title }}</template>
        <step-sub-informations secondary :sub-data="subData" v-if="showInformations"/>
    </sub-header>
</template>

<script>
import SubHeader           from '@symfia/library/Views/Helper/SubHeader';
import StepSubInformations from './StepSubInformations';

export default {
    components: {
        SubHeader,
        StepSubInformations,
    },

    props: {
        subData: {
            type:     Object,
            required: true,
        },

        showInformations: {
            type:    Boolean,
            default: false,
        },

        prefix: {
            type: String,
            default: '',
        }
    },

    data: () => ({}),

    watch: {},

    mounted() {

    },

    beforeDestroy() {

    },

    methods: {},

    computed: {

        levelLabel() {
            if (! this.subData.level || this.subData.level === '') {
                return null;
            }

            return this.subData.level_text;
        },

        timeLabel() {
            if (! this.subData.time || this.subData.time <= 0 || this.subData.time === '') {
                return null;
            }

            return this.subData.time_human;
        },

        title() {
            return this.subData.title && this.subData.title !== '' ? this.subData.title : null;
        },
    },
};
</script>
