<template>
    <div class="position-relative">
        <v-boilerplate-loading :elevation="0" v-if="! isLoaded" type="list-item-two-line@1" class="w-full"/>
        <template v-else>
            <sub-header-with-divider text="Zutaten" v-if="showTitleIfNecessary && (! isTogether || ! isSideBySide)"/>
            <recipe-portions-field
                :original-portions="recipe.portions"
                :portions="portionsForCalculation"
                more-informations-label
                v-on:recipe:portions:updated="updatedPortions"
                use-placeholder
                :class="{
                    'mb-3': hasModifiedPortions
                }"
            />

            <div
                v-for="(group, index) in recipe.ingredient_groups"
                :class="{
                    'bg-gray-400': isTogether && isSideBySide,
                    'dark:bg-gray-900': isTogether && isSideBySide,
                    'dark:bg-material-blueGrey-darken-4': isTogether && isSideBySide,
                    'rounded-lg': isTogether && isSideBySide,
                    'px-4': isTogether && isSideBySide,
                    'py-4': isTogether && isSideBySide,
                    'ring-1': isTogether && isSideBySide,
                    'dark:ring-emerald-900': isTogether && isSideBySide,
                    'dark:ring-opacity-85': isTogether && isSideBySide,
                    'shadow-md': isTogether && isSideBySide,
                    'dark:shadow-md-white': isTogether && isSideBySide,
                    'mt-5': index > 0
                }"
            >
                <sub-header-with-divider
                    :class="{
                        '-mt-3': isTogether && isSideBySide,
                        'ml-0': isTogether && isSideBySide
                    }"
                    :bigger="isTogether && isSideBySide"
                    :text="group.title"
                    v-if="group.title && group.title !== ''"
                    :show-divider="false"
                />

                <ul class="list-disc text-sm mb-0" :class="{'pl-2': ! isTogether || ! isSideBySide, 'ml-2': ! isTogether || ! isSideBySide}">
                    <li
                        v-for="(ingredient, ingredientIndex) in group.ingredients"
                        :key="'recipe-ingredient-'+recipe.id+group.id+ingredient.id"
                    >
                        <ingredient
                            :class="{'mt-2': ingredientIndex}"
                            :ingredient="ingredient"
                            :portions-for-calculation="portionsForCalculation"
                            :recipe-portions="recipe.portions"
                        />
                    </li>
                </ul>
            </div>
        </template>
    </div>
</template>

<script>
import SubHeaderWithDivider from '@symfia/library/Views/Helper/SubHeaderWithDivider';
import Divider              from '@symfia/library/Views/Helper/Divider';
import Ingredient          from './Ingredient';
import RecipePortionsField from './RecipePortionsField';

export default {
    components: {
        SubHeaderWithDivider,
        Divider,
        Ingredient,
        RecipePortionsField,
    },

    props: {
        recipe: {
            type:     Object,
            required: true,
        },

        isTogether: {
            type:    Boolean,
            default: false,
        },

        isSideBySide: {
            type:    Boolean,
            default: false,
        },

        portionsForCalculation: {
            type:    [Number, String],
            default: null,
        },

        showTitleIfNecessary: {
            type:    Boolean,
            default: false,
        },
    },

    data: () => ({}),

    watch: {},

    mounted() {

    },

    beforeDestroy() {

    },

    methods: {
        updatedPortions(newPortions) {
            this.$emit('recipe:portions:updated', newPortions ? parseInt(newPortions) : null)
        }
    },

    computed: {
        hasModifiedPortions() {
            if (! this.recipe.portions || this.recipe.portions <= 0) {
                return false;
            }

            let forCalc = this.portionsForCalculation ? parseInt(this.portionsForCalculation) : null;

            if (
                this.recipe.portions
                && forCalc
                && this.recipe.portions > 0
                && forCalc > 0
                && this.recipe.portions !== forCalc
            ) {
                return true;
            }

            return false;
        },

        isLoaded() {
            if (
                this.recipe === null
                || typeof this.recipe !== 'object'
                || typeof this.recipe.ingredient_groups === 'undefined'
            ) {
                return false;
            }

            if (typeof this.recipe.ingredient_groups_loaded === 'undefined') {
                return true;
            }

            return this.recipe.ingredient_groups_loaded === true;
        },
    },
};
</script>
