<template>
    <div>
        <div>
            {{ calculatedUnit }} {{ ingredient.unit }} {{ title }}
        </div>
        <div v-if="additional" class="text-xs dark:text-gray-400 pt-0.5 font-thin">
            {{additional}}
        </div>
    </div>
</template>

<script>

export default {
    components: {},

    props: {
        ingredient: {
            type:     Object,
            required: true,
        },

        recipePortions: {
            type:    Number,
            default: null,
        },

        portionsForCalculation: {
            type:    [Number, String],
            default: null,
        },
    },

    data: () => ({}),

    watch: {},

    mounted() {

    },

    beforeDestroy() {

    },

    methods: {},

    computed: {
        calculatedUnit() {
            if (! this.ingredient.amount || this.ingredient.amount <= 0) {
                return null;
            }

            let forCalc    = this.portionsForCalculation ? parseInt(this.portionsForCalculation) : null;
            let amount     = this.ingredient.amount;
            let percentage = 1;

            if (
                this.recipePortions
                && forCalc
                && this.recipePortions > 0
                && forCalc > 0
                && this.recipePortions !== forCalc
            ) {
                percentage = ((100 / this.recipePortions) * forCalc) / 100;

                if (percentage <= 0) {
                    percentage = 1;
                }
            }

            let calculated = amount * percentage;

            return +calculated.toFixed(2);
        },

        title() {
            let title = this.ingredient.title;

            if (! title || title === '') {
                return null;
            }

            return this.ingredient.title;

            if (this.ingredient.additional && this.ingredient.additional !== '') {
                title += ', ' + this.ingredient.additional;
            }

            return title;
        },

        additional() {
            let title = this.ingredient.additional;

            if (! title || title === '') {
                return null;
            }

            return this.ingredient.additional;
        },
    },
};
</script>

