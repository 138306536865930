<template>
    <div class="position-relative" :class="{'fill-height': onDashboard}">
        <create-edit-view
            v-if="useCreateView || useEditView"
            :wanted-recipe="editRecipe"
            :wanted-recipe-id="editRecipeId"
            v-on:recipe:create-edit:back-edit="hideThroughEdit($event)"
            v-on:recipe:create-edit:back="hideThroughCreate()"
            v-on:recipe:create-edit:recipe-created="addOrUpdateRecipe($event)"
            v-on:recipe:create-edit:recipe-updated="updateRecipe($event)"
        />
        <detail-view
            v-else-if="showDetails"
            :wanted-recipe="selectedRecipe"
            :wanted-recipe-id="selectedRecipeId"
            :display-comments="displayComments"
            v-on:recipe:details:back="hideThroughDetails"
            v-on:recipe:details:edit="activateRecipeEdit($event)"
            v-on:recipe:details:deleted="removeRecipe($event)"
        />
        <template v-else>
            <div class="flex flex-row">
                <v-btn class="mr-2" color="success" v-if="! loading && showCreateButton" small v-on:click="handleCreate(true)">Rezept erstellen</v-btn>
                <import-handler :show-button="! loading"/>
            </div>

            <div v-if="loading && ! reloadingRecipes" :class="{'h-90%': onDashboard}">
                <content-loader v-if="! onDashboard"/>
                <div class="h-90%" v-else>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                </div>
            </div>
            <div v-else>
                <sticky-with-intersection v-if="recipesExist" :background-sticky-color-classes="stickyColorClasses" :disabled="! useSticky">
                    <v-row class="mb-4">
                        <v-col cols="12" sm="4" md="3" lg="3" :class="{'self-center': ! $breakpoint.xs, 'text-center': ! $breakpoint.xs}">
                            {{ countText }}
                        </v-col>
                        <v-col cols="12" sm="8" md="5" offset-md="1" lg="6" offset-lg="0">
                            <v-text-field
                                v-model="query"
                                hint="Wir benutzen eine Prefix-basierte Volltextsuche - diese durchsucht mehrere Felder auf einmal. Da es Prefix-Based ist, kannst du nur dem Anfang eines Wortes suchen (Kartoffel wird gefunden mit Kart, aber nicht mit fel)."
                                label="Prefix-Volltext-Suche"
                                clearable
                            />
                        </v-col>
                    </v-row>
                </sticky-with-intersection>

                <template v-if="reloadingRecipes ||  loadingPage">
                    <div class="text-center" v-if="loadingPage">
                        <v-icon>$spinner</v-icon>
                        - Deine Seite wird geladen
                    </div>
                    <div class="text-center" v-else>
                        <v-icon>$spinner</v-icon>
                        - Deine Rezepte werden aktualisiert
                    </div>
                </template>
                <template v-else-if="recipesWithoutPossibleDuplicates.length > 0 || loadingPage">
                    <v-alert v-if="dynamicallyModified" dismissible color="info">
                        Bei deiner Liste wurden Rezepte dynamisch verändert. Dadurch kann es sein, dass der Seitenwechsel anders reagiert.
                    </v-alert>

                    <v-pagination
                        v-if="recipes.last_page && recipes.last_page > 1"
                        v-model="page"
                        :length="recipes.last_page"
                        class="mb-2"
                    ></v-pagination>

                    <div class="text-center" v-if="loadingPage">
                        <v-icon>$spinner</v-icon>
                        - deine Seite wird geladen
                    </div>
                    <card-list-row
                        v-else
                        v-on:recipes:select="selectRecipe"
                        v-on:recipe:delete="removeRecipe"
                        :is-own-view="isListForMyRecipes"
                        :recipes="recipesWithoutPossibleDuplicates"
                        :on-dashboard="onDashboard"
                        :dashboard-full="dashboardFull"
                        :dashboard-used-cols="dashboardUsedCols"
                    />

                    <v-pagination
                        class="mt-2"
                        v-if="recipes.last_page && recipes.last_page > 1"
                        v-model="page"
                        :length="recipes.last_page"
                    ></v-pagination>
                </template>
                <div v-else>
                    Für diese Anzeige existieren keine Rezepte
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import {
    flagName as RecipesListSearchAreaStickyName,
    defaultActive as RecipesListSearchAreaStickyDefault,
} from './../Config/Flags/RecipesListSearchAreaSticky';

import {
    flagName as RecipeCardsListMoreColumnsName,
    defaultActive as RecipeCardsListMoreColumnsDefault,
} from '../Config/Flags/RecipesCardsListMoreColumns.js';

import {flagName as RecipesDetailsAsModalName, defaultActive as RecipesDetailsAsModalDefault} from './../Config/Flags/RecipesDetailsAsModal';
import {debounce}                                                                             from 'lodash';
import StickyWithIntersection
                                                                                              from '@symfia/library/Views/Helper/StickyWithIntersection';
import CardListRow                                                                            from './List/CardListRow';
import ContentLoader                                                                          from './Shared/ContentLoader';
import ImportHandler                                                                          from './Import/ImportHandler';
import DetailView                                                                             from './Details/DetailView';
import CreateEditView                                                                         from './CreateEdit/CreateEditView';

export default {
    components: {
        StickyWithIntersection,
        CardListRow,
        ContentLoader,
        ImportHandler,
        DetailView,
        CreateEditView,
    },

    props: {
        type: {
            type:    String,
            default: 'mine',
        },

        recipeId: {
            type:    [Number, String],
            default: null,
        },

        recipeIdEdit: {
            type:    [Number, String],
            default: null,
        },

        startWithEdit: {
            type:    Boolean,
            default: false,
        },

        onDashboard: {
            type:    Boolean,
            default: false,
        },

        shouldChangeRoute: {
            type:    Boolean,
            default: false,
        },

        dashboardFull: {
            type:    Boolean,
            default: false,
        },

        dashboardUsedCols: {
            type:    Number,
            default: 6,
        },

        displayComments: {
            type:    Boolean,
            default: true,
        },
    },

    data: () => ({
        listInitialized:              false,
        withoutLoadingResetOnFinally: false,
        previousRoute:                null,
        previousRouteObject:          null,
        selectedRecipeId:             null,
        selectedRecipe:               null,
        editRecipeId:                 null,
        editRecipe:                   null,
        requestsFinallyReached:       true,
        dynamicallyModified:          false,
        useCreateView:                false,
        useEditView:                  false,
        showDetails:                  false,
        debounceSearch:               null,
        recipes:                      {
            'current_page':   0,
            'data':           [],
            'first_page_url': null,
            'from':           0,
            'last_page':      0,
            'last_page_url':  null,
            'links':          [],
            'next_page_url':  null,
            'path':           null,
            'per_page':       0,
            'prev_page_url':  null,
            'to':             0,
            'total':          0,
        },
        query:                        '',
        recipesImportProvider:        [],
        page:                         1,
        loading:                      false,
        showCreateButton:             true,
        reloadingRecipes:             false,
        loadingPage:                  false,
        axiosToken:                   null,
        hasSearchWithQuery:           false,
        recipesExist:                 false,
    }),

    watch: {
        $route(to, from) {
            const listRoutes = [
                'Cookbook.Recipes.mine',
                'Cookbook.Recipes.friends',
                'Cookbook.Recipes.public',
                'Cookbook.Recipes.all',
            ];

            if (
                this.previousRouteObject
                && this.previousRouteObject.name
                && this.previousRouteObject.name === to.name
                && from.name !== to.name
            ) {
                if (
                    from
                    && (
                        from.name === 'Cookbook.Recipes.detail'
                        || from.name === 'Cookbook.Recipes.create'
                        || from.name === 'Cookbook.Recipes.edit'
                    )
                ) {
                    this.redirectToListView();
                }

                this.previousRouteObject = null;
                return;
            }

            if (to.name !== from.name && listRoutes.includes(to.name)) {
                if (
                    from
                    && (
                        from.name === 'Cookbook.Recipes.detail'
                        || from.name === 'Cookbook.Recipes.create'
                        || from.name === 'Cookbook.Recipes.edit'
                    )
                ) {
                    this.redirectToListView();
                }

                this.cancelRequests();
                this.withoutLoadingResetOnFinally = true;

                this.$nextTick(() => {
                    this.handleRoute();
                });

                return;
            }

            if (listRoutes.includes(to.name)) {
                if (
                    from
                    && (
                        from.name === 'Cookbook.Recipes.detail'
                        || from.name === 'Cookbook.Recipes.create'
                        || from.name === 'Cookbook.Recipes.edit'
                    )
                ) {
                    this.redirectToListView();
                }

                let queryPageParam   = 1;
                let querySearchParam = '';

                try {
                    queryPageParam = typeof to.query.page !== 'undefined' ? parseInt(to.query.page) : 1;
                } catch (e) {
                    queryPageParam = 1;
                }

                try {
                    querySearchParam = typeof to.query.query !== 'undefined' ? to.query.query : '';
                } catch (e) {
                    querySearchParam = '';
                }

                let isEmptySearch       = ! querySearchParam || querySearchParam === '' || querySearchParam === ' ';
                let actualSearchIsEmpty = ! this.query || this.query === '' || this.query === ' ';

                if (! queryPageParam || queryPageParam <= 0) {
                    queryPageParam = 1;
                }

                if (
                    (isEmptySearch && ! actualSearchIsEmpty)
                    || (! isEmptySearch && actualSearchIsEmpty)
                ) {
                    this.query = '';
                } else if (this.query !== querySearchParam) {
                    this.query = querySearchParam;
                }

                if (this.page !== queryPageParam) {
                    this.page = queryPageParam;
                }
            }

            if (to.name === 'Cookbook.Recipes.detail' && to.params.id && ! this.selectedRecipeId && ! this.selectedRecipe) {
                let recipeId    = to.params.id;
                let recipe      = null;
                let recipeIndex = this.searchRecipeIndex(recipeId);

                if (recipeIndex !== -1) {
                    recipe   = this.recipes.data[recipeIndex];
                    recipeId = null;
                }

                this.selectRecipe(recipe, recipeId, false);
            }

            if (to.name === 'Cookbook.Recipes.edit' && to.params.id && ! this.editRecipeId && ! this.editRecipe) {
                let recipeId    = to.params.id;
                let recipe      = null;
                let recipeIndex = this.searchRecipeIndex(recipeId);

                if (recipeIndex !== -1) {
                    recipe   = this.recipes.data[recipeIndex];
                    recipeId = null;
                }

                this.handleEdit(recipe, recipeId, false);
            }

            if (to.name === 'Cookbook.Recipes.create' && from.name !== 'Cookbook.Recipes.create' && this.useCreateView !== true) {
                this.handleCreate(false);
            }
        },

        query() {
            if (this.loading || ! this.listInitialized || ! this.debounceSearch) {
                return;
            }

            this.debounceSearch();
        },

        page() {
            if (this.loading || ! this.listInitialized) {
                return;
            }

            this.cancelRequests(false);

            this.$nextTick(() => {
                if (this.requestsFinallyReached === false) {
                    window.setTimeout(() => {
                        this.loadingPage = true;
                    }, 300);
                }

                this.loadRecipes(false, true);
            });
        },
    },

    beforeMount() {
        const isDetails = this.recipeId && this.recipeId !== '' && parseInt(this.recipeId) > 0;
        const isEdit    = this.recipeIdEdit && this.recipeIdEdit !== '' && parseInt(this.recipeIdEdit) > 0;

        if (! isDetails && ! isEdit && ! this.startWithEdit) {
            this.initList();
        } else if (isDetails) {
            this.selectedRecipeId = parseInt(this.recipeId);
            this.showDetails      = true;
        } else if (isEdit) {
            this.editRecipeId = parseInt(this.recipeIdEdit);
            this.useEditView  = true;
        } else if (this.startWithEdit) {
            this.useCreateView = true;
        }
    },

    beforeDestroy() {
        this.cancelRequests();

        if (this.isListForAllRecipes || this.isListForMyRecipes) {
            this.$echo.stopListeningPrivate(
                this.$store.getters['User/broadcastUserChannel'],
                '.Recipe',
                this.addOrUpdateRecipe,
                'cookbook-recipes-list-view-my-' + this.type + (this.onDashboard ? '-dasboard' : ''),
            );

            this.$echo.stopListeningPrivate(
                this.$store.getters['User/broadcastUserChannel'],
                '.RecipeDeleted',
                this.removeRecipe,
                'cookbook-recipes-list-view-my-deleted-' + this.type + (this.onDashboard ? '-dasboard' : ''),
            );
        }

        if (this.isListForAllRecipes || this.isListForFriendRecipes) {
            this.$echo.stopListeningPrivate(
                this.$store.getters['User/broadcastUserChannel'],
                '.RecipeFriend',
                this.addOrUpdateRecipe,
                'cookbook-recipes-list-view-friend-' + this.type + (this.onDashboard ? '-dasboard' : ''),
            );

            this.$echo.stopListeningPrivate(
                this.$store.getters['User/broadcastUserChannel'],
                '.RecipeDeletedFriend',
                this.removeRecipe,
                'cookbook-recipes-list-view-friend-deleted-' + this.type + (this.onDashboard ? '-dasboard' : ''),
            );
        }

        if (this.isListForAllRecipes || this.isListForPublicRecipes) {
            this.$echo.stopListening(
                'Plugins.Cookbook.Recipes.Models.Recipe',
                '.RecipePublic',
                this.addOrUpdateRecipe,
                'cookbook-recipes-list-view-public-' + this.type + (this.onDashboard ? '-dasboard' : ''),
            );

            this.$echo.stopListening(
                'Plugins.Cookbook.Recipes.Models.Recipe',
                '.RecipeDeletedPublic',
                this.removeRecipe,
                'cookbook-recipes-list-view-friend-public-' + this.type + (this.onDashboard ? '-dasboard' : ''),
            );
        }
    },

    methods: {
        handleRoute() {
            let page       = this.$route.query.page || 1;
            let parsedPage = parseInt(page);

            this.page  = parsedPage || 1;
            this.query = this.$route.query.query || '';
            this.loadRecipes(false, false);
        },

        initList() {
            if (this.listInitialized) {
                return;
            }

            this.handleRoute();

            this.debounceSearch = debounce(() => {
                if (this.loading) {
                    return;
                }

                if (this.loadingPage || this.reloadingRecipes) {
                    this.cancelRequests(false);
                }

                this.page = 1;

                this.$nextTick(() => {
                    if (this.requestsFinallyReached === false) {
                        window.setTimeout(() => {
                            this.reloadingRecipes = true;
                        }, 300);
                    }

                    if (this.query === '') {
                        this.loadRecipes(false, false, true);
                    } else {
                        this.loadRecipes(true, false);
                    }
                });
            }, 350);

            if (this.isListForAllRecipes || this.isListForMyRecipes) {
                this.$echo.listenPrivate(
                    this.$store.getters['User/broadcastUserChannel'],
                    '.Recipe',
                    this.addOrUpdateRecipe,
                    'cookbook-recipes-list-view-my-' + this.type + (this.onDashboard ? '-dasboard' : ''),
                );

                this.$echo.listenPrivate(
                    this.$store.getters['User/broadcastUserChannel'],
                    '.RecipeDeleted',
                    this.removeRecipe,
                    'cookbook-recipes-list-view-my-deleted-' + this.type + (this.onDashboard ? '-dasboard' : ''),
                );
            }

            if (this.isListForAllRecipes || this.isListForFriendRecipes) {
                this.$echo.listenPrivate(
                    this.$store.getters['User/broadcastUserChannel'],
                    '.RecipeFriend',
                    this.addOrUpdateRecipe,
                    'cookbook-recipes-list-view-friend-' + this.type + (this.onDashboard ? '-dasboard' : ''),
                );

                this.$echo.listenPrivate(
                    this.$store.getters['User/broadcastUserChannel'],
                    '.RecipeDeletedFriend',
                    this.removeRecipe,
                    'cookbook-recipes-list-view-friend-deleted-' + this.type + (this.onDashboard ? '-dasboard' : ''),
                );
            }

            if (this.isListForAllRecipes || this.isListForPublicRecipes) {
                this.$echo.listen(
                    'Plugins.Cookbook.Recipes.Models.Recipe',
                    '.RecipePublic',
                    this.addOrUpdateRecipe,
                    'cookbook-recipes-list-view-public-' + this.type + (this.onDashboard ? '-dasboard' : ''),
                );

                this.$echo.listen(
                    'Plugins.Cookbook.Recipes.Models.Recipe',
                    '.RecipeDeletedPublic',
                    this.removeRecipe,
                    'cookbook-recipes-list-view-friend-public-' + this.type + (this.onDashboard ? '-dasboard' : ''),
                );
            }

            this.listInitialized = true;
        },

        resetRecipeObject() {
            this.recipes = {
                'current_page':   0,
                'data':           [],
                'first_page_url': null,
                'from':           0,
                'last_page':      0,
                'last_page_url':  null,
                'links':          [],
                'next_page_url':  null,
                'path':           null,
                'per_page':       0,
                'prev_page_url':  null,
                'to':             0,
                'total':          0,
            };
        },

        updateRecipe(recipe) {
            this.$logger.debug(
                'Rezept: Update after edit',
                recipe,
                'Recipe-List',
            );

            this.selectedRecipe   = recipe;
            this.selectedRecipeId = recipe.id;
            this.editRecipe       = recipe;
            this.editRecipeId     = recipe.id;

            const index = this.searchRecipeIndex(recipe.id);

            if (index !== -1) {
                this.updateRecipeEntry(index, recipe);
            }
        },

        addOrUpdateRecipe(recipe) {
            this.$logger.debug(
                'Dynamisches Rezept: Add / Update',
                recipe,
                'Recipe-List',
            );

            const index = this.searchRecipeIndex(recipe.id);

            if (index === -1) {
                this.addRecipeEntry(recipe);
            } else {
                this.updateRecipeEntry(index, recipe);
            }
        },

        addRecipeEntry(recipe) {
            this.$logger.debug(
                'Dynamisches Rezept: Kein sichtbares Rezept gefunden',
                null,
                'Recipe-List',
            );

            if (typeof recipe.recipeType === 'undefined' || recipe.recipeType !== 'update') {
                this.recipes.data.unshift(recipe);

                try {
                    this.recipes.total = parseInt(this.recipes.total) + 1;
                    this.recipes.to    = parseInt(this.recipes.to || '0') + 1;
                    if (parseInt(this.recipes.from) <= 0) {
                        this.recipes.from = 1;
                    }
                } catch (e) { }

                this.recipesExist        = true;
                this.dynamicallyModified = true;
            }
        },

        updateRecipeEntry(index, recipe) {
            this.$logger.debug(
                'Dynamisches Rezept: sichtbares Rezept gefunden',
                index,
                'Recipe-List',
            );

            const combined = {
                ...this.recipes.data[index],
                ...recipe,
            };

            this.$set(this.recipes.data, index, combined);
        },

        removeRecipe(recipe) {
            this.$logger.debug(
                'Dynamisches Rezept: Rezept entfernen',
                recipe,
                'Recipe-List',
            );

            const index = this.searchRecipeIndex(recipe.id);

            if (index !== -1) {
                this.$logger.debug(
                    'Dynamisches Rezept: Index gefunden',
                    index,
                    'Recipe-List',
                );

                this.$delete(this.recipes.data, index);
                try {
                    this.recipes.total = parseInt(this.recipes.total) - 1;

                    if (this.recipes.total < 0) {
                        this.recipes.total = 0;
                    }

                    this.recipes.to = parseInt(this.recipes.to || '0') - 1;

                    if (this.recipes.to < 0) {
                        this.recipes.to = 0;
                    }
                } catch (e) { }

                if (this.recipesWithoutPossibleDuplicates.length <= 0) {
                    this.recipesExist = false;
                }
                this.dynamicallyModified = true;
            }
        },

        cancelRequests(cancelLoadings) {
            if (this.axiosToken) {
                this.axiosToken.cancel();
                this.axiosToken = null;

                if (cancelLoadings !== false) {
                    this.loadingPage      = false;
                    this.loading          = false;
                    this.reloadingRecipes = false;
                }
            }
        },

        handleCreate(pushRoutes = true) {
            this.previousRoute       = this.$route.fullPath;
            this.previousRouteObject = this.$route;
            this.useCreateView       = true;

            this.$tracker.trackEvent(
                'Rezepte',
                'Rezept erstellen',
            );

            try {
                if (pushRoutes === true) {
                    this.$router.push({
                        name: this.frontendCreateRouteName,
                    });
                }
            } catch (e) { }
        },

        handleEdit(recipe, recipeId, pushRoutes = true) {
            this.selectedRecipeId = recipeId ? recipeId : null;
            this.editRecipeId     = recipeId ? recipeId : null;
            this.selectedRecipe   = recipe ? recipe : null;
            this.editRecipe       = recipe ? recipe : null;
            this.useEditView      = true;
            this.showDetails      = true;

            let actualId = recipe && typeof recipe.id !== 'undefined' ? recipe.id : recipeId;

            this.$tracker.trackEvent(
                'Rezepte',
                'Rezept bearbeiten' + (this.onDashboard === true ? ' (Dashboard) ' : ''),
                recipe && typeof recipe.name !== 'undefined' ? recipe.name : '- ID Call -',
                actualId,
            );

            try {
                if (pushRoutes === true) {
                    this.$router.push({
                        name: this.frontendEditRouteName, params: {id: actualId},
                    });
                }
            } catch (e) { }
        },

        selectRecipe(recipe, recipeId, pushRoutes = true) {
            this.selectedRecipeId    = recipeId ? recipeId : null;
            this.previousRoute       = this.$route.fullPath;
            this.previousRouteObject = this.$route;
            this.selectedRecipe      = recipe ? recipe : null;
            this.showDetails         = true;

            let actualId = recipe && typeof recipe.id !== 'undefined' ? recipe.id : recipeId;

            this.$tracker.trackEvent(
                'Rezepte',
                'Rezept Details anzeigen' + (this.onDashboard === true ? ' (Dashboard) ' : ''),
                recipe && typeof recipe.name !== 'undefined' ? recipe.name : '- ID Call -',
                actualId,
            );

            try {
                if (this.$route.name !== this.frontendDetailRouteName) {
                    this.$router.push({
                        name: this.frontendDetailRouteName, params: {id: actualId},
                    });
                }
            } catch (e) { }

            try {
                /*  if (pushRoutes === true) {
                      this.$router.push({
                          name: this.frontendDetailRouteName, params: {id: actualId},
                      });
                  }*/
            } catch (e) { }
        },

        searchRecipeIndex(recipeId) {
            return this.recipes.data.findIndex((existingRecipe) => {
                return existingRecipe && existingRecipe.id && existingRecipe.id === recipeId;
            });
        },

        hideThroughEdit(recipe) {
            let editRecipe     = typeof recipe === 'object' && typeof recipe.id !== 'undefined' ? recipe : null;
            this.useCreateView = false;
            this.useEditView   = false;

            let actualRecipe   = editRecipe || this.editRecipe || this.selectedRecipe;
            let actualRecipeId = this.editRecipeId || this.selectedRecipeId;

            if (! actualRecipeId) {
                actualRecipeId = actualRecipe ? this.editRecipe.id : null;
            }

            if (actualRecipeId) {
                this.showDetails      = true;
                this.selectedRecipe   = actualRecipe;
                this.selectedRecipeId = actualRecipeId;
                this.editRecipe       = null;
                this.editRecipeId     = null;

                this.$tracker.trackEvent(
                    'Rezepte',
                    'Rezept editieren beenden' + (this.onDashboard === true ? ' (Dashboard) ' : ''),
                    actualRecipe && typeof actualRecipe.name !== 'undefined' ? actualRecipe.name : '- Unknown, ID -',
                    actualRecipeId,
                );

                try {
                    try {
                        if (this.$route.name !== this.frontendDetailRouteName) {
                            this.$router.push({
                                name: this.frontendDetailRouteName, params: {id: actualRecipeId},
                            });
                        }
                    } catch (e) { }
                } catch (e) { }

                return;
            }

            this.hideThroughCreate(true);
        },

        hideThroughCreate(isFromEdit = false) {
            this.useCreateView    = false;
            this.useEditView      = false;
            this.showDetails      = false;
            this.selectedRecipe   = null;
            this.selectedRecipeId = null;
            this.showDetails      = false;
            this.editRecipe       = null;
            this.editRecipeId     = null;

            this.$tracker.trackEvent(
                'Rezepte',
                'Rezept erstellen beenden' + (isFromEdit === true ? ' - von Edit ' : '') + (this.onDashboard === true ? ' (Dashboard) ' : ''),
            );

            try {
                let previous = this.previousRoute;

                if (previous.indexOf('recipe/') === -1 && previous.indexOf('recipes/') === -1) {
                    previous = null;
                }

                if (previous && this.$route.fullPath !== previous) {
                    this.$router.push(previous);
                } else if (this.$route.name !== this.routeNameFrontend) {
                    this.$router.push({
                        name: this.route,
                    });
                }
            } catch (e) { }

            this.$nextTick(() => {
                this.previousRoute       = null;
                this.previousRouteObject = null;
            });

            this.initList();
        },

        hideThroughDetails() {
            this.useCreateView    = false;
            this.useEditView      = false;
            this.showDetails      = false;
            this.selectedRecipe   = null;
            this.selectedRecipeId = null;
            this.editRecipe       = null;
            this.editRecipeId     = null;

            this.$tracker.trackEvent('Rezepte', 'Rezept Details ausblenden' + (this.onDashboard === true ? ' (Dashboard) ' : ''));

            try {
                let previous = this.previousRoute;

                if (previous.indexOf('recipe/') === -1 && previous.indexOf('recipes/') === -1) {
                    previous = null;
                }

                if (previous && this.$route.fullPath !== previous) {
                    this.$router.push(previous);
                } else if (this.$route.name !== this.routeNameFrontend) {
                    this.$router.push({
                        name: this.routeNameFrontend,
                    });
                }
            } catch (e) { }

            this.$nextTick(() => {
                this.previousRoute       = null;
                this.previousRouteObject = null;
            });

            this.initList();
        },

        redirectToListView() {
            const wasCreate  = this.useCreateView;
            const wasEdit    = this.useEditView;
            const wasDetails = this.showDetails;

            this.useCreateView    = false;
            this.useEditView      = false;
            this.showDetails      = false;
            this.selectedRecipe   = null;
            this.selectedRecipeId = null;
            this.editRecipe       = null;
            this.editRecipeId     = null;

            if (wasCreate) {
                this.$tracker.trackEvent('Rezepte', 'Neues Rezept abbrechen' + (this.onDashboard === true ? ' (Dashboard) ' : ''));
            }

            if (wasDetails) {
                this.$tracker.trackEvent('Rezepte', 'Rezept Details ausblenden' + (this.onDashboard === true ? ' (Dashboard) ' : ''));
            }

            if (wasEdit) {
                this.$tracker.trackEvent('Rezepte', 'Rezept Edit ausblenden' + (this.onDashboard === true ? ' (Dashboard) ' : ''));
            }

            try {
                let previous = this.previousRoute;

                if (previous.indexOf('recipe/') === -1 && previous.indexOf('recipes/') === -1) {
                    previous = null;
                }

                if (previous && this.$route.fullPath !== previous) {
                    this.$router.push(previous);
                } else if (this.$route.name !== this.routeNameFrontend) {
                    this.$router.push({
                        name: this.routeNameFrontend,
                    });
                }
            } catch (e) { }

            this.$nextTick(() => {
                this.previousRoute       = null;
                this.previousRouteObject = null;
            });

            this.initList();
        },

        activateRecipeEdit(givenRecipe) {
            const recipe          = givenRecipe !== null && typeof givenRecipe.id !== 'undefined' ? givenRecipe : null;
            this.useEditView      = true;
            this.editRecipe       = recipe ? recipe : this.selectedRecipe;
            this.editRecipeId     = recipe ? recipe.id : this.selectedRecipeId;
            this.selectedRecipe   = this.editRecipe;
            this.selectedRecipeId = this.editRecipeId;

            if (! this.editRecipeId) {
                return;
            }

            this.$tracker.trackEvent(
                'Rezepte',
                'Rezept bearbeiten über Details' + (this.onDashboard === true ? ' (Dashboard) ' : ''),
                this.editRecipe && typeof this.editRecipe.name !== 'undefined' ? this.editRecipe.name : '- Unknown, ID -',
                this.editRecipeId,
            );

            try {
                this.$router.push({
                    name: this.frontendEditRouteName, params: {id: this.editRecipeId},
                });
            } catch (e) { }
        },

        loadRecipes(isSearch, isPageCall, comesFromSearch) {
            if (
                (! isSearch && ! isPageCall && ! comesFromSearch)
                && (this.loading || this.reloadingRecipes || this.loadingPage)
            ) {
                return;
            }

            if (isSearch) {
                this.reloadingRecipes = true;
            } else if (isPageCall) {
                this.loadingPage = true;
            } else {
                this.loading = true;
            }

            this.requestsFinallyReached = false;

            this.$store.commit('System/activateLoading');

            this.axiosToken = this.$axiosToken.source();
            let parameters  = {
                page: this.page || 1,
                size: this.wantsLargeNavigation ? 12 : 9,
            };

            if (this.query && this.query !== '') {
                parameters.query        = this.query;
                this.hasSearchWithQuery = true;
            } else {
                this.hasSearchWithQuery = false;
            }

            let route = this.route(this.routeName, parameters);

            this.$axios.get(route,
                {
                    cancelToken: this.axiosToken.token,
                },
            ).then((response) => {
                this.$logger.debug('Load page for cards display', {
                    routeName: this.routeName,
                    route:     route,
                    type:      this.type,
                    data:      response.data,
                }, 'Recipe-List');

                this.recipes = response.data;

                this.dynamicallyModified    = false;
                this.requestsFinallyReached = true;
                this.axiosToken             = null;
                this.$store.commit('System/deactivateLoading');

                this.loading          = false;
                this.loadingPage      = false;
                this.reloadingRecipes = false;

                if (isSearch) {
                    this.$tracker.trackSiteSearch(this.query, 'Rezepte', this.recipes.total || 0);

                    if (! this.onDashboard && (typeof this.$route.query.query === 'undefined' || this.$route.query.query !== this.query)) {
                        let query = {...this.$route.query, query: this.query};
                        try {
                            this.$router.push({query: query});

                            this.$route.query.query = this.query;
                        } catch (e) { }
                    }
                } else if (isPageCall) {
                    this.$tracker.trackEvent('Rezepte', 'Seitenwechsel: ' + this.page + (this.onDashboard === true ? ' (Dashboard) ' : ''));

                    if (! this.onDashboard && (typeof this.$route.query.page === 'undefined' || this.$route.query.page !== this.page)) {
                        let query = {...this.$route.query, page: this.page};
                        try {
                            this.$router.push({query: query});

                            this.$route.query.page = this.page;
                        } catch (e) { }
                    }
                } else if (comesFromSearch) {
                    this.$tracker.trackEvent('Rezepte', 'Suche entfernt' + (this.onDashboard === true ? ' (Dashboard) ' : ''));

                    if (! this.onDashboard && (typeof this.$route.query.page === 'undefined' || this.$route.query.page !== this.page)) {
                        if (typeof this.$route.query !== 'undefined') {
                            let query = {...this.$route.query};
                            delete query.query;

                            try {
                                this.$router.push({query: query});

                                this.$delete(this.$route.query, 'query');
                            } catch (e) { }
                        }
                    }
                } else {
                    this.recipesExist = this.recipes.total > 0;
                }

                if (
                    typeof this.recipes !== 'undefined'
                    && typeof this.recipes.last_page !== 'undefined'
                    && this.recipes.last_page >= 1
                    && this.recipes.last_page < this.page
                ) {
                    this.page = 1;
                }
            }).catch((error) => {
                if (! this.$axiosIsCancel(error)) {
                    this.dynamicallyModified    = false;
                    this.requestsFinallyReached = true;
                    this.axiosToken             = null;
                    this.loading                = false;
                    this.loadingPage            = false;
                    this.reloadingRecipes       = false;
                    this.$store.commit('System/deactivateLoading');
                }
            });
        },
    },

    computed: {
        isListForMyRecipes() {
            return this.type === 'my' || this.type === 'mine' || this.type === 'myRecipes';
        },

        isListForPublicRecipes() {
            return this.type === 'publicRecipes' || this.type === 'public';
        },

        isListForFriendRecipes() {
            return this.type === 'friend' || this.type === 'friendRecipes' || this.type === 'friends';
        },

        isListForAllRecipes() {
            return this.type === 'all'
                || this.type === 'allRecipes'
                || this.type === 'visible'
                || this.type === 'visibleRecipes'
                || this.type === 'allVisibleRecipes';
        },

        routeName() {
            if (this.isListForFriendRecipes) {
                return 'cookbook.recipes.friends';
            }

            if (this.isListForPublicRecipes) {
                return 'cookbook.recipes.public';
            }

            if (this.isListForAllRecipes) {
                return 'cookbook.recipes.visible';
            }

            return 'cookbook.recipes.mine';
        },

        routeNameFrontend() {
            if (this.isListForFriendRecipes) {
                return 'Cookbook.Recipes.friends';
            }

            if (this.isListForPublicRecipes) {
                return 'Cookbook.Recipes.public';
            }

            if (this.isListForAllRecipes) {
                return 'Cookbook.Recipes.all';
            }

            return 'Cookbook.Recipes.mine';
        },

        frontendDetailRouteName() {
            return 'Cookbook.Recipes.detail';
        },

        frontendCreateRouteName() {
            return 'Cookbook.Recipes.create';
        },

        frontendEditRouteName() {
            return 'Cookbook.Recipes.edit';
        },

        wantsLargeNavigation() {
            return this.isFeatureFlagActive(RecipeCardsListMoreColumnsName, RecipeCardsListMoreColumnsDefault);
        },

        useSticky() {
            return this.isFeatureFlagActive(RecipesListSearchAreaStickyName, RecipesListSearchAreaStickyDefault);
        },

        stickyColorClasses() {
            return this.$store.getters['ColorManager/getSystemAppBarColorForOtherElements'];
        },

        countText() {
            if (this.reloadingRecipes || this.loading) {
                return '';
            }

            let total = this.recipes.total;

            if (! total || total <= 0) {
                total = this.recipesWithoutPossibleDuplicates.length;
            }

            if (! total || total <= 0) {
                return '';
            }

            if (this.loadingPage || (this.recipes.last_page && this.recipes.last_page === 1)) {
                if (total > 1) {
                    return `${total} Rezepte`;
                }

                return 'Ein Rezept';
            }

            let from = (this.recipes.from && this.recipes.from > 0 ? this.recipes.from : 1);
            let to   = (this.recipes.to && this.recipes.to > 0 ? this.recipes.to : total);

            return `Zeige ${from} - ${to} von ${total} Rezepten`;
        },

        detailsTroughModal() {
            if (this.onDashboard) {
                return true;
            }

            return this.isFeatureFlagActive(RecipesDetailsAsModalName, RecipesDetailsAsModalDefault);
        },

        recipesWithoutPossibleDuplicates() {
            if (typeof this.recipes.data === 'undefined') {
                return [];
            }

            return this.recipes.data.filter((recipe, index, self) => {
                return index === self.findIndex((innerRecipe) => {
                    return innerRecipe.id === recipe.id;
                });
            });
        },
    },
};
</script>
