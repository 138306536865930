<template>
    <div class="position-relative">
        <v-row>
            <v-col cols="12" md="6" lg="3">
                <v-text-field
                    v-model.number="value.calories"
                    type="number"
                    label="Kalorien"
                    persistent-hint
                    clearable
                />
            </v-col>

            <template v-if="! value.caloriesAmountForPortions">
                <v-col cols="12" md="6" lg="3">
                    <v-text-field
                        v-model.number="value.caloriesAmount"
                        type="number"
                        label="Mengenangabe"
                        clearable
                    />
                </v-col>

                <v-col cols="12" md="6" lg="3">
                    <v-combobox
                        v-model="value.caloriesAmountUnit"
                        :items="autocompletion.units"
                        clearable
                        :loading="loadingAutocomplete"
                        hint="Vorschläge nicht passend? Einfach frei eingeben!"
                        persistent-hint
                        label="Einheit"
                    ></v-combobox>
                </v-col>
            </template>
            <template v-else>
                <v-col cols="12" md="6" lg="6">
                    <v-subheader>
                        {{ value.calories }} Kalorien pro {{ value.caloriesAmount === 1 ? 'Portion' : value.caloriesAmount + ' Portionen' }}
                    </v-subheader>
                    <slider-with-plus-minus
                        v-model.number="value.caloriesAmount"
                        :thumb-label="true"
                        :min="1"
                        dense
                        :max="50"
                    />
                </v-col>
            </template>

            <v-col cols="12" md="6" lg="3">
                <v-switch
                    v-model="value.caloriesAmountForPortions"
                    @change="changeCaloriesPerPortion"
                    label="Angabe der Kalorien pro Portion?"
                />
            </v-col>
        </v-row>

        <hideable-content-with-input-and-divider
            v-model.number="value.fat"
            type="number"
            label="Fette"
            class="mt-6"
        >
            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field
                        v-model.number="value.transFat"
                        type="number"
                        label="davon ungesättigte Fettsäuren"
                        persistent-hint
                        clearable
                    />
                </v-col>

                <v-col cols="12" md="6">
                    <v-text-field
                        v-model.number="value.saturatedFat"
                        type="number"
                        label="davon gesättigte Fettsäuren"
                        persistent-hint
                        clearable
                    />
                </v-col>
            </v-row>
        </hideable-content-with-input-and-divider>

        <hideable-content-with-input-and-divider
            v-model.number="value.carbohydrate"
            type="number"
            label="Kohlenhydrate"
            class="mt-4"
        >
            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field
                        v-model.number="value.dietaryFiber"
                        type="number"
                        label="davon Ballaststoffe"
                        persistent-hint
                        clearable
                    />
                </v-col>

                <v-col cols="12" md="6">
                    <v-text-field
                        v-model.number="value.sugar"
                        type="number"
                        label="davon Zucker"
                        persistent-hint
                        clearable
                    />
                </v-col>
            </v-row>
        </hideable-content-with-input-and-divider>

        <hideable-content-sub-header-with-divider text="Weitere">
            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field
                        v-model.number="value.cholesterol"
                        type="number"
                        label="Cholesterin"
                        persistent-hint
                        hint="in Gramm"
                        clearable
                    />
                </v-col>

                <v-col cols="12" md="6">
                    <v-text-field
                        v-model.number="value.protein"
                        type="number"
                        label="Eiweiß / Protein"
                        hint="in Gramm"
                        persistent-hint
                        clearable
                    />
                </v-col>

                <v-col cols="12" md="6" lg="4">

                </v-col>
            </v-row>
        </hideable-content-sub-header-with-divider>

        <hideable-content-sub-header-with-divider text="Mineralstoffe & Vitamine">
            <v-row>
                <v-col cols="12" md="6" lg="4">
                    <v-text-field
                        v-model.number="value.salt"
                        type="number"
                        label="Salz (g)"
                        persistent-hint
                        clearable
                    />
                </v-col>

                <v-col cols="12" md="6" lg="4">
                    <v-text-field
                        v-model.number="value.sodium"
                        type="number"
                        label="Natrium (g)"
                        persistent-hint
                        clearable
                    />
                </v-col>

                <v-col cols="12" md="6" lg="4">
                    <v-text-field
                        v-model.number="value.calcium"
                        type="number"
                        label="Kalzium (mg)"
                        persistent-hint
                        clearable
                    />
                </v-col>

                <v-col cols="12" md="6" lg="4">
                    <v-text-field
                        v-model.number="value.potassium"
                        type="number"
                        label="Kalium (g)"
                        persistent-hint
                        clearable
                    />
                </v-col>

                <v-col cols="12" md="6" lg="4">
                    <v-text-field
                        v-model.number="value.iron"
                        type="number"
                        label="Eisen (mg)"
                        persistent-hint
                        clearable
                    />
                </v-col>

                <v-col cols="12" md="6" lg="4">
                    <v-text-field
                        v-model.number="value.magnesium"
                        type="number"
                        label="Magnesium (mg)"
                        persistent-hint
                        clearable
                    />
                </v-col>

                <v-col cols="12" md="6" lg="4">
                    <v-text-field
                        v-model.number="value.vitaminA"
                        type="number"
                        label="Vitamin A (g)"
                        persistent-hint
                        clearable
                    />
                </v-col>

                <v-col cols="12" md="6" lg="4">
                    <v-text-field
                        v-model.number="value.vitaminC"
                        type="number"
                        label="Vitamin C (mg)"
                        persistent-hint
                        clearable
                    />
                </v-col>

                <v-col cols="12" md="6" lg="4">
                    <v-text-field
                        v-model.number="value.vitaminD"
                        type="number"
                        label="Vitamin D (g)"
                        persistent-hint
                        clearable
                    />
                </v-col>
            </v-row>
        </hideable-content-sub-header-with-divider>

    </div>
</template>

<script>
import HideableContentSubHeaderWithDivider from '@symfia/library/Views/Helper/HideableContentSubHeaderWithDivider';
import SliderWithPlusMinus                 from '@symfia/library/Views/Inputs/SliderWithPlusMinus';
import HideableContentSlotTitleWithDivider from '@symfia/library/Views/Helper/HideableContentSlotTitleWithDivider';
import HideableContentWithInputAndDivider  from '@symfia/library/Views/Helper/HideableContentWithInputAndDivider';

export default {
    components: {
        HideableContentWithInputAndDivider,
        HideableContentSlotTitleWithDivider,
        HideableContentSubHeaderWithDivider,
        SliderWithPlusMinus,
    },

    props: {
        value: {
            type:     Object,
            required: true,
        },

        autocompletion: {
            type:    Object,
            default: function () {
                return {};
            },
        },

        loadingAutocomplete: {
            type:    Boolean,
            default: false,
        },
    },

    watch: {
        value() {
            this.$emit('input', this.value);
        },
    },

    data: () => ({}),

    beforeMount() {

    },

    mounted() {
    },

    beforeDestroy() {

    },

    methods: {
        changeCaloriesPerPortion() {
            //this.value.caloriesAmountForPortions = ! this.value.caloriesAmountForPortions;

            if (this.value.caloriesAmountForPortions) {
                this.value.caloriesAmount = 1;
            } else {
                this.value.caloriesAmount = null;
            }
        },
    },
};
</script>
