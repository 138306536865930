<template>
    <div v-if="stepTitle || levelLabel || timeLabel">
        <span class="font-bold text-base text-almostSm md:text-sm" v-if="stepTitle">
            {{ stepTitle }}
        </span>
    </div>
</template>

<script>
import SubHeaderWithDivider from '@symfia/library/Views/Helper/SubHeaderWithDivider';
import Divider              from '@symfia/library/Views/Helper/Divider';
import SubHeader            from '@symfia/library/Views/Helper/SubHeader';

export default {
    components: {
        SubHeaderWithDivider,
        Divider,
        SubHeader,
    },

    props: {
        step: {
            type:     Object,
            required: true,
        },

        withDivider: {
            typ: Boolean,
            default: true
        }
    },

    data: () => ({}),

    watch: {},

    mounted() {

    },

    beforeDestroy() {

    },

    methods: {},

    computed: {
        levelIcon() {
            if (! this.step.level || this.step.level === '')  {
                return null;
            }

            switch (this.step.level) {
                case 'very-easy':
                    return '$difficultyVeryEasy';

                case 'easy':
                    return '$difficultyEasy';

                case 'normal':
                    return '$difficultyNormal';

                case 'hard':
                    return '$difficultyHard';

                case 'very-hard':
                    return '$difficultyVeryHard';

                default:
                    return '$difficultyNormal';
            }
        },

        levelLabel() {
            if (! this.step.level || this.step.level === '')  {
                return null;
            }

            return this.step.level_text;
        },

        timeLabel() {
            if (! this.step.time || this.step.time <= 0 || this.step.time === '')  {
                return null;
            }

            return this.step.time_human;
        },

        stepTitle() {
            return this.step.title && this.step.title !== '' ? this.step.title : null;
        },
    },
};
</script>
