<template>
    <v-alert
        dense
        dismissible
        elevation="4"
        text
        type="info"
    >
        Sobald du den Import startest, wird dieser im Hintergrund ausgeführt. Du erhälst dann immer aktuelle Benachrichtigungen.
        Du erhälst außerdem bei deinen Benachrichtigungen eine Info, welche Seiten aktiv importiert werden können - sollten von mehreren Seiten
        nur manche nicht importiert werden können, erhälst du keine extra Info!
    </v-alert>
</template>

<script>

export default {

};
</script>
