<template>
    <hideable-content-slot-title-with-divider
        :width-class="usedWidthClass"
        :margin-class-for-slot-divider="marginClassForSlotDivider"
        :icon-class="iconClass"
        :without-animation="withoutAnimation"
        :start-hidden="startHidden"
        :show-divider="showDivider"
        :color-divider="colorDivider"
        :icon-color="iconColor"
        :change-accent-for-theme="changeAccentForTheme"
        :use-margin-bottom="useMarginBottom"
    >
        <template v-slot:title>
            <v-text-field
                v-model="internalValue"
                class="m-0 p-0"
                dense
                :hide-details="hideDetails"
                :type="type"
                :label="label"
                :hint="hint"
                :persistent-hint="persistentHint"
                :clearable="clearableInput"
            />
        </template>

        <slot></slot>
    </hideable-content-slot-title-with-divider>
</template>

<script>
import SubHeader                           from './SubHeader';
import Divider                             from './Divider';
import HideableContentSlotTitleWithDivider from '@symfia/library/Views/Helper/HideableContentSlotTitleWithDivider';

export default {
    components: {
        HideableContentSlotTitleWithDivider,
        SubHeader,
        Divider,
    },

    watch: {
        value(newVal) {
            this.lazyValue = newVal;
        },
    },

    props: {
        value: {
            required: true,
        },

        hideDetails: {
            type:    [Boolean, String],
            default: 'auto',
        },

        persistentHint: {
            type:    Boolean,
            default: false,
        },

        clearableInput: {
            type:    Boolean,
            default: true,
        },

        hint: {
            type:    String,
            default: null,
        },

        type: {
            type:    String,
            default: null,
        },

        label: {
            type:    String,
            default: null,
        },

        widthClass: {
            type:    String,
            default: 'w-30%',
        },

        widthClassMobile: {
            type:    String,
            default: 'w-60%',
        },

        marginClassForSlotDivider: {
            type:    String,
            default: 'mb-2',
        },

        iconClass: {
            type:    String,
            default: 'pt-1',
        },

        withoutAnimation: {
            type:    Boolean,
            default: false,
        },

        startHidden: {
            type:    Boolean,
            default: false,
        },

        showDivider: {
            type:    Boolean,
            default: true,
        },

        colorDivider: {
            type:    String,
            default: 'blue',
        },

        iconColor: {
            type:    String,
            default: 'info',
        },

        changeAccentForTheme: {
            type:    Boolean,
            default: true,
        },

        useMarginBottom: {
            type:    Boolean,
            default: true,
        },
    },

    data: () => ({
        hidden:    false,
        lazyValue: null,
    }),

    created() {
        this.lazyValue = this.value;
        this.hidden    = this.startHidden === true;
    },

    computed: {
        usedWidthClass() {
            return this.$vuetify.breakpoint.mobile ? this.widthClassMobile : this.widthClass;
        },

        internalValue: {
            get() {
                return this.lazyValue;
            },

            set(val) {
                if (val === this.lazyValue) {
                    return;
                }

                this.lazyValue = val;
                this.$emit('input', val);
            },
        },

        icon() {
            return this.hidden ? '$plus' : '$minus';
        },
    },

    methods: {
        toggleContent() {
            this.hidden = ! this.hidden;

            this.$emit('subheader:content-visible', this.hidden);
        },
    },
};
</script>
