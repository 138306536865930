<template>
    <div class="position-relative">
        <div v-if="detailsNeedsLoad" class="text-center">
            <v-icon>$spinner</v-icon>
            - Die Infos zum Rezept werden geladen
        </div>
        <div v-else>
            <v-dialog
                v-if="showDeleteDialog"
                :value="showDeleteDialog"
                :fullscreen="$breakpoint.smAndDown"
                :persistent="isDeleting"
                content-class="!shadow-none"
                max-width="95%">
                <alert-content-card
                    v-if="showDeleteDialog"
                    :action-button-is-loading="isDeleting"
                    v-on:alert-component:cancel="showDeleteDialog = false"
                    v-on:alert-component:action="triggerRecipeDeletion">
                    <template slot="title">
                        Möchtest du das Rezept '{{ recipe.title }}' löschen?
                    </template>

                    <template slot="actionButton">
                        Löschen
                    </template>

                    <template slot="cancelButton">
                        Abbrechen
                    </template>

                    Du bist im Begriff, das Rezept '{{ recipe.title }}' zu löschen. Möchtest du fortfahren?
                </alert-content-card>
            </v-dialog>

            <v-img
                v-if="bannerImage"
                :lazy-src="bannerPlaceholder"
                :position="backgroundPosition"
                :max-height="maxHeightBanner"
                :height="maxHeightBanner"
                width="100%"
                :src="bannerImage"
            >
                <template v-slot:placeholder>
                    <div :style="{height: maxHeightBanner + 'px'}" class="-mt-12">
                        <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-row>
                    </div>
                </template>
            </v-img>

            <div :class="{
            '-mt-16': bannerImage,
            'mr-5': bannerImage,
            'ml-5': bannerImage
        }">
                <v-card>
                    <sticky-with-intersection :disabled="! shouldUseStickyHeader">
                        <v-toolbar>
                            <v-toolbar-title class="w-full text-center recipe-details-card-header-title ">
                                {{ recipe.title }}
                            </v-toolbar-title>

                            <div slot="extension" class="mb-2 w-full">
                                <div class="flex">
                                    <tab-group
                                        :use-dropdown="$breakpoint.isMobile"
                                        :active-tab="activeTab"
                                        v-on:recipe:update:tab="changeActiveTab"
                                        :additional-loaded="! additionalNeedsLoad"
                                        :calories-loaded="! caloriesNeedsLoad"
                                        :calories-count="recipe.calories"
                                        :ingredients-count="recipe.ingredients_count"
                                        :ingredients-loaded="! ingredientsNeedsLoad"
                                        :steps-count="recipe.steps_count"
                                        :steps-loaded="!  stepsNeedsLoad"
                                        :as-tab-menu="shouldUseTabs"
                                        :recipe-details-and-ingredients-steps-swapped="shouldSwapRecipeDetailsWithIngredientsStepsOnMobile"
                                        :ingredients-and-steps-together="shouldDisplayIngredientsStepsTogether"
                                    />

                                    <template>
                                        <v-spacer></v-spacer>

                                        <div>
                                            <v-btn v-if="withReturnButton" color="warning" x-small @click="backLink">{{ returnButtonText }}</v-btn>
                                            <v-btn v-if="withEditButton && isOwnRecipe" color="success" class="ml-2" x-small @click="editLink">Bearbeiten</v-btn>
                                            <v-btn v-if="withDeleteButton && isOwnRecipe" color="error" class="ml-2" x-small @click="deleteRecipe">Löschen</v-btn>
                                        </div>
                                    </template>
                                </div>

                            </div>
                        </v-toolbar>
                    </sticky-with-intersection>

                    <v-card-text>
                        <div>
                            <div class="w-full">
                                <template v-if="shouldUseTabs">
                                    <v-slide-x-transition mode="out-in">
                                        <div
                                            class="!animation-duration-100"
                                            v-if="activeTab === 'ingredientsSteps'"
                                            key=1
                                        >
                                            <ingredients-steps
                                                :portions-for-calculation="portionsForCalculation"
                                                v-on:recipe:portions:updated="updatedPortions"
                                                :recipe="recipe"
                                            />
                                        </div>

                                        <div
                                            class="!animation-duration-100"
                                            v-else-if="activeTab === 'ingredients'"
                                            key=2
                                        >
                                            <ingredients
                                                :portions-for-calculation="portionsForCalculation"
                                                v-on:recipe:portions:updated="updatedPortions"
                                                :show-title-if-necessary="false"
                                                :recipe="recipe"
                                            />
                                        </div>

                                        <div
                                            class="!animation-duration-100"
                                            v-else-if="activeTab === 'steps'"
                                            key=3
                                        >
                                            <steps :show-title-if-necessary="false" :recipe="recipe"/>
                                        </div>

                                        <div
                                            class="!animation-duration-100"
                                            v-else-if="activeTab === 'calories'"
                                            key=4
                                        >
                                            <calories :recipe="recipe"/>
                                        </div>

                                        <div
                                            class="!animation-duration-100"
                                            v-else-if="activeTab === 'additional'"
                                            key=5
                                        >
                                            <additional :recipe="recipe" show-more/>
                                        </div>

                                        <div
                                            v-else
                                            key=0
                                            class="!animation-duration-100"
                                        >
                                            <recipe
                                                :portions-for-calculation="portionsForCalculation"
                                                v-on:recipe:portions:updated="updatedPortions"
                                                :show-additionals="false"
                                                :recipe="recipe"
                                            />
                                        </div>
                                    </v-slide-x-transition>
                                </template>

                                <div v-if="! shouldUseTabs">
                                    <template v-if="! shouldSwapRecipeDetailsWithIngredientsStepsOnMobile">
                                        <sub-header-with-divider
                                            :id="recipeDetailsId" text="Infos zum Rezept"
                                            :icon="collapsed.recipe ? '$plus' : '$minus'"
                                            :show-divider="! collapsed.recipe"
                                            class="mb-4"
                                            v-on:subheader:icon-clicked="collapsed.recipe = ! collapsed.recipe"
                                        />
                                        <v-slide-y-transition>
                                            <recipe
                                                :portions-for-calculation="portionsForCalculation"
                                                v-on:recipe:portions:updated="updatedPortions"
                                                class="w-full"
                                                :recipe="recipe"
                                                v-show="! collapsed.recipe"
                                                :show-additionals="false"
                                            />
                                        </v-slide-y-transition>
                                    </template>

                                    <recipe-additionals v-if="! shouldDisplayIngredientsStepsTogether && false" :recipe="recipe"/>

                                    <template v-if="shouldDisplayIngredientsStepsTogether">
                                        <sub-header-with-divider
                                            id="recipe-details-ingredientsSteps" text="Infos zur Zubereitung"
                                            :icon="collapsed.ingredientsSteps ? '$plus' : '$minus'"
                                            :show-divider="! collapsed.ingredientsSteps"
                                            v-on:subheader:icon-clicked="collapsed.ingredientsSteps = ! collapsed.ingredientsSteps"
                                        />
                                        <v-slide-y-transition>
                                            <ingredients-steps
                                                :portions-for-calculation="portionsForCalculation"
                                                v-on:recipe:portions:updated="updatedPortions"
                                                :recipe="recipe"
                                                v-show="! collapsed.ingredientsSteps"
                                                :show-additionals="false"
                                            />
                                        </v-slide-y-transition>
                                    </template>
                                    <template v-else>
                                        <sub-header-with-divider
                                            id="recipe-details-ingredients" text="Zutaten"
                                            :icon="collapsed.ingredients ? '$plus' : '$minus'"
                                            :show-divider="! collapsed.ingredients"
                                            v-on:subheader:icon-clicked="collapsed.ingredients = ! collapsed.ingredients"
                                        />
                                        <v-slide-y-transition>
                                            <ingredients
                                                :portions-for-calculation="portionsForCalculation"
                                                v-on:recipe:portions:updated="updatedPortions"
                                                :recipe="recipe"
                                                v-show="! collapsed.ingredients"
                                                :show-title-if-necessary="false"
                                            />
                                        </v-slide-y-transition>

                                        <sub-header-with-divider
                                            id="recipe-details-steps" text="Zubereitung"
                                            :icon="collapsed.steps ? '$plus' : '$minus'"
                                            :show-divider="! collapsed.steps"
                                            v-on:subheader:icon-clicked="collapsed.steps = ! collapsed.steps"
                                        />
                                        <v-slide-y-transition>
                                            <steps :recipe="recipe" v-show="! collapsed.steps" :show-title-if-necessary="false"/>
                                        </v-slide-y-transition>
                                    </template>

                                    <template v-if="shouldSwapRecipeDetailsWithIngredientsStepsOnMobile">
                                        <sub-header-with-divider
                                            :id="recipeDetailsId" text="Infos zum Rezept"
                                            :icon="collapsed.recipe ? '$plus' : '$minus'"
                                            :show-divider="! collapsed.recipe"
                                            class="mb-4"
                                            v-on:subheader:icon-clicked="collapsed.recipe = ! collapsed.recipe"
                                        />
                                        <v-slide-y-transition>
                                            <recipe
                                                :portions-for-calculation="portionsForCalculation"
                                                v-on:recipe:portions:updated="updatedPortions"
                                                class="w-full"
                                                :recipe="recipe"
                                                v-show="! collapsed.recipe"
                                                :show-additionals="false"
                                            />
                                        </v-slide-y-transition>
                                    </template>

                                    <sub-header-with-divider
                                        id="recipe-details-calories" text="Kalorien"
                                        :icon="collapsed.calories ? '$plus' : '$minus'"
                                        :show-divider="! collapsed.calories"
                                        v-on:subheader:icon-clicked="collapsed.calories = ! collapsed.calories"
                                    />
                                    <v-slide-y-transition>
                                        <calories :recipe="recipe" v-show="! collapsed.calories"/>
                                    </v-slide-y-transition>

                                    <sub-header-with-divider
                                        id="recipe-details-additional" text="Zusätzliches"
                                        :icon="collapsed.additional ? '$plus' : '$minus'"
                                        :show-divider="! collapsed.additional"
                                        v-on:subheader:icon-clicked="collapsed.additional = ! collapsed.additional"
                                    />
                                    <v-slide-y-transition>
                                        <additional :recipe="recipe" v-show="! collapsed.additional" show-more/>
                                    </v-slide-y-transition>
                                </div>
                            </div>
                        </div>

                        <template v-if="displayComments && recipe.id">
                            <comments class="mt-4" module="recipe" :id="recipe.id" />
                        </template>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
import {
    flagName as RecipesDetailsStepsAndIngredientsTogetherName,
    defaultActive as RecipesDetailsStepsAndIngredientsTogetherDefault,
} from './../../Config/Flags/RecipesDetailsStepsAndIngredientsTogether';

import {
    flagName as RecipeDetailsWithTabsName,
    defaultActive as RecipeDetailsWithTabsDefault,
    settingFlagName as RecipeDetailsWithTabsActiveDefaultTabName,
    settingDefaultActive as RecipeDetailsWithTabsActiveDefaultTabDefault,
    inactiveSettingFlagName as RecipeDetailsWithTabsInactiveTabsOnMobileName,
    inactiveSettingDefaultActive as RecipeDetailsWithTabsInactiveTabsOnMobileDefault,
    inactiveSettingFlagNameSecond as RecipeDetailsWithTabsInactiveDefaultTabName,
    inactiveSettingDefaultActiveSecond as RecipeDetailsWithTabsInactiveDefaultTabDefault,
    inactiveSettingFlagNameThird as RecipeDetailsWithTabsRecipeDetailsCollapsedOnMobile,
    inactiveSettingDefaultActiveThird as RecipeDetailsWithTabsRecipeDetailsCollapsedOnMobileDefault,
    inactiveSettingFlagNameFourth as RecipeDetailsWithRecipeDetailsSwappedWithIngredientsStepsOnMobile,
    inactiveSettingDefaultActiveFourth as RecipeDetailsWithRecipeDetailsSwappedWithIngredientsStepsOnMobileDefault,
} from './../../Config/Flags/RecipeDetailsWithTabs';

import {
    flagName as RecipeDetailsStickyHeader,
    defaultActive as RecipeDetailsStickyHeaderDefault,
    settingFlagName as RecipeDetailsStickyHeaderOnMobile,
    settingDefaultActive as RecipeDetailsStickyHeaderOnMobileDefault,
} from './../../Config/Flags/RecipeDetailsStickyHeader';

import StickyWithIntersection from '@symfia/library/Views/Helper/StickyWithIntersection';
import TabGroup               from './TabGroup';
import Recipe                 from './Data/Recipe';
import Ingredients            from './Data/Ingredients';
import Steps                  from './Data/Steps';
import Additional             from './Data/Additional';
import Calories               from './Data/Calories';
import IngredientsSteps       from './Data/IngredientsSteps';
import SubHeaderWithDivider   from '@symfia/library/Views/Helper/SubHeaderWithDivider';
import RecipeImage            from './RecipeImage';
import RecipeAdditionals      from './Data/RecipeAdditionals';
import AlertContentCard       from '@symfia/library/Views/Components/TailwindUi/AlertContentCard';
import Comments from '@symfia/social/comments/views/Index';

export default {
    components: {
        StickyWithIntersection,
        TabGroup,
        Recipe,
        Ingredients,
        Steps,
        Additional,
        Calories,
        SubHeaderWithDivider,
        IngredientsSteps,
        RecipeImage,
        RecipeAdditionals,
        AlertContentCard,
        Comments,
    },

    props: {
        wantedRecipeId: {
            type:    [Number, String],
            default: null,
        },

        wantedRecipe: {
            type:    Object,
            default: null,
        },

        withReturnButton: {
            type:    Boolean,
            default: true,
        },

        withEditButton: {
            type:    Boolean,
            default: true,
        },

        withDeleteButton: {
            type:    Boolean,
            default: true,
        },

        useTabs: {
            type:    Boolean,
            default: null,
        },

        ingredientsStepsTogether: {
            type:    Boolean,
            default: null,
        },

        returnButtonText: {
            type:    String,
            default: 'Übersicht',
        },

        displayComments: {
            type:    Boolean,
            default: true,
        },
    },

    data: () => ({
        activeTab:                          'recipe',
        recipeDetailsId:                    'recipe-details-recipe',
        useATabMenu:                        null,
        displayIngredientsAndStepsTogether: null,
        recipe:                             null,
        portionsForCalculation:             null,
        loading:                            false,
        showDeleteDialog:                   false,
        isDeleting:                         false,
        isDeletingAxios:                    false,
        axiosToken:                         null,
        collapsed:                          {
            recipe:           false,
            ingredientsSteps: false,
            ingredients:      false,
            steps:            false,
            calories:         false,
            additional:       false,
        },
    }),

    watch: {
        activeTab(newValue) {
            if (this.shouldUseTabs) {
                if (this.shouldDisplayIngredientsStepsTogether && (newValue === 'ingredients' || newValue === 'steps')) {
                    this.activeTab = 'ingredientsSteps';
                }
                return;
            }

            this.$vuetify.goTo('#recipe-details-' + this.activeTab, {
                offset: document.getElementsByClassName('stickyElement')[0].offsetHeight,
            });
        },
    },

    beforeMount() {
        this.recipe = null;

        if (this.wantedRecipe) {
            this.recipe = this.wantedRecipe;
        }

        if (this.portionsForCalculation === null && this.recipe && this.recipe.portions) {
            this.portionsForCalculation = this.recipe.portions;
        }
    },

    mounted() {
        this.activeTab        = this.defaultTabNameWithTogether;
        this.collapsed.recipe = this.shouldCollapseRecipeDetailsOnMobile && ! this.shouldSwapRecipeDetailsWithIngredientsStepsOnMobile;

        if (this.recipeNeedsLoad) {
            this.$nextTick(() => {
                this.loadRecipes();
            });
        }

        /*this.$echo.listenPrivate(
            this.$store.getters['User/broadcastUserChannel'],
            '.Recipe',
            this.addOrUpdateRecipe,
            'cookbook-recipes-list-view-my-' + this.type + (this.onDashboard ? '-dasboard' : ''),
        );*/
    },

    beforeDestroy() {
        this.cancelRequests();

        /*this.$echo.stopListeningPrivate(
            this.$store.getters['User/broadcastUserChannel'],
            '.RecipeFriend',
            this.addOrUpdateRecipe,
            'cookbook-recipes-list-view-friend-' + this.type + (this.onDashboard ? '-dasboard' : ''),
        );*/

    },

    methods: {
        changeActiveTab(tab) {
            if (this.displayIngredientsAndStepsTogether && (tab === 'ingredients' || tab === 'steps')) {
                tab = 'ingredientsSteps';
            }

            this.activeTab = tab;
        },

        updatedPortions(newPortions) {
            this.portionsForCalculation = newPortions;
        },

        backLink() {
            this.$emit('recipe:details:back');
        },

        editLink() {
            this.$emit('recipe:details:edit', this.recipe);
        },

        deleteRecipe() {
            this.showDeleteDialog = true;
        },

        triggerRecipeDeletion() {
            this.isDeleting      = true;
            this.isDeletingAxios = this.$axiosToken.source();

            const route = this.route('cookbook.recipe.delete', {recipe: this.recipe.id});
            this.$axios.delete(route,
                {
                    cancelToken: this.isDeletingAxios.token,
                },
            ).then((response) => {
                this.$logger.debug('Rezept gelöscht', {
                    data:   response.data,
                    recipe: this.recipe.title,
                }, 'Recipe-Details');

                this.$emit('recipe:details:deleted', this.recipe);
                this.$emit('recipe:details:back');
            }).catch((error) => {
                this.$logger.warn('Error beim Löschen des Rezepts', {
                    error:    error,
                    response: this.geResponseFromAxiosError(error),
                }, 'Recipe-Details');

                this.$notifications.error('Beim Löschen deines Rezepts trat ein Fehler auf. Bitte probiere es erneut.');
            }).finally(() => {
                this.isDeleting      = false;
                this.isDeletingAxios = null;
            });
        },

        cancelRequests() {
            if (this.axiosToken) {
                this.axiosToken.cancel();
                this.axiosToken = null;
            }

            if (this.isDeletingAxios) {
                this.isDeletingAxios.cancel();
                this.isDeletingAxios = null;
            }
        },

        loadRecipes() {
            if (this.loading) {
                return;
            }

            this.$store.commit('System/activateLoading');

            this.axiosToken = this.$axiosToken.source();

            const route = this.route('cookbook.recipe.load', {recipe: this.recipeIdForLoading});
            this.$axios.get(route,
                {
                    cancelToken: this.axiosToken.token,
                },
            ).then((response) => {
                this.$logger.debug('Details loaded', {
                    route: route,
                    data:  response.data,
                }, 'Recipe-Details');

                this.recipe = response.data;

                if (this.portionsForCalculation === null) {
                    this.portionsForCalculation = response.data.portions;
                }
            }).catch((error) => {
                this.$logger.debug('Details loaded failed', {
                    error:    error,
                    response: typeof error.response !== 'undefined' ? error.response : null,
                }, 'Recipe-Details');

                if (! this.$axiosIsCancel(error)) {
                    this.$notifications.error('Beim Laden trat ein Fehler auf. Bitte versuche es über die Liste erneut');
                }
            }).finally(() => {
                this.loading    = false;
                this.axiosToken = null;
                this.$store.commit('System/deactivateLoading');
            });
        },
    },

    computed: {
        defaultTabNameWithTogether() {
            return this.displayIngredientsAndStepsTogether
            && (
                this.defaultTabName === 'ingredients'
                || this.defaultTabName === 'steps'
            ) ? 'ingredientsSteps' : this.defaultTabName;
        },

        defaultTabName() {
            if (this.shouldUseTabs) {
                return this.defaultTab || 'recipe';
            }

            if (this.shouldUseTabsOnMobile) {
                return this.defaultTabOnMobile || 'recipe';
            }

            return 'recipe';
        },

        flagIngredientsAndStepsTogether() {
            return this.isFeatureFlagActive(RecipesDetailsStepsAndIngredientsTogetherName, RecipesDetailsStepsAndIngredientsTogetherDefault);
        },

        flagUseStickyHeader() {
            return this.isFeatureFlagActive(RecipeDetailsStickyHeader, RecipeDetailsStickyHeaderDefault);
        },

        flagUseStickyHeaderOnMobile() {
            if (! this.flagShouldUseTabs) {
                return false;
            }

            return this.getFeatureFlagSetting(
                RecipeDetailsStickyHeader,
                RecipeDetailsStickyHeaderOnMobile,
                RecipeDetailsStickyHeaderOnMobileDefault,
            ) === true;
        },

        flagShouldUseTabs() {
            return this.isFeatureFlagActive(RecipeDetailsWithTabsName, RecipeDetailsWithTabsDefault);
        },

        flagShouldUseTabsOnMobile() {
            return ! this.flagShouldUseTabs && this.getFeatureFlagSetting(
                RecipeDetailsWithTabsName,
                RecipeDetailsWithTabsInactiveTabsOnMobileName,
                RecipeDetailsWithTabsInactiveTabsOnMobileDefault,
            ) === true;
        },

        flagRecipeDetailsCollapsedMobile() {
            return ! this.shouldUseTabs && this.getFeatureFlagSetting(
                RecipeDetailsWithTabsName,
                RecipeDetailsWithTabsRecipeDetailsCollapsedOnMobile,
                RecipeDetailsWithTabsRecipeDetailsCollapsedOnMobileDefault,
            ) === true;
        },

        flagRecipeDetailsSwappedWithIngredientsSteps() {
            return ! this.shouldUseTabs && this.getFeatureFlagSetting(
                RecipeDetailsWithTabsName,
                RecipeDetailsWithRecipeDetailsSwappedWithIngredientsStepsOnMobile,
                RecipeDetailsWithRecipeDetailsSwappedWithIngredientsStepsOnMobileDefault,
            ) === true;
        },

        shouldUseStickyHeader() {
            if (! this.flagUseStickyHeader) {
                return false;
            }

            if (this.$breakpoint.isMobile && ! this.flagUseStickyHeaderOnMobile) {
                return false;
            }

            return true;
        },

        shouldUseTabsOnMobile() {
            return this.flagShouldUseTabsOnMobile && this.$breakpoint.isMobile;
        },

        shouldCollapseRecipeDetailsOnMobile() {
            return this.flagRecipeDetailsCollapsedMobile && this.$breakpoint.isMobile;
        },

        shouldSwapRecipeDetailsWithIngredientsStepsOnMobile() {
            return this.flagRecipeDetailsSwappedWithIngredientsSteps && this.$breakpoint.isMobile;
        },

        defaultTab() {
            if (! this.flagShouldUseTabs) {
                return null;
            }

            return this.getFeatureFlagSetting(
                RecipeDetailsWithTabsName,
                RecipeDetailsWithTabsActiveDefaultTabName,
                RecipeDetailsWithTabsActiveDefaultTabDefault,
            );
        },

        defaultTabOnMobile() {
            if (! this.shouldUseTabsOnMobile) {
                return null;
            }

            return this.getFeatureFlagSetting(
                RecipeDetailsWithTabsName,
                RecipeDetailsWithTabsInactiveDefaultTabName,
                RecipeDetailsWithTabsInactiveDefaultTabDefault,
            );
        },

        shouldUseTabs() {
            if (this.useTabs !== null) {
                return this.useTabs;
            }

            if (this.useATabMenu !== null) {
                return this.useATabMenu;
            }

            return this.flagShouldUseTabs || this.shouldUseTabsOnMobile;
        },

        shouldDisplayIngredientsStepsTogether() {
            if (this.ingredientsStepsTogether !== null) {
                return this.ingredientsStepsTogether;
            }

            if (this.displayIngredientsAndStepsTogether !== null) {
                return this.displayIngredientsAndStepsTogether;
            }

            return this.flagIngredientsAndStepsTogether;
        },

        coverImage() {
            if (typeof this.recipe.cover !== 'undefined' && this.recipe.cover !== null && this.recipe.cover !== '') {
                return this.recipe.cover;
            }

            return null;
        },

        bannerImage() {
            if (typeof this.recipe.background !== 'undefined' && this.recipe.background !== null && this.recipe.background !== '') {
                return this.recipe.background;
            }

            return this.coverImage;
        },

        bannerPlaceholder() {
            if (
                typeof this.recipe.bannerPlaceholderBase64 !== 'undefined'
                && this.recipe.bannerPlaceholderBase64 !== null
                && this.recipe.bannerPlaceholderBase64 !== ''
            ) {
                return this.recipe.bannerPlaceholderBase64;
            }

            if (
                typeof this.recipe.bannerPlaceholder !== 'undefined'
                && this.recipe.bannerPlaceholder !== null
                && this.recipe.bannerPlaceholder !== ''
            ) {
                return this.recipe.bannerPlaceholder;
            }

            return this.coverPlaceholder;
        },

        coverPlaceholder() {
            if (
                typeof this.recipe.placeholderBase64 !== 'undefined'
                && this.recipe.placeholderBase64 !== null
                && this.recipe.placeholderBase64 !== ''
            ) {
                return this.recipe.placeholderBase64;
            }

            if (
                typeof this.recipe.placeholder !== 'undefined'
                && this.recipe.placeholder !== null
                && this.recipe.placeholder !== ''
            ) {
                return this.recipe.placeholder;
            }

            return null;
        },

        maxHeightBanner() {
            return 150;
        },

        backgroundPosition() {
            return 'center center';
        },

        recipeIdForLoading() {
            if (this.recipe !== null && typeof this.recipe === 'object' && typeof this.recipe.id !== 'undefined') {
                return this.recipe.id;
            }

            if (this.wantedRecipe !== null && typeof this.wantedRecipe === 'object' && typeof this.wantedRecipe.id !== 'undefined') {
                return this.wantedRecipe.id;
            }

            return this.wantedRecipeId;
        },

        recipeNeedsLoad() {
            return this.detailsNeedsLoad
                || this.caloriesNeedsLoad
                || this.additionalNeedsLoad
                || this.stepsNeedsLoad
                || this.ingredientsNeedsLoad;
        },

        detailsNeedsLoad() {
            return this.recipe === null
                || typeof this.recipe !== 'object'
                || typeof this.recipe.id === 'undefined'
                || ! this.recipe.id
                || typeof this.recipe.title === 'undefined';
        },

        caloriesNeedsLoad() {
            if (
                this.recipe === null
                || typeof this.recipe !== 'object'
                || typeof this.recipe.calorie === 'undefined'
            ) {
                return true;
            }

            if (typeof this.recipe.calorie_loaded === 'undefined') {
                return false;
            }

            return this.recipe.calorie_loaded === false;
        },

        additionalNeedsLoad() {
            if (
                this.recipe === null
                || typeof this.recipe !== 'object'
                || typeof this.recipe.additional === 'undefined'
            ) {
                return true;
            }

            if (typeof this.recipe.additional_loaded === 'undefined') {
                return false;
            }

            return this.recipe.additional_loaded === false;
        },

        stepsNeedsLoad() {
            if (
                this.recipe === null
                || typeof this.recipe !== 'object'
                || typeof this.recipe.step_groups === 'undefined'
            ) {
                return true;
            }

            if (typeof this.recipe.step_groups_loaded === 'undefined') {
                return false;
            }

            return this.recipe.step_groups_loaded === false;
        },

        ingredientsNeedsLoad() {
            if (
                this.recipe === null
                || typeof this.recipe !== 'object'
                || typeof this.recipe.ingredient_groups === 'undefined'
            ) {
                return true;
            }

            if (typeof this.recipe.ingredient_groups_loaded === 'undefined') {
                return false;
            }

            return this.recipe.ingredient_groups_loaded === false;
        },

        isOwnRecipe() {
            if (! this.recipe || typeof this.recipe.user === 'undefined' || typeof this.recipe.user.id === 'undefined') {
                return false;
            }

            return this.isActualUser(this.recipe.user.id);
        },
    },
};
</script>
