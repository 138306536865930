<template>
    <div class="position-relative">
        <v-boilerplate-loading :elevation="0" v-if="! isLoaded || ! additionalLoaded" type="list-item-two-line@1" class="w-full"/>
        <template v-else>
            <sub-header-with-divider :show-divider="showDivider" v-if="recipe.additional.description" text="Über das Rezept"/>
            <div v-if="recipe.additional.description" v-html="recipe.additional.description"></div>
        </template>
    </div>
</template>

<script>
import SubHeaderWithDivider from '@symfia/library/Views/Helper/SubHeaderWithDivider';

export default {
    components: {
        SubHeaderWithDivider,
    },

    props: {
        recipe: {
            type:     Object,
            required: true,
        },

        showDivider: {
            type: Boolean,
            default: true
        }
    },

    data: () => ({}),

    watch: {},

    mounted() {

    },

    beforeDestroy() {

    },

    methods: {},

    computed: {
        isLoaded() {
            return ! (this.recipe === null
                || typeof this.recipe !== 'object'
                || typeof this.recipe.id === 'undefined'
                || ! this.recipe.id
                || typeof this.recipe.title === 'undefined');
        },

        additionalLoaded() {
            if (
                this.recipe === null
                || typeof this.recipe !== 'object'
                || typeof this.recipe.additional === 'undefined'
            ) {
                return false;
            }

            if (typeof this.recipe.additional_loaded === 'undefined') {
                return true;
            }

            return this.recipe.additional_loaded === true;
        },
    },
};
</script>
