<template>
    <v-slider
        :value="internalValue"
        :step="step"
        :min="usedMin"
        :max="usedMax"

        @end="handleSliderUpdate('end', $event)"
        @input="handleSliderUpdate('input', $event)"
        @change="handleSliderUpdate('change', $event)"

        :disabled="disabled"
        :thumb-label="thumbLabel"
        :vertical="vertical"
        :loading="loading"
        :dense="dense"
        :hint="hint"
        :label="label"
        :persistent-hint="persistentHint"

        v-bind="$attrs"
    >
        <template v-slot:message v-if="$slots.hint">
            <slot name="hint"></slot>
        </template>

        <template v-slot:append v-if="withoutInput !== true && ($vuetify.breakpoint.mdAndUp || withoutInputOnMobile !== true)">
            <v-text-field
                @input="handleTextFieldUpdate($event)"
                :value="internalValue"
                v-model.number="inputValue"
                class="mt-0 pt-0 ml-"
                :hide-details="hintForTextField === null || withoutInputHint === true"
                single-line
                type="number"
                :min="usedMin"
                :max="usedMax"
                :persistent-hint="hintForTextField !== null && withoutInputHint !== true"
                :hint="hintForTextField"
                dense
            ></v-text-field>
        </template>
    </v-slider>
</template>

<script>

export default {
    props: {
        value: {
            type:    Number,
            default: true,
        },

        withoutInput: {
            type:    Boolean,
            default: false,
        },

        withoutInputHint: {
            type:    Boolean,
            default: false,
        },

        withoutInputOnMobile: {
            type:    Boolean,
            default: false,
        },

        updateImmediately: {
            type:    Boolean,
            default: false,
        },

        noUpdateOnChange: {
            type:    Boolean,
            default: false,
        },

        noUpdateOnEnd: {
            type:    Boolean,
            default: true,
        },

        step: {
            type:    [Number, String],
            default: 1,
        },

        min: {
            type:    [Number, String],
            default: 0,
        },

        max: {
            type:    [Number, String],
            default: 100,
        },

        disabled:       Boolean,
        thumbLabel:     {
            type:    [Boolean, String],
            default: undefined,
        },
        vertical:       Boolean,
        loading:        {
            type:    [Boolean, String],
            default: false,
        },
        dense:          Boolean,
        hint:           String,
        label:          String,
        persistentHint: Boolean,
    },

    data: () => ({
        lazyValue:  0,
        inputValue: 0,
    }),

    created() {
        this.lazyValue  = this.value;
        this.inputValue = this.value;
    },

    watch: {
        value(newVal) {
            this.lazyValue  = newVal;
            this.inputValue = newVal;
        },
    },

    computed: {
        usedMin: function () {
            return parseFloat(this.min);
        },

        usedMax: function () {
            return parseFloat(this.max);
        },

        stepNumeric() {
            return this.step > 0 ? parseFloat(this.step) : 0;
        },

        internalValue: {
            get() {
                return this.lazyValue;
            },

            set(val) {
                const value = isNaN(val) ? this.usedMin : val;

                if (value === this.lazyValue) {
                    return;
                }

                this.lazyValue  = value;
                this.inputValue = value;
                this.$emit('input', value);
            },
        },

        hintForTextField: function () {
            if (this.inputValue !== this.internalValue && this.stepNumeric > 1) {
                this.$emit('slider-with-input:input-value-not-taken', {
                    inputValue:  this.inputValue,
                    sliderValue: this.internalValue,
                });

                return `${this.stepNumeric}er Steps`;
            }

            if (this.stepNumeric > 1) {
                this.$emit('slider-with-input:input-value-taken', {
                    inputValue:  this.inputValue,
                    sliderValue: this.internalValue,
                });
            }
            return null;
        },
    },

    methods: {
        handleTextFieldUpdate(val) {
            if (isNaN(val)) {
                return;
            }

            const value = this.roundValue(Math.min(Math.max(val, this.usedMin), this.usedMax));

            this.internalValue = value;
        },

        roundValue(value) {
            if (! this.stepNumeric) {
                return value;
            }

            const trimmedStep = this.step.toString().trim();
            const decimals    = trimmedStep.indexOf('.') > -1
                ? (trimmedStep.length - trimmedStep.indexOf('.') - 1)
                : 0;
            const offset      = this.usedMin % this.stepNumeric;

            const newValue = Math.round((value - offset) / this.stepNumeric) * this.stepNumeric + offset;

            return parseFloat(Math.min(newValue, this.usedMax).toFixed(decimals));
        },

        handleSliderUpdate(type, newValue) {
            if (this.updateImmediately === true) {
                this.internalValue = newValue;

                return;
            }

            if (this.noUpdateOnChange !== true && type === 'change') {
                this.internalValue = newValue;

                return;
            }

            if (this.noUpdateOnEnd !== true && type === 'end') {
                this.internalValue = newValue;

                return;
            }

            // Fallback, if it has no update possibility
            if (type === 'change') {
                this.internalValue = newValue;
            }
        },
    },
};
</script>
