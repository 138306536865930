var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative"},[(! _vm.isLoaded)?_c('v-boilerplate-loading',{staticClass:"w-full",attrs:{"elevation":0,"type":"list-item-two-line@1"}}):[(_vm.showTitleIfNecessary && (! _vm.isTogether || ! _vm.isSideBySide))?_c('sub-header-with-divider',{attrs:{"text":"Zutaten"}}):_vm._e(),_c('recipe-portions-field',{class:{
                'mb-3': _vm.hasModifiedPortions
            },attrs:{"original-portions":_vm.recipe.portions,"portions":_vm.portionsForCalculation,"more-informations-label":"","use-placeholder":""},on:{"recipe:portions:updated":_vm.updatedPortions}}),_vm._l((_vm.recipe.ingredient_groups),function(group,index){return _c('div',{class:{
                'bg-gray-400': _vm.isTogether && _vm.isSideBySide,
                'dark:bg-gray-900': _vm.isTogether && _vm.isSideBySide,
                'dark:bg-material-blueGrey-darken-4': _vm.isTogether && _vm.isSideBySide,
                'rounded-lg': _vm.isTogether && _vm.isSideBySide,
                'px-4': _vm.isTogether && _vm.isSideBySide,
                'py-4': _vm.isTogether && _vm.isSideBySide,
                'ring-1': _vm.isTogether && _vm.isSideBySide,
                'dark:ring-emerald-900': _vm.isTogether && _vm.isSideBySide,
                'dark:ring-opacity-85': _vm.isTogether && _vm.isSideBySide,
                'shadow-md': _vm.isTogether && _vm.isSideBySide,
                'dark:shadow-md-white': _vm.isTogether && _vm.isSideBySide,
                'mt-5': index > 0
            }},[(group.title && group.title !== '')?_c('sub-header-with-divider',{class:{
                    '-mt-3': _vm.isTogether && _vm.isSideBySide,
                    'ml-0': _vm.isTogether && _vm.isSideBySide
                },attrs:{"bigger":_vm.isTogether && _vm.isSideBySide,"text":group.title,"show-divider":false}}):_vm._e(),_c('ul',{staticClass:"list-disc text-sm mb-0",class:{'pl-2': ! _vm.isTogether || ! _vm.isSideBySide, 'ml-2': ! _vm.isTogether || ! _vm.isSideBySide}},_vm._l((group.ingredients),function(ingredient,ingredientIndex){return _c('li',{key:'recipe-ingredient-'+_vm.recipe.id+group.id+ingredient.id},[_c('ingredient',{class:{'mt-2': ingredientIndex},attrs:{"ingredient":ingredient,"portions-for-calculation":_vm.portionsForCalculation,"recipe-portions":_vm.recipe.portions}})],1)}),0)],1)})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }