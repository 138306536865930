<template>
    <div>
        <v-flex :class="marginClassForSlotDivider">
            <div :class="widthClass">
                <slot name="title"></slot>
            </div>
            <v-icon
                :color="iconColor"
                :class="iconClass"
                class="ml-2"
                v-html="icon"
                @click="toggleContent"/>
        </v-flex>
        <divider
            v-if="showDivider"
            :color="colorDivider"
            :change-accent-for-theme="changeAccentForTheme"
            :use-margin-bottom="useMarginBottom"/>

        <v-expand-transition v-if="! withoutAnimation">
            <div v-show="hidden === false">
                <slot/>
            </div>
        </v-expand-transition>
        <div v-show="hidden === false" v-else>
            <slot/>
        </div>
    </div>
</template>

<script>
import SubHeader from './SubHeader';
import Divider   from './Divider';

export default {
    components: {
        SubHeader,
        Divider,
    },

    props: {
        widthClass: {
            type:    String,
            default: 'w-50%',
        },

        marginClassForSlotDivider: {
            type:    String,
            default: 'mb-2',
        },

        iconClass: {
            type:    String,
            default: '',
        },

        withoutAnimation: {
            type:    Boolean,
            default: false,
        },

        startHidden: {
            type:    Boolean,
            default: false,
        },

        showDivider: {
            type:    Boolean,
            default: true,
        },

        colorDivider: {
            type:    String,
            default: 'blue',
        },

        iconColor: {
            type:    String,
            default: 'info',
        },

        changeAccentForTheme: {
            type:    Boolean,
            default: true,
        },

        useMarginBottom: {
            type:    Boolean,
            default: true,
        },
    },

    data: () => ({
        hidden: false,
    }),

    mounted() {
        this.hidden = this.startHidden === true;
    },

    computed: {
        icon() {
            return this.hidden ? '$plus' : '$minus';
        },
    },

    methods: {
        toggleContent() {
            this.hidden = ! this.hidden;

            this.$emit('subheader:content-visible', this.hidden);
        },
    },
};
</script>
