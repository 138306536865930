<template>
    <div v-if="! isDisabled" class="comments-area">
        <hideable-content-sub-header-with-divider v-if="header !== ''" :text="header" :start-hidden="defaultCollapsed === true">
            <comments-index :readonly="readonly" :module="module" :id="id" />
        </hideable-content-sub-header-with-divider>
        <comments-index :readonly="readonly" :module="module" :id="id" v-else />
    </div>
</template>

<script>

import {
    flagName as DisableCommentsFlagName,
    defaultActive as DisableCommentsFlagDefault,
    settingFlagName as DisableCommentsFlagSettingsName,
    settingDefaultActive as DisableCommentsFlagSettingsDefault
} from '../Config/flags/DisableComments';


import {
    flagName as CommentsCollapsedFlagName,
    defaultActive as CommentsCollapsedFlagDefault,
    settingFlagName as CommentsCollapsedFlagSettingsName,
    settingDefaultActive as CommentsCollapsedFlagSettingsDefault
} from '../Config/flags/CommentsCollapsed';

export default {
    components: {
        HideableContentSubHeaderWithDivider: () => import('@symfia/library/Views/Helper/HideableContentSubHeaderWithDivider.vue'), 
        CommentsIndex: () => import('./comments/CommentsIndex.vue'),
    },

    mounted() {},

    beforeDestroy() {},

    data: () => ({

    }),

    props: {
        module: {
            type:     String,
            required: true,
        },
        id:     {
            type:     Number,
            required: true,
        },
        readonly: {
            type:     Boolean,
            required: false,
            default: false,
        },
        header:  {
            type:     String,
            required: false,
            default: 'Kommentare'
        },
    },

    computed: {
        defaultCollapsed() {
            if (this.isFeatureFlagActive(CommentsCollapsedFlagName, CommentsCollapsedFlagDefault)) {
                return true;
            }

            return this.isMobileOrTablet() && this.getFeatureFlagSetting(
                CommentsCollapsedFlagName,
                CommentsCollapsedFlagSettingsName,
                CommentsCollapsedFlagSettingsDefault,
            ) === true;
        },
        isDisabled() {
            if (this.isFeatureFlagActive(DisableCommentsFlagName, DisableCommentsFlagDefault)) {
                return true;
            }

            return this.isMobileOrTablet() && this.getFeatureFlagSetting(
                DisableCommentsFlagName,
                DisableCommentsFlagSettingsName,
                DisableCommentsFlagSettingsDefault,
            ) === true;
        }
    },

    methods: {},
};
</script>
