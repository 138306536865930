<template>
    <div class="position-relative">
        <v-dialog
            v-if="showDialog"
            :value="showDialog"
            :fullscreen="$breakpoint.smAndDown"
            :persistent="importing"
            max-width="95%">
            <v-card>
                <v-card-title>
                    Import von Rezepten
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text class="mt-3">
                    <import-info-alert
                        v-if="! importing"
                        class="mb-2"
                    />
                    Wir unterstützen den Import von folgenden Seiten: <br>
                    <div v-for="provider in providers">
                        <a :href="provider.description" target="_blank">
                            <v-btn class="ml-0 pl-0 pr-0" small text color="info">{{ provider.value }}</v-btn>
                        </a>
                    </div>

                    <import-form
                        ref="importForm"
                        v-on:recipes:importing="importing = true"
                        v-on:recipes:importFailed="importing = false"
                        v-on:recipes:imported="importFinished"
                        class="mt-3"
                        :preloaded-providers="providers"
                        hide-import-button
                        hide-info
                    />
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn color="success" text v-on:click="importPages" :loading="importing">
                        Importieren
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text v-on:click="showDialog = false" :disabled="importing">
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-btn color="success" v-if="showButton" small :disabled="providers.length === 0" v-on:click="showDialog = true">Rezepte importieren</v-btn>
    </div>
</template>

<script>
import ImportForm      from './ImportForm';
import ImportInfoAlert from '../Shared/ImportInfoAlert';

export default {
    components: {
        ImportForm,
        ImportInfoAlert
    },

    mounted() {
        this.loadImportSites();
    },

    beforeDestroy() {
        this.cancelRequests();
    },

    props: {
        showButton: {
            type:    Boolean,
            default: true,
        },
    },

    data: () => ({
        providers:  [],
        fields:     {},
        loading:    false,
        axiosToken: null,
        showDialog: false,
        importing:  false,
    }),

    methods: {
        cancelRequests() {
            if (this.axiosToken) {
                this.axiosToken.cancel();
                this.axiosToken = null;
                this.loading    = false;
            }
        },

        importPages() {
            this.$refs.importForm.importPages();
        },

        importFinished() {
            this.showDialog = false;
            this.importing = false;
        },

        loadImportSites() {
            if (this.loading) {
                return;
            }

            this.loading    = true;
            this.axiosToken = this.$axiosToken.source();

            this.$axios.get(this.route('cookbook.recipes.import.providers'),
                {
                    cancelToken: this.axiosToken.token,
                },
            ).then((response) => {
                const values = Object.values(response.data);
                this.$logger.debug('Recipe Import Providers', {
                    response: response.data,
                    values:   values,
                }, 'Recipes-Import');

                this.providers = values;
            }).catch((error) => {
                this.$logger.debug('Recipe Import Providers failed', {
                    error:    error,
                    response: typeof error.response !== 'undefined' ? error.response : null,
                }, 'Recipes-Import');

                if (! this.$axiosIsCancel(error)) {
                    this.$notifications.error('Beim Laden der Import-Anbieter trat ein Fehler auf - am besten lädst du neu.');
                }
            }).finally(() => {
                this.axiosToken = null;
            });
        },
    },

    computed: {},
};
</script>
