<template>
    <v-slider
        :value="internalValue"
        :step="step"
        :min="usedMin"
        :max="usedMax"

        @end="handleSliderUpdate('end', $event)"
        @input="handleSliderUpdate('input', $event)"
        @change="handleSliderUpdate('change', $event)"

        :disabled="disabled"
        :thumb-label="thumbLabel"
        :vertical="vertical"
        :loading="loading"
        :dense="dense"
        :hint="hint"
        :label="label"
        :persistent-hint="persistentHint"

        v-bind="$attrs"
    >
        <template v-slot:message v-if="$slots.hint">
            <slot name="hint"></slot>
        </template>

        <template v-slot:prepend>
            <v-icon @click="decrement" :disabled="internalValue <= usedMin" :ripple="false">
                $minus
            </v-icon>
        </template>

        <template v-slot:append>
            <v-icon @click="increment" :disabled="internalValue >= usedMax" :ripple="false">
                $plus
            </v-icon>
        </template>
    </v-slider>
</template>

<script>

export default {
    props: {
        value: {
            type:    Number,
            default: true,
        },

        updateImmediately: {
            type:    Boolean,
            default: false,
        },

        noUpdateOnChange: {
            type:    Boolean,
            default: false,
        },

        noUpdateOnEnd: {
            type:    Boolean,
            default: true,
        },

        step: {
            type:    [Number, String],
            default: 1,
        },

        min: {
            type:    [Number, String],
            default: 0,
        },

        max: {
            type:    [Number, String],
            default: 100,
        },

        disabled:       Boolean,
        thumbLabel:     {
            type:    [Boolean, String],
            default: undefined,
        },
        vertical:       Boolean,
        loading:        {
            type:    [Boolean, String],
            default: false,
        },
        dense:          Boolean,
        hint:           String,
        label:          String,
        persistentHint: Boolean,
    },

    data: () => ({
        lazyValue: 0,
    }),

    created() {
        this.lazyValue = this.value;
    },

    watch: {
        value(newVal) {
            this.lazyValue = newVal;
        },
    },

    computed: {
        usedMin: function () {
            return parseFloat(this.min);
        },

        usedMax: function () {
            return parseFloat(this.max);
        },

        internalValue: {
            get() {
                return this.lazyValue;
            },

            set(val) {
                const value = isNaN(val) ? this.usedMin : val;

                if (value === this.lazyValue) {
                    return;
                }

                this.lazyValue = value;
                this.$emit('input', value);
            },
        },
    },

    methods: {
        handleSliderUpdate(type, newValue) {
            if (this.updateImmediately === true) {
                this.internalValue = newValue;

                return;
            }

            if (this.noUpdateOnChange !== true && type === 'change') {
                this.internalValue = newValue;

                return;
            }

            if (this.noUpdateOnEnd !== true && type === 'end') {
                this.internalValue = newValue;

                return;
            }

            // Fallback, if it has no update possibility
            if (type === 'change') {
                this.internalValue = newValue;
            }
        },

        increment() {
            let newValue = this.internalValue + this.step;

            if (newValue >= this.usedMax) {
                newValue = this.usedMax;
            }

            this.internalValue = newValue;
        },

        decrement() {
            let newValue = this.internalValue - this.step;

            if (newValue <= this.usedMin) {
                newValue = this.usedMin;
            }

            this.internalValue = newValue;
        },
    },
};
</script>
