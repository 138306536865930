var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.levelLabel || _vm.timeLabel)?_c('div',{class:{
    'text-xs': _vm.secondary,
    'dark:text-gray-400': _vm.secondary,
    'font-thin': _vm.secondary,
    'text-xsToSm': _vm.titleSecondary,
    'dark:text-gray-300': _vm.titleSecondary,
    'font-extralight': _vm.titleSecondary,
}},[_vm._v(" "+_vm._s(_vm.prefix)+" "),(_vm.levelLabel)?[_c('v-icon',{class:{
                'ml-2': _vm.inTitle,
                'mr-2': ! _vm.secondary && ! _vm.titleSecondary,
                'mr-1': _vm.secondary || _vm.titleSecondary,
                'text-xs': _vm.secondary || _vm.titleSecondary,
                'dark:text-gray-400': _vm.secondary || _vm.titleSecondary,
                'font-thin': _vm.secondary,
                'font-extralight': _vm.titleSecondary,
            },attrs:{"small":! _vm.secondary,"x-small":_vm.secondary}},[_vm._v(" "+_vm._s(_vm.levelIcon)+" ")]),_vm._v(" "+_vm._s(_vm.levelLabel)+" ")]:_vm._e(),(_vm.timeLabel)?[_c('v-icon',{staticClass:"text-xs dark:text-gray-400",class:{
                'ml-2': _vm.inTitle || _vm.levelLabel,
                'mr-2': ! _vm.secondary && ! _vm.titleSecondary,
                'mr-1': _vm.secondary || _vm.titleSecondary,
                'text-xs': _vm.secondary || _vm.titleSecondary,
                'dark:text-gray-400': _vm.secondary || _vm.titleSecondary,
                'font-thin': _vm.secondary,
                'font-extralight': _vm.titleSecondary,
            },attrs:{"small":! _vm.secondary,"x-small":_vm.secondary}},[_vm._v(" $recipeTime ")]),_vm._v(" "+_vm._s(_vm.timeLabel)+" ")]:_vm._e(),_vm._v(" "+_vm._s(_vm.suffix)+" ")],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }