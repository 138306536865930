<template>
    <v-img
        v-if="displayCoverSideBySide"
        :max-height="height"
        :height="height"
        :max-width="width"
        :width="width"
        :src="coverImage"
        :lazy-src="coverPlaceholder"
    >
        <template v-slot:placeholder>
            <div :style="{width: width + 'px', height: height + 'px'}">
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-row>
            </div>
        </template>
    </v-img>
</template>

<script>

export default {
    components: {},

    props: {
        recipe: {
            type:     Object,
            required: true,
        },

        height: {
            type:    Number,
            default: 250,
        },

        width: {
            type:    Number,
            default: 250,
        },
    },

    data: () => ({}),

    watch: {},

    methods: {},

    computed: {
        coverImage() {
            if (typeof this.recipe.cover !== 'undefined' && this.recipe.cover !== null && this.recipe.cover !== '') {
                return this.recipe.cover;
            }

            return null;
        },

        coverPlaceholder() {
            if (
                typeof this.recipe.placeholderBase64 !== 'undefined'
                && this.recipe.placeholderBase64 !== null
                && this.recipe.placeholderBase64 !== ''
            ) {
                return this.recipe.placeholderBase64;
            }

            if (
                typeof this.recipe.placeholder !== 'undefined'
                && this.recipe.placeholder !== null
                && this.recipe.placeholder !== ''
            ) {
                return this.recipe.placeholder;
            }

            return null;
        },

        displayCoverSideBySide() {
            return this.displayCover /*&& ! this.$breakpoint.isSmartphone*/;
        },

        displayCover() {
            return typeof this.recipe.cover !== 'undefined' && this.recipe.cover !== null && this.recipe.cover !== '';
        },
    },
};
</script>
