<template>
    <v-row>
        <v-col cols="12">
            <content-loader
                :width="450"
                :height="500">
                <!-- Button -->
                <rect x="9" y="2" rx="0" ry="0" width="78" height="24"/>
                <rect x="103" y="2" rx="0" ry="0" width="78" height="24"/>

                <!-- Text & Input -->
                <rect x="40" y="65" rx="7" ry="7" width="105" height="10"/>
                <rect x="300" y="53" rx="6" ry="6" width="215" height="26"/>

                <!-- Pagination oben -->
                <rect x="215" y="116" rx="5" ry="5" width="34" height="35"/>
                <rect x="263" y="116" rx="5" ry="5" width="34" height="35"/>
                <rect x="310" y="116" rx="5" ry="5" width="34" height="35"/>
                <rect x="357" y="116" rx="5" ry="5" width="34" height="35"/>
                <rect x="404" y="116" rx="5" ry="5" width="34" height="35"/>

                <!-- Erste Card Reihe -->
                <rect x="10" y="176" rx="5" ry="5" width="190" height="94"/>
                <rect x="225" y="175" rx="5" ry="5" width="190" height="94"/>
                <rect x="440" y="175" rx="5" ry="5" width="190" height="94"/>
                <rect x="660" y="175" rx="5" ry="5" width="190" height="94"/>

                <!-- Zweite Card Reihe -->
                <rect x="10" y="300" rx="5" ry="5" width="190" height="94"/>
                <rect x="225" y="300" rx="5" ry="5" width="190" height="94"/>
                <rect x="440" y="300" rx="5" ry="5" width="190" height="94"/>
                <rect x="660" y="300" rx="5" ry="5" width="190" height="94"/>

                <!-- Pagination unten -->
                <rect x="215" y="420" rx="5" ry="5" width="34" height="35"/>
                <rect x="263" y="420" rx="5" ry="5" width="34" height="35"/>
                <rect x="310" y="420" rx="5" ry="5" width="34" height="35"/>
                <rect x="357" y="420" rx="5" ry="5" width="34" height="35"/>
                <rect x="404" y="420" rx="5" ry="5" width="34" height="35"/>
            </content-loader>
        </v-col>
    </v-row>
</template>

<script>
import ContentLoader from '@symfia/library/Views/Helper/ContentLoader';

export default {
    components: {
        ContentLoader,
    },
};
</script>
