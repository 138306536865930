<template>
    <index
        :type="type"
        :recipe-id="recipeId"
        :recipe-id-edit="recipeIdEdit"
        :start-with-edit="startWithEdit"
        :on-dashboard="onDashboard"
        :shouldChangeRoute="shouldChangeRoute"
        :dashboard-full="dashboardFull"
        :dashboard-used-cols="dashboardUsedCols"/>
</template>

<script>
import Index from './Index';

export default {
    components: {
        Index,
    },

    props: {
        onDashboard: {
            type:    Boolean,
            default: false,
        },

        dashboardFull: {
            type:    Boolean,
            default: false,
        },

        dashboardUsedCols: {
            type:    Number,
            default: 6,
        },
    },

    watch: {
        $route(to, from) {
            const listRoutes = [
                'Cookbook.Recipes.mine',
                'Cookbook.Recipes.friends',
                'Cookbook.Recipes.public',
                'Cookbook.Recipes.all',
            ];

            if (to.name !== from.name && listRoutes.includes(to.name)) {
                this.setType();
            }
        },

    },

    beforeMount() {
        this.setType();
    },

    data: () => ({
        startWithEdit:     false,
        recipeId:          null,
        recipeIdEdit:      null,
        type:              'mine',
        shouldChangeRoute: false,
    }),

    methods: {
        setType() {
            const route = this.$route.fullPath.toLowerCase();

            if (typeof this.$route.params.id !== 'undefined' && this.$route.params.id !== '') {
                if (this.$route.name === 'Cookbook.Recipes.edit') {
                    this.recipeIdEdit      = parseInt(this.$route.params.id);
                    this.recipeId          = null;
                    this.startWithEdit     = true;
                    this.shouldChangeRoute = true;
                } else {
                    this.recipeId          = parseInt(this.$route.params.id);
                    this.recipeIdEdit      = null;
                    this.startWithEdit     = route.includes('recipes/create');
                    this.shouldChangeRoute = true;
                }
            } else {
                this.recipeId          = null;
                this.recipeIdEdit      = null;
                this.startWithEdit     = route.includes('recipes/create');
                this.shouldChangeRoute = route.includes('recipes/create');
            }

            if (route.includes('recipes/friends')) {
                this.type = 'friend';
            } else if (route.includes('recipes/public')) {
                this.type = 'public';
            } else if (route.includes('recipes/all')) {
                this.type = 'all';
            } else {
                this.type = 'mine';
            }
        },
    },

    computed: {},
};
</script>
