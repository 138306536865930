<template>
    <div v-if="levelLabel || timeLabel" :class="{
        'text-xs': secondary,
        'dark:text-gray-400': secondary,
        'font-thin': secondary,
        'text-xsToSm': titleSecondary,
        'dark:text-gray-300': titleSecondary,
        'font-extralight': titleSecondary,
    }">
        {{ prefix }}
        <template v-if="levelLabel">
            <v-icon
                :small="! secondary"
                :x-small="secondary"
                :class="{
                    'ml-2': inTitle,
                    'mr-2': ! secondary && ! titleSecondary,
                    'mr-1': secondary || titleSecondary,
                    'text-xs': secondary || titleSecondary,
                    'dark:text-gray-400': secondary || titleSecondary,
                    'font-thin': secondary,
                    'font-extralight': titleSecondary,
                }">
                {{ levelIcon }}
            </v-icon>
            {{ levelLabel }}
        </template>
        <template v-if="timeLabel">
            <v-icon
                :small="! secondary"
                :x-small="secondary"
                class="text-xs dark:text-gray-400"
                :class="{
                    'ml-2': inTitle || levelLabel,
                    'mr-2': ! secondary && ! titleSecondary,
                    'mr-1': secondary || titleSecondary,
                    'text-xs': secondary || titleSecondary,
                    'dark:text-gray-400': secondary || titleSecondary,
                    'font-thin': secondary,
                    'font-extralight': titleSecondary,
                }">
                $recipeTime
            </v-icon>
            {{ timeLabel }}
        </template>
        {{ suffix }}
    </div>
</template>

<script>

export default {
    components: {},

    props: {
        subData: {
            type:     Object,
            required: true,
        },

        secondary: {
            type:    Boolean,
            default: false,
        },

        titleSecondary: {
            type:    Boolean,
            default: false,
        },

        inTitle: {
            type:    Boolean,
            default: false,
        },

        prefix: {
            type:    String,
            default: '',
        },

        suffix: {
            type:    String,
            default: '',
        },
    },

    data: () => ({}),

    watch: {},

    mounted() {

    },

    beforeDestroy() {

    },

    methods: {},

    computed: {
        levelIcon() {
            if (! this.subData.level || this.subData.level === '') {
                return null;
            }

            switch (this.subData.level) {
                case 'very-easy':
                    return '$difficultyVeryEasy';

                case 'easy':
                    return '$difficultyEasy';

                case 'normal':
                    return '$difficultyNormal';

                case 'hard':
                    return '$difficultyHard';

                case 'very-hard':
                    return '$difficultyVeryHard';

                default:
                    return '$difficultyNormal';
            }
        },

        levelLabel() {
            if (! this.subData.level || this.subData.level === '') {
                return null;
            }

            return this.subData.level_text;
        },

        timeLabel() {
            if (! this.subData.time || this.subData.time <= 0 || this.subData.time === '') {
                return null;
            }

            return this.subData.time_human;
        },
    },
};
</script>
