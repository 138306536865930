<template>
    <div>
        <sub-header
            :text="text"
            :bolder="bolder"
            :bigger="bigger"
            :color="color"
            :change-accent-for-theme="changeAccentForTheme"
            :inset="inset"
            :icon="icon"
            :icon-color="iconColor"
            v-on:subheader:icon-clicked="$emit('subheader:icon-clicked')"
            :remove-padding="removePadding"/>
        <divider
            v-if="showDivider"
            :color="colorDivider"
            :change-accent-for-theme="changeAccentForTheme"
            :use-margin-bottom="useMarginBottom"/>
    </div>
</template>

<script>
    import SubHeader from './SubHeader';
    import Divider   from './Divider';

    export default {
        components: {
            SubHeader,
            Divider,
        },

        props: {
            text: {
                type:     String,
                required: true,
            },

            inset: {
                type:    Boolean,
                default: false,
            },

            bolder: {
                type:    Boolean,
                default: false,
            },

            bigger: {
                type:    Boolean,
                default: false,
            },

            showDivider: {
                type:    Boolean,
                default: true,
            },

            icon: {
                type:     String,
                default: null
            },

            color: {
                type:    String,
                default: 'info',
            },

            iconColor: {
                type:    String,
                default: 'info',
            },

            colorDivider: {
                type:    String,
                default: 'blue',
            },

            changeAccentForTheme: {
                type:    Boolean,
                default: true,
            },

            removePadding: {
                type:    Boolean,
                default: true,
            },

            useMarginBottom: {
                type:    Boolean,
                default: true,
            },
        },

        computed: {},
    };
</script>
