<template>
    <div class="position-relative">
        <hideable-content-with-input-and-divider
            v-for="(ingredientGroup, index) in ingredientGroups"
            :key="'ingredient-group-'+index"
            v-model="ingredientGroup.title"
            hint="Optionaler Titel der Zutatengruppe"
            persistent-hint
            type="text"
            label="Titel"
            class="mt-6"
        >
            <v-row v-for="(ingredient, ingredientIndex) in ingredientGroup.ingredients" :key="'ingredient-group-'+index+'-ingredient-'+ingredientIndex">
                <v-col cols="12" sm="4" md="3" lg="3">
                    <ValidationProvider :vid="'step-ingredient-'+index+'-group-'+ingredientIndex" :name="'Zutat ' + (ingredientIndex + 1) + ' (Gruppe: '+(index + 1)+')'" :rules="rules.name" v-slot="{ errors, valid }">
                        <v-combobox
                            :error-messages="errors"
                            :success="valid"
                            v-model="ingredient.title"
                            :items="autocompletion.ingredients"
                            clearable
                            :loading="loadingAutocomplete"
                            hint="Vorschläge nicht passend? Einfach frei eingeben!"
                            persistent-hint
                            label="Zutat"
                        >
                            <span slot="label">Zutat <span class="required-asterisk-mark">*</span></span>
                        </v-combobox>
                    </ValidationProvider>
                </v-col>

                <v-col cols="12" sm="2" md="2" lg="2">
                    <v-text-field
                        v-model.number="ingredient.amount"
                        type="number"
                        label="Menge"
                        persistent-hint
                        clearable
                    />
                </v-col>

                <v-col cols="12" sm="3" md="3" lg="3">
                    <v-combobox
                        v-model="ingredient.unit"
                        :items="autocompletion.units"
                        clearable
                        :loading="loadingAutocomplete"
                        hint="Vorschläge nicht passend? Einfach frei eingeben! Leer lassen möglich."
                        persistent-hint
                        label="Einheit"
                    ></v-combobox>
                </v-col>

                <v-col cols="12" sm="2" md="3" lg="3">
                    <v-text-field
                        v-model.number="ingredient.additional"
                        type="text"
                        label="Zusatzinfo"
                        persistent-hint
                        clearable
                    />
                </v-col>

                <v-col cols="12" sm="1" md="1" lg="1" class="flex items-center">
                    <v-btn color="primary" icon class="cursor-pointer" v-if="(ingredientGroups[index].ingredients.length - 1) === ingredientIndex" :ripple="false" @click="addIngredientStep(index)">
                        <v-icon>$plus</v-icon>
                    </v-btn>
                    <v-btn icon color="error" class="cursor-pointer" v-if="ingredientGroups[index].ingredients.length > 1" :ripple="false" @click="deleteIngredientStep(index, ingredientIndex)">
                        <v-icon>$minus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <div class="mt-3">
                <v-btn x-small v-if="(ingredientGroups.length - 1) === index" color="primary" :ripple="false" @click="addIngredientGroup">Neue Gruppe</v-btn>
                <v-btn :class="{'ml-3': (ingredientGroups.length - 1) === index}" x-small v-if="ingredientGroups.length > 1" @click="deleteIngredientGroup(index)" color="error" :ripple="false">Gruppe löschen</v-btn>
            </div>
        </hideable-content-with-input-and-divider>
    </div>
</template>

<script>

import HideableContentWithInputAndDivider from '@symfia/library/Views/Helper/HideableContentWithInputAndDivider';
import {IngredientAndStepRule}            from '../../Config/Rules/CreateEdit';

export default {
    components: {
        HideableContentWithInputAndDivider,
    },

    props: {
        value: {
            type:     [Array, null, undefined],
            required: true,
        },

        initialIngredients: {
            type:    Array,
            default: function () {
                return [];
            },
        },

        autocompletion: {
            type:    Object,
            default: function () {
                return {};
            },
        },

        loadingAutocomplete: {
            type:    Boolean,
            default: false,
        },
    },

    watch: {
        ingredientGroups() {
            this.$emit('input', this.ingredientGroups);
        },
    },

    data: () => ({
        ingredientGroups: [],
        rules:            {
            name: IngredientAndStepRule,
        },
    }),

    mounted() {
        this.ingredientGroups = this.initialIngredients || this.value || [];

        this.$nextTick(() => {
            this.initGroups();
        });
    },

    beforeDestroy() {

    },

    methods: {
        initGroups() {
            if (this.ingredientGroups.length === 0) {
                this.addIngredientGroup();
            } else if (this.ingredientGroups[0].ingredients.length === 0) {
                this.addIngredientStep(0);
            }
        },

        addIngredientGroup() {
            this.ingredientGroups.push({
                ingredients: [],
                title:       '',
            });

            this.addIngredientStep(this.ingredientGroups.length - 1);
        },

        addIngredientStep(ingredientGroupIndex) {
            this.ingredientGroups[ingredientGroupIndex].ingredients.push({
                title:      '',
                amount:     null,
                unit:       '',
                additional: '',
            });
        },

        deleteIngredientStep(ingredientGroupIndex, ingredientIndex) {
            this.ingredientGroups[ingredientGroupIndex].ingredients.splice(ingredientIndex, 1);
        },

        deleteIngredientGroup(ingredientGroupIndex) {
            this.ingredientGroups.splice(ingredientGroupIndex, 1);
        },
    },
};
</script>
