<template>
    <content-loader
        should-be-little-bigger
        :width="width"
        :height="100"
    >
        <rect x="46" y="11" rx="3" ry="3" :width="width" height="6"/>
        <circle cx="17" cy="17" r="17"/>
    </content-loader>
</template>

<script>
import ContentLoader from '@symfia/library/Views/Helper/ContentLoader';

export default {
    components: {
        ContentLoader,
    },
    props: {
        width: {
            type: Number,
            default: 400
        },
    },
};
</script>
